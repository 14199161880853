
import parsePhoneNumberFromString from "libphonenumber-js";

export const isValidPhoneNumberAppoitment = (value, country) => {
    try {
      const phoneNumber = parsePhoneNumberFromString(value, country?.toUpperCase());
      console.log(phoneNumber,"phoneNumber",value, country)
      return phoneNumber?.isValid() || false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };