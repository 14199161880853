import React, { useState, useEffect, useCallback } from "react";
// import { environmentVariables } from "../../../../config/env.config";
import Drawer from "react-bottom-drawer";
// import CustomerDetails from "./CustomerDetails";
import axios from "axios";
// import CartSummary from "./CartSummary";
import { toast } from "react-toastify";
import { environmentVariables } from "../../../../config/env.config";
import CustomerDetails from "../theme53/CustomerDetails";
import "./Theme20cart.css";
import "./Theme20cartstyle";
import { Base64 } from 'js-base64';
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux";
import emprtyIcon from "../../../../Images/outlineII.png";
// import { getShortDescription } from "../../../../utils/utility";

export const Cart = ({
  data,
  active,
  toggleProduct,
  currencySymbol,
  updatedState,
  setUpdatedState,
  rs,
  handleOpenLogin,
}) => {
  //   const items = Array.from({ length: 10 }, (_, index) => index + 1);
  const [closing, setClosing] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isCartSummaryVisible, setIsCartSummaryVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  //   const [selectedProData, setSelectedProData] = useState(null);
  const [selectedUserData, setSelectedUserData] = useState(null);
  const [cartSummaryData, setCartSummaryData] = useState(null);
  const [itemQuantities, setItemQuantities] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [forwardedemail, setFormwadedEmail] = useState("");
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponID, setCouponID] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const [applyLoader, setApplyLoader] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  let customer_data = localStorage.getItem("Customer");
  let cusData = !!customer_data ? JSON.parse(customer_data) : "";

  const openDrawer = useCallback(() => setIsVisible(true), []);
  const closeDrawer = useCallback(() => setIsVisible(false), []);
  const openCartSummaryDrawer = useCallback(
    () => setIsCartSummaryVisible(true),
    []
  );
  const closeCartSummaryDrawer = useCallback(
    () => setIsCartSummaryVisible(false),
    []
  );

  const fronturl = environmentVariables?.frntUrl;
  const httpProtocol = environmentVariables?.httpProtocol;

  const handleAddToOrder = (menuItem) => {
    // Check if item is already in cart
    console.log("menuItem===>>>", menuItem);
    // setCartItems((prevCartItems) => {
    //   const isAdded = prevCartItems.some((item) => item.id === menuItem.id);

    //   if (isAdded) {
    //     // If already added, remove from cart
    //     return prevCartItems.filter((item) => item.id !== menuItem.id);
    //   } else {
    //     if(menuItem?.infproduts?.product_price?.toLowerCase() != "not available"){

    //       // Add item to cart with selected properties
    //       const newItem = {
    //         id: menuItem.id,
    //         product_id: menuItem?.product_id,
    //         name: menuItem.infproduts.product_name,
    //         price: menuItem.base_price,
    //         listing_price: menuItem.listing_price,
    //         image: `${environmentVariables.apiUrl}uploads/${menuItem.infproduts.image}`,
    //         quantity: 1,
    //         currencySymbol: currencySymbol,
    //       };
    //       return [...prevCartItems, newItem];
    //     }else{
    //       return prevCartItems;
    //     }
    //   }
    // });
  };

  const removeItem = (index) => {
    setCartItems((prevItems) => prevItems.filter((item, i) => i !== index));
  };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  useEffect(() => {
    const initialQuantities = {};
    data.forEach((item, index) => {
      initialQuantities[index] = item.quantity || 1;
    });
    setItemQuantities(initialQuantities);
  }, [data]);

  const increaseQuantity = (index, data) => {
    setItemQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[index] || 1;

      // Check if the quantity is already at the maximum limit
      if (currentQuantity >= 50) {
        return prevQuantities; // Return previous state without changes
      }

      // Otherwise, increment the quantity
      return {
        ...prevQuantities,
        [index]: currentQuantity + 1,
      };
    });
  };

  const decreaseQuantity = (index, data) => {
    if (itemQuantities[index] > 0) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: prevQuantities[index] - 1,
      }));
    }
  };

  useEffect(() => {
    for (let el in itemQuantities) {
      let get = data.map((elem, index) => {
        if (index == el) {
          elem.quantity = itemQuantities[el];
        }
        return el;
      });
    }
  }, [itemQuantities]);

  const handleRemoveItem = (itemId) => {
    // console.log("itemId========",itemId)
    // removeItem(index);
    // setItemQuantities((prevQuantities) => {
    //   const updatedQuantities = { ...prevQuantities };
    //   delete updatedQuantities[index];
    //   return updatedQuantities;
    // });
    dispatch({
      type: "REMOVE_PRODUCT_CART_ITEM",
      payload: { id: itemId },
    });
  };

  const calculateTotalPrice = (price, quantity) => {
    return price * quantity;
  };

  const calculateCartTotal = () => {
    let total = 0;
    data.forEach((item, index) => {
      total += calculateTotalPrice(item.price, itemQuantities[index] || 1);
    });
    return total;
  };

  const updateTotalAmount = (amount) => {
    setTotalAmount(amount);
  };
  useEffect(() => {
    handleApplyCouponCode();
  }, [totalAmount]);

  useEffect(() => {
    updateTotalAmount(calculateCartTotal());
  }, [data, itemQuantities]);

  const createOrderRazorPay = async (orderId, dataToSend) => {
    try {
      const res = await loadRazorpayScript();
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      let dataForRazorPay = {
        amount: Number(dataToSend?.total)?.toFixed(2),
        currency: "INR",
        orderId: orderId?.toString(),
        phone: dataToSend?.user_mobile,
      };

      let config = {
        method: "post",
        url: `${environmentVariables?.apiUrl}api/v1/minimarket/create_order_razorpay`,
        headers: {
          "Content-Type": "application/json",
        },
        data: dataForRazorPay,
      };
      const response = await axios(config);
      const {
        id: order_id,
        amount: order_amount,
        currency: order_currency,
      } = response?.data?.data;

      const options = {
        key: process.env.RAZORPAY_ID, // Enter the Key ID generated from the Dashboard
        amount: parseFloat(Number(response?.data?.data?.amount) / 100).toFixed(
          2
        ),
        currency: response?.data?.data?.currency,
        name: "Businessbay",
        description: "Influencer Transactions",
        order_id: response?.data?.data?.id,
        handler: (responseData) => {
          let amountTobeShown = parseFloat(
            !!order_amount ? order_amount / 100 : 0
          );
          window.location.href = `/minimktproductpaynowredirect?status=success&payment_id=${responseData.razorpay_payment_id}&orderid=${responseData?.razorpay_order_id}&amount=${amountTobeShown}&currency=${order_currency}`;
          // Optionally, handle the response here (e.g., store the payment info in your backend)
        },
        prefill: {
          name: dataToSend?.user_name,
          email: dataToSend?.user_email,
          contact: dataToSend?.user_mobile,
        },
        theme: {
          color: "#3399cc",
        },
        modal: {
          ondismiss: async () => {
            console.log("failure");
            // setIsSubmit(false);

            // Redirect to failure page
            // window.location.href = "/redirect?status=failure";
            let dataForRazorPayFailed = {
              orderId: orderId?.toString(),
            };
            let config = {
              method: "post",
              url: `${environmentVariables?.apiUrl}api/v1/minimarket/update_order_razorpay_failed`,
              headers: {
                "Content-Type": "application/json",
              },
              data: dataForRazorPayFailed,
            };

            await axios(config);
          },
        },
        notes: {
          product_purchase: "product_purchase",
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.on("payment.failed", function (response) {
        // alert(`Payment Failed! Error: ${response.error.description}`);
        // Redirect to failure page
        // setIsSubmit(false);

        window.location.href = `/minimktproductpaynowredirect?status=failure&payment_id=${response.error.metadata.payment_id}&orderid=${response?.error?.metadata?.order_id}`;
      });
      rzp.open();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message, {
        autoClose: 2000,
      });
      console.log(err);
    }
  };

  const createOrder = async () => {
    setLoader(true);
    console.log("cartItems====>>>>", data);

    const transformedProducts = data.map((product) => ({
      product_id: product.id,
      //   product_id: product.product_id,
      quantity: product.quantity,
      name: product?.name,
      single_price: product?.price,
      base_price: product?.price,
      price_x_qty: !!product?.price
        ? parseFloat(product?.price) * Number(product.quantity)
        : 0,
    }));

    // set current business slug in localstorage
    const slugb = rs?.slug;
    const slug_subdomain = rs?.slug_subdomain;
    let businessUrl =
      slug_subdomain != null
        ? httpProtocol + slug_subdomain
        : slugb != null
        ? fronturl + slugb
        : "/";

    localStorage.setItem("businessUrl", businessUrl);
    localStorage.setItem("orderCurrencySymbol", currencySymbol);
    // Calculate subtotal based on product price and quantity from the cart
    const couponId = cartSummaryData?.couponID || 0;
    const subTotal = cartSummaryData?.totalAmount || 0;
    const deliveryCharge = 0; // Set this based on your delivery charge logic
    const discount = cartSummaryData?.discountAmount || 0; // Set this based on your discount logic
    const total = cartSummaryData?.isAppliedCoupon
      ? cartSummaryData?.totalAmountAfterDiscount
      : cartSummaryData?.totalAmount;
    let productsInfo = rs?.influencerProducts;
    let singleProData = productsInfo.length > 0 ? productsInfo[0] : "";

    // Set form data dynamically using productData and userDetails
    const dataTobeSend = {
      products: transformedProducts,
      coupon_id: couponId,
      business_id: rs?.id,
      product_business_id: !!singleProData
        ? singleProData?.product_business_id
        : null,
      //   influencer_id: rs?.influencer_id,
      user_id: rs?.created_by,
      sub_total: subTotal,
      delivery_charge: deliveryCharge,
      discount: discount,
      total: total,
      payment_method: "Razorpay",
      customer_id: cusData?.id,
      currency: currencySymbol,
      user_name: selectedUserData?.name,
      user_email: selectedUserData?.email,
      user_mobile: selectedUserData?.phone,
      user_country_code: selectedUserData?.countryCode,
      user_address: selectedUserData?.address,
    };
    console.log("dataTobeSend", dataTobeSend);
    try {
      const createRes = await axios.post(
        `${environmentVariables?.apiUrl}api/v1/minimarket/create_order`,
        dataTobeSend,
        { "Content-Type": "application/json" }
      );
      await createOrderRazorPay(createRes?.data?.data?.order_id, dataTobeSend);
      setLoader(false);
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };

  const handleProceedDPO = async () => {
    try {
      setLoader(true);
      const transformedProducts = data.map((product) => ({
        product_id: product.id,
        quantity: product.quantity,
        name: product?.name,
        single_price: product?.price,
        base_price: product?.price,
        price_x_qty: !!product?.price
          ? parseFloat(product?.price) * Number(product.quantity)
          : 0,
      }));

      // set current business slug in localstorage
      const slugb = rs?.slug;
      const slug_subdomain = rs?.slug_subdomain;
      let businessUrl =
        slug_subdomain != null
          ? httpProtocol + slug_subdomain
          : slugb != null
          ? fronturl + slugb
          : "/";
      localStorage.setItem("businessUrl", businessUrl);
      localStorage.setItem("orderCurrencySymbol", currencySymbol);

      // Calculate subtotal based on product price and quantity from the cart
      const couponId = cartSummaryData?.couponID || 0;
      const subTotal = cartSummaryData?.totalAmount.toFixed(2) || 0;
      const deliveryCharge = 0; // Set this based on your delivery charge logic
      const discount = cartSummaryData?.discountAmount || 0; // Set this based on your discount logic
      const total = cartSummaryData?.isAppliedCoupon
        ? cartSummaryData?.totalAmountAfterDiscount
        : cartSummaryData?.totalAmount;
      let productsInfo = rs?.influencerProducts;
      let singleProData = productsInfo.length > 0 ? productsInfo[0] : "";

      const dataToBeSave = {
        products: transformedProducts,
        coupon_id: couponId,
        business_id: rs?.id,
        product_business_id: !!singleProData
          ? singleProData?.product_business_id
          : null,
        user_id: rs?.created_by,
        sub_total: subTotal,
        delivery_charge: deliveryCharge,
        discount: discount,
        total: total.toFixed(2),
        currencyName: "AED",
        currency: currencySymbol,
        payment_method: "DPO",
        customer_id: cusData?.id,
        user_name: selectedUserData?.name,
        user_email: selectedUserData?.email,
        user_mobile: selectedUserData?.phone,
        user_country_code: selectedUserData?.countryCode,
        user_address: selectedUserData?.address,
        cURL: businessUrl,
      };

      const response = await fetch(
        `${environmentVariables?.apiUrl}api/v1/minimarket/create_order_dpo`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToBeSave),
        }
      );

      const dataResponse = await response.json();

      console.log("dataResponse===>>>", dataResponse);

      // Handle the response from the backend
      if (response.ok) {
        // Payment initiated successfully
        if (
          dataResponse.success &&
          dataResponse.result &&
          dataResponse.result.API3G &&
          dataResponse.result.API3G.TransToken &&
          dataResponse.result.API3G.TransRef
        ) {
          const paymentPlatformUrl = `https://secure.3gdirectpay.com/payv3.php?ID=${dataResponse.result.API3G.TransToken._text}`;
          // Add more data as needed

          // Navigate to the payment platform
          window.location.href = paymentPlatformUrl;
          setLoader(false);

          // setIsPaymentBtnClicked(false);
        } else {
          console.error("Missing data for redirect");
          setLoader(false);

          // setIsPaymentBtnClicked(false);
        }
      } else {
        // Payment initiation failed
        console.error("Error initiating payment:", dataResponse.message);
        setLoader(false);

        // setIsPaymentBtnClicked(false);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      setLoader(false);

      // setIsPaymentBtnClicked(false);
    }

    // setIsPaymentBtnClicked(false);
  };

  //   // const link = `${environmentVariables.apiUrl}uploads/gallery/${item.image}`;
  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      toggleProduct();
      setClosing(false);
    }, 600);
  };
  let customerData = localStorage.getItem("Customer");
  useEffect(() => {
    if (!!selectedUserData) {
      if (customerData == null) {
      }
      callPG();
    }
  }, [selectedUserData]);

  const callPG = () => {
    if (!!rs?.shipCountry && rs?.shipCountry == "IN") {
      createOrder();
    } else {
      handleProceedDPO();
    }
  };

  const handleFormSubmit = (formData) => {
    setLoader(true);
    setSelectedUserData(formData);
  };

  const handleCheckout = () => {
    let getLocalStorageData = localStorage.getItem("Customer");
    if (getLocalStorageData) {
      closeCartSummaryDrawer();
      openDrawer();
      let cartSummaryData = {
        // couponID: couponID,
        // discountAmount: discountAmount,
        // isAppliedCoupon: isAppliedCoupon,
        totalAmount: totalAmount,
        totalAmountAfterDiscount: totalAmountAfterDiscount,
      };
      handleSummaryData(cartSummaryData);
    } else {
      // setIsModalOpen(true);
      // setIsModalOpenRegister(false);
      handleOpenLogin(true);
    }
  };

  //   const handleBuyNow = (selectedProductData) => {
  //     openDrawer();
  //     setSelectedProData(selectedProductData);
  //   };
  const handleSummaryData = (cartSummaryDetails) => {
    setCartSummaryData(cartSummaryDetails);
  };

  const handleApplyCouponCode = () => {
    setApplyLoader(true);
    if (!!couponCode) {
      let data = JSON.stringify({
        code: couponCode,
        influencer_id: inf_id,
      });

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/Influencercoupon/getInfluencerCouponByCode`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setApplyLoader(false);
          let couponResponse = response.data;
          let couponData = couponResponse?.result;
          setIsAppliedCoupon(true);
          setCouponID(couponData?.id);
          let calculateDisAmount = (
            (totalAmount * couponData?.discount) /
            100
          ).toFixed(2);
          setDiscountAmount(calculateDisAmount);
          let totalAfterDiscount = (totalAmount - calculateDisAmount).toFixed(
            2
          );
          setTotalAmountAfterDiscount(totalAfterDiscount);
        })
        .catch((error) => {
          setApplyLoader(false);
          console.log("error===>>>", error);
          toast.error(error?.response?.data?.message || error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsAppliedCoupon(false);
          setCouponID(0);
          setDiscountAmount(0);
          setTotalAmountAfterDiscount(0);
        });
    } else {
      setApplyLoader(false);
    }
  };

  return (
    <section
      // style={{ bottom: closing ? "-100%" : active ? "50px" : "-100%",marginBottom:"400px", color:"black"}}
      style={{
        bottom: closing ? "-100%" : active ? "50px" : "-100%",
        color: "black",
      }}
      className={`${active ? "product-popup" : ""}`}
      id="theme20-cart"
    >
      <div className="product-popup-inner">
        <article className="products sec">
          <h3 className="sectitle">Cart Summary</h3>
          <p className="cross">
            {active && <i onClick={handleClose} className="fa-solid fa-x"></i>}
          </p>
          {data?.length >= 1 ? (
            <div className="product-inner">
              {data?.length > 0 &&
                data?.map((item, index) => {
                  // const infProduct = item.infproduts;
                  const link = `${item?.image}`;
                  // const isAdded = cartItems.some(
                  //   (cartItem) => cartItem.id === item.id
                  // );
                  let pID = Base64.encode(item?.id);
                  let detailsLink = (rs?.slug_subdomain != null) ? httpProtocol + rs?.slug_subdomain : (rs?.slug != null) ? fronturl + rs?.slug : "/";
                  return (
                    <div className="item" key={index}>
                      <div className="image">
                        <Link to={detailsLink + '/product-details/' + pID}  onClick={()=>dispatch({ type : "SET_NAVIGATION_KEY", payload: "Cart_Page"})}>
                          <img src={link} alt={item?.image} />
                        </Link>                       
                      </div>
                      <div className="itemData" style={{ width: "100%" }}>
                        <h6>{item.name}</h6>
                        {!!item?.price && item?.price !== "Not available" ? (
                          <div className="priceAndQuantity20">
                            <p className="itemprice">{`${
                              item?.currencySymbol
                            }${calculateTotalPrice(
                              item.price,
                              itemQuantities[index] || 1
                            ).toFixed(2)}`}</p>
                            <p className="theme20quantity">
                              <button
                                className="plusminus"
                                onClick={() => increaseQuantity(index, item)}
                              >
                                +
                              </button>
                              {itemQuantities[index] || 1}
                              <button
                                className="plusminus"
                                onClick={() => decreaseQuantity(index, item)}
                              >
                                -
                              </button>
                            </p>
                          </div>
                        ) : (
                          <>
                            <p>{`N/A`} </p>
                          </>
                        )}
                        <button
                          className="closered"
                          onClick={() => handleRemoveItem(item?.id)}
                        >
                          X
                        </button>
                      </div>
                      {/* <button onClick={() => handleAddToOrder(item)}>
                      {isAdded ? (
                        <div className="addeditem">Added</div>
                      ) : (
                        <div className="addtocartitem">Add To Cart</div>
                      )}
                    </button> */}
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className="empty_cartcontainer">
              <div className="emptyiconimg_container">
                <img className="emprtyIconImg" src={emprtyIcon} />
              </div>
              <div
                style={{
                  fontFamily: "Tajawal",
                  fontWeight: 500,
                  color: "rgb(43, 47, 51)",
                  fontSize: "14px",
                  margin: "20px 0",
                }}
              >
                "Your shopping cart is empty."
              </div>
              <div
                style={{
                  color: "#959799",
                  fontFamily: "Tajawal",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                Let's Start shopping now and add items you love.
              </div>
            </div>
          )}

          {data?.length > 0 && (
            <div className="subtotal_cart subtotal_cartcontainer">
              {/* <p >
                <span style={{width:"100%", position:"relative"}}>
                  <input className="applyCodeInput" type="text" name="coupon-code" placeholder="Enter coupon code" value={couponCode} onChange={(e) => handleCoupon(e)} />
                  <button className="applyCodeBtn" type="button" onClick={() => {
                    handleApplyCouponCode();
                  }}>{isAppliedCoupon ? 'Applied' : 'Apply'}</button>
                </span>
              </p> */}

              <p style={{textTransform:"uppercase", coolor:"#222222", fontWeight:"500", margin:"0", fontSize:"22px"}}>Price Details</p>

              <p className="subTotalPrice">
                Subtotal Price:{" "}
                <span>
                  {data?.[0]?.currencySymbol}
                  {totalAmount.toFixed(2)}
                </span>
              </p>
              {!!isAppliedCoupon && (
                <p style={{ color: "green" }}>
                  Item Discount{" "}
                  <span>{`- ${
                    data?.[0]?.currencySymbol + discountAmount
                  }`}</span>
                </p>
              )}
              {/* <p style={{ fontSize: "22px", color: "#797979" }}>
                Total:
                <span>
                  {data?.[0]?.currencySymbol}
                  {isAppliedCoupon
                    ? totalAmountAfterDiscount
                    : totalAmount.toFixed(2)}
                </span>
              </p> */}
              <div className="productCheckout">
              <p style={{ margin:"0",textAlign:"left", fontSize: "16px",fontWeight:"500",fontFamily:"Tajawal",textTransform:"uppercase", color: "#222222" }}>
                Total Amount:
                <p style={{margin:"0", fontSize:"18px", fontWeight:"700",fontFamily:"Tajawal"}}>
                  {data?.[0]?.currencySymbol}
                  {isAppliedCoupon
                    ? totalAmountAfterDiscount
                    : totalAmount.toFixed(2)}
                </p>
              </p>
              <div className="checkoutBtn">
              <button className="checkout53" onClick={() => handleCheckout()}>
                <span>Checkout</span>
              </button>
            </div>
              </div>
            </div>
          )}
          {/* {cartItems.length > 0 && (
            <div className="continue-popup" onClick={openCartSummaryDrawer}>
              <button
                className="continue-popupbutton"
                style={{ color: "white" }}
              >
                <span>{`${cartItems.length} item selected`}</span>
                {`Continue`}
              </button>
            </div>
          )} */}
          {data?.length <= 0 ? (
            <div className="checkout53Box">
              <button className="checkout53" onClick={handleClose}>
                <span>Add Products</span>
              </button>
            </div>
          ) : (
            ''
          )}
        </article>
        <Drawer
          duration={150}
          hideScrollbars={true}
          onClose={closeDrawer}
          isVisible={isVisible}
          className="drawer"
        >
          <CustomerDetails
            closeDrawer={closeDrawer}
            onSubmit={handleFormSubmit}
            loader={loader}
            customerData={customerData}
          />
        </Drawer>
        {/* <Drawer
          duration={150}
          hideScrollbars={false}
          onClose={closeCartSummaryDrawer}
          isVisible={isCartSummaryVisible}
          className="cartdrawer"
        >
          <CartSummary
            cartItems={cartItems}
            closeDrawer={closeCartSummaryDrawer}
            removeItem={removeItem}
            openDrawer={openDrawer}
            closeCartSummaryDrawer={closeCartSummaryDrawer}
            updatedState={updatedState}
            setUpdatedState={setUpdatedState}
            handleSummaryData={handleSummaryData}
            inf_id={data?.influencer_id}
          />
        </Drawer> */}
      </div>
    </section>
  );
};
