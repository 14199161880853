import React, { useEffect } from "react";
import { useState } from "react";
import { environmentVariables } from "../../../../config/env.config";
import nextarrow from '../../../../Images/theme18-next-gallery-arrow.png';
import prevarrow from '../../../../Images/theme-18-prev-gallery-arrow.png';
import { Lightbox } from "../../../Components/PortfolioPopup/PortfolioPopup";

const GalleryCarousel = ({ slides, video }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [backgroundImage, setBackgroundImage] = useState(
  //   `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[0]?.image})`
  // );
  // const [backgroundImage1, setBackgroundImage1] = useState(
  //   `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[0]?.image})`
  // );
  // useEffect(() => {
  //   setBackgroundImage(
  //     `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex]?.image})`
  //   );
  //   setBackgroundImage1(
  //     `url(${environmentVariables?.apiUrl}uploads/gallery/${
  //       slides[currentIndex + 1]?.image
  //     })`
  //   );
  //   console.log(
  //     `url(${environmentVariables?.apiUrl}uploads/${slides[currentIndex]?.image})`,
  //     "backgroundimage"
  //   );
  // }, [currentIndex]);

  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImage1, setBackgroundImage1] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (slides.length > currentIndex) {
      setBackgroundImage(
        `url(${environmentVariables?.apiUrl}uploads/gallery/${slides[currentIndex]?.image})`
      );
      setBackgroundImage1(
        `url(${environmentVariables?.apiUrl}uploads/gallery/${
          slides[currentIndex + 1]?.image
        })`
      );
    }
  }, [currentIndex, slides]);

  const slideStyles = {
    width: "100%",
    height: "300px",
    borderRadius: "5px",
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const sliderStyles = {
    position: "relative",
    height: "100%",
  };

  const rightArrowStyles = {
    // position: "absolute",
    // top: "50%",
    // transform: "translate(0, -50%)",
    // right: "32px",
    fontSize: "45px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
  };

  const leftArrowStyles = {
    // position: "absolute",
    // top: "50%",
    // transform: "translate(0, -50%)",
    // left: "32px",
    fontSize: "45px",
    color: "#fff",
    zIndex: 1,
    cursor: "pointer",
  };

  const slideStylesWidthBackground = {
    ...slideStyles,
    cursor:"pointer",
  };
  const slideStylesWidthBackground2 = {
    ...slideStyles,
    cursor:"pointer",
    backgroundImage: `url(${environmentVariables?.apiUrl}uploads/${
      slides[currentIndex + 1]
    })`,
  };
  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  const dotsContainerStyles = {
    display: "flex",
    justifyContent: "center",
    color: "#585858",
  };

  const dotStyle = {
    margin: "0 3px",
    cursor: "pointer",
    fontSize: "30px",
  };
  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    if (video) {
      const newIndex = isFirstSlide ? slides.length : currentIndex - 1;
      setCurrentIndex(newIndex);
    } else {
      const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
      setCurrentIndex(newIndex);
    }
  };
  const goToNext = () => {
    if (video) {
      const isLastSlide = currentIndex === slides.length;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    } else {
      const isLastSlide = currentIndex === slides.length - 1;
      const newIndex = isLastSlide ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }
  };
  const openLightbox = (index) => {
    setSelectedImage(index);
  };

  const closeLightbox = () => {
    setSelectedImage(null);
  };
  return (
    <div className="theme15-gallery-wrapper">
      <div className="theme15-gallery-inner-wrapper"> 
        <div onClick={goToPrevious} >   <img src={prevarrow} alt='prev arrow' />   </div>        
        <div onClick={goToNext} >  <img src={nextarrow} alt="next arrow" />  </div>
      </div>
      {/* <div>
        <div onClick={goToPrevious} style={leftArrowStyles}>
          ❰
        </div>
        {video && currentIndex == slides.length ? (
          <video className="mx-auto d-block img-fluid" controls>
            <source
              src={video && `${environmentVariables?.apiUrl}uploads/${video}`}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        ) : (
          <></>
        )}
        <div onClick={goToNext} style={rightArrowStyles}>
          ❱
        </div>
      </div> */}
      {/* <div className="twoimages ">
        <div
          style={{
            ...slideStylesWidthBackground,
            backgroundImage: backgroundImage,
          }}
        ></div>
        <div
          style={{
            ...slideStylesWidthBackground2,
            backgroundImage: backgroundImage1,
          }}
        ></div>
      </div> */}
      {slides.length > 0 && (
        <div className="twoimages ">
          {backgroundImage && (
            <div
              style={{
                ...slideStylesWidthBackground,
                backgroundImage: backgroundImage,
              }}
              onClick={() => openLightbox(currentIndex)}
            ></div>
          )}
          {backgroundImage1 && (
            <div
              style={{
                ...slideStylesWidthBackground2,
                backgroundImage: backgroundImage1,
              }}
              onClick={() => openLightbox(currentIndex + 1)}
            ></div>
          )}
        </div>
      )}
         {selectedImage !== null && (
                          <Lightbox
                            src={`${environmentVariables?.apiUrl}uploads/gallery/${slides[selectedImage]?.image}`}
                            slides={slides}
                            currentIndex={selectedImage}
                            onClose={closeLightbox}
                            onPrevious={(index) => setSelectedImage(index)}
                            onNext={(index) => setSelectedImage(index)}
                          />
                        )}
      {/* <div style={dotsContainerStyles}>
        {slides.map((slide, slideIndex) => {
          return (
            <div
              style={{
                ...dotStyle,
                color: currentIndex == slideIndex ? "#ffa438" : "#525252",
              }}
              key={slideIndex}
              onClick={() => goToSlide(slideIndex)}
            >
              ●
            </div>
          );
        })}
      </div> */}
    </div>
  );
};

export default GalleryCarousel;
