import React, { useContext, useEffect, useState } from "react";
import "./Infpopupdesign.css";
import './Additional.css';
import closeimg from "../../../Images/close.png";
// import backimg from '../../../Images/back_arrow_icon.svg'
import DropdownInf from "./DropdownInf";
import infimage from "../../../Images/banner9.png";
import Producttableinf from "./Producttableinf";
import axios from "axios";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Addinfluancerdetailspopup = ({
  isOpen,
  onClose,
  handleClosePopup,
  children,
  handleFetchedInfluencerData,
  selectedPercentage,
  fetchinfluencerpercentage,
  formikData
}) => {
  if (!isOpen) return null;

  const [localPercentage, setLocalPercentage] = useState(selectedPercentage);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [selectedBusinessTitle, setSelectedBusinessTitle] = useState(null);

  const [searchQuery, setSearchQuery] = useState("");
  const [allmktbusiness, setAllMrkBusiness] = useState();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allInfluencer, setAllInfluencer] = useState([]);
  const [Influencerid, setInfluencerid] = useState();
  const { userData } = useContext(userContext);

  const urlParams = new URLSearchParams(window.location.search);
  const influencerBusinessId = urlParams.get("id");

  const handleSelectedProducts = (products) => {
    setSelectedProducts(products);
  };

  const fetchProductsByBusinessId = () => {
    axios
      .get(`${environmentVariables?.apiUrl}api/v1/influencer/getproductbyBusinessId?id=${influencerBusinessId}`)
      .then((response) => {
        setFetchedProducts(response.data); // Update state with fetched products
        handleFetchedInfluencerData(response?.data?.result);
      })
      .catch((error) => {
        console.log("Error fetching products:", error);
      });
  };
  
  const handlePostRequest = () => {
    if(selectedProducts.length>0){
      if (isSubmitting) return;
      setIsSubmitting(true);

      let data = {
        influencer_id: formikData?.influencer_id,
        influencer_business_id: influencerBusinessId,
        productData: selectedProducts,
        business_id: selectedBusiness,
        selectedPercentage: selectedPercentage,
      };
      
      axios
        .post(
          `${environmentVariables?.apiUrl}api/v1/influencer/create_products`,
          data,
          {
            headers: {
              _token: userData?.token,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setIsSubmitting(false);
          setSelectedProducts([]); // Clear selection to avoid re-adding the same products
          fetchProductsByBusinessId();
          onClose();
          toast.success(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
        });

        fetchProductsByBusinessId();
    }else{
      toast.error("Please choose the products.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  // useEffect(() => {
  //   handlePostRequest();
  // }, []);

  const GetAllBusinesshandle = () => {
    axios
      .get(`${environmentVariables?.apiUrl}api/v1/influencer/getMKTBusiness`)
      .then((res) => setAllMrkBusiness(res.data.result))
      .catch((err) => console.log(err));
  };

  const AllInfluencerHandler = () => {
    axios
      .get(`${environmentVariables?.apiUrl}api/v1/business/getAllInfluencers`)
      .then((response) => {
        setAllInfluencer(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AllInfluencerHandler();
  }, []);

  useEffect(() => {
    if (isOpen) {
      GetAllBusinesshandle();
    }
  }, [isOpen]);

  useEffect(() => {}, []);

  // const options = ["SheLuxe", "Option 2", "Option 3"];
  const options = allmktbusiness?.map((business) => business.title);
  const optionsId = allmktbusiness?.map((business) => business.id);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSetSelected = (selectedId, selectedTitle) => {
    setSelectedBusiness(selectedId);
    setSelectedBusinessTitle(selectedTitle);
  };


  useEffect(() => {
    if (isOpen) {
      fetchProductsByBusinessId(); // Fetch initial data each time the popup opens
    }
  }, [isOpen]);


  const percentageOptions = Array.from({ length: 100 }, (_, i) => i + 1);

  const handleChange = (event) => {
    const newPercentage = event.target.value;
    setLocalPercentage(newPercentage);
    fetchinfluencerpercentage(newPercentage);
  };

  useEffect(() => {
    setLocalPercentage(selectedPercentage);
  }, [selectedPercentage]);

  

  return (
    <div className="modal-overlaynew influancermodal" onClick={onClose}>
      <div className="modal-content2" onClick={(e) => e.stopPropagation()}>
        {/* <buttom className="modalbackbtn" onClick={onClose}> <img src={backimg} width="25"/> Back</buttom> */}
        <button className="modalclosebtn" onClick={onClose}>
          <img src={closeimg} width="40" />
        </button>
        <div className="modalcontantinner">
          <div className="containertop">
            <div className="dropdowndiv">
              {/* <h4>Select Influencer </h4> */}
              <h2>Choose Products</h2>
              {/* <DropdownInf options={allInfluencerNames} selected={selectedInfluencer} setSelected={setSelectedInfluencer} /> */}
              {/* <select
                value={selectedInfluencer}
                onChange={(e) => setSelectedInfluencer(e.target.value)}
              >
                <option value="">Select Influencer</option>
                {allInfluencer.map((influencer) => (
                  <option key={influencer.id} value={influencer.id}>
                    {influencer.name}
                  </option>
                ))}
              </select> */}
            </div>
            {/* <div className='dropdowndiv'>
                    <h4>Choose Businesses </h4>
                    <DropdownInf options={options} selected={selectedBusiness} setSelected={setSelectedBusiness} />
                  </div> */}
            {/* <h2>Products</h2> */}
            <div>
              <div className="percentageSelectBox">
      <label htmlFor="percentageSelect">Choose Influencer Commission: </label>
      <select
        id="percentageSelect"
        value={selectedPercentage}
        onChange={handleChange}
      >
        <option value="">-- Select --</option>
        {percentageOptions.map((percentage) => (
          <option key={percentage} value={percentage}>
            {percentage}%
          </option>
        ))}
      </select>
              </div>
      {selectedPercentage && (
        <p className="selectedPercentageText">
         <span> You selected:  </span><span><strong>{selectedPercentage}%</strong></span>
        </p>
      )}
            </div>
            <div className="table-actions">
              <input
                type="text"
                placeholder="Search Product Name"
                value={searchQuery}
                onChange={handleSearchChange}
                className="search-input"
              />
              <DropdownInf
                options={allmktbusiness}
                selected={selectedBusiness}
                selectedTitle={selectedBusinessTitle}
                handleSetSelected={handleSetSelected}
              />
            </div>
          </div>
          <div className="containermiddle">
            <Producttableinf
              selectedBusiness={selectedBusiness}
              onSelectedProductsChange={handleSelectedProducts}
              options={options}
              optionsId={optionsId}
              allmktbusiness={allmktbusiness}
              searchQuery={searchQuery}
              handleSetSelected={handleSetSelected}
              selectedPercentage={selectedPercentage}
              formikData={formikData}
            />
          </div>
          <div className="containerbottom">
            <button type="button" disabled={isSubmitting} onClick={handlePostRequest}>
              {isSubmitting ? "Adding..." : "ADD"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Addinfluancerdetailspopup;
