import React from "react";
function SubContactInfo(props) {
  let rs = props.rs;
  let ff = rs?.contactInfo?.content;
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }

  // console.log(ff,"adfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Contact Info</h3>
        <ul class="conlink">
          <li>
            <a href={ff?.contactInfoPhone ? `tel:${ff?.contactInfoPhone}` : ""}>
              <i class="fa-solid fa-phone"></i>
              <span>{ff?.contactInfoPhone}</span> 
               {ff.optionalContactInfoPhone?.split("-")?.[1]?.length > 0 &&           
                <span>{ff?.optionalContactInfoPhone || ``} </span>           
              }
            </a>
          </li>
          <li>
            <a href={ ff?.contactInfoEmail ? `mailto:${ff?.contactInfoEmail}`: ""}>
              <i class="fa-solid fa-envelope"></i>
              <span>{ff?.contactInfoEmail}</span> 
            </a>
          </li>


          {ff?.contactInfoWebUrl && 
          <li>
            {/* <a href={(ff?.contactInfoWebUrl)?`http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}`:''} target="_blank" className="contactinfolink content"> */}
            <a
              href={(ff?.contactInfoWebUrl) ? `http://${ff?.contactInfoWebUrl.replace(/^(https?:\/\/)?(www\.)?/, '')}` : '#'}
              onClick={(e) => {
                if (!ff?.contactInfoWebUrl) {
                  e.preventDefault(); // Prevents the default link behavior
                }
              }}
              target={ff?.contactInfoWebUrl ? '_blank' : undefined}
              className="contactinfolink content"
            >
              <i class="fa fa-globe"></i>
              <span className="mailurllong"> {ff?.contactInfoWebUrl} </span>
            </a>
          </li>
          
        }
        
{ff?.contactInfoAddress && 
          <li>
            <a >
              <i className="fa-solid fa-location-dot"></i>
              <span>
                {ff?.contactInfoAddress?.split('\n').map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </a>
          </li>
}
{ff?.contactInfoOptAddress && (
                <div style={{ paddingTop: "5px",textAlign:"center",color:"#815738"  }}>
                  <p style={{ fontSize: "14px",color:"#815738" }}>Alternative Address</p>
                  <span style={{padding:"0px", fontSize: "14px"}}>
                    {ff?.contactInfoOptAddress?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </span>
                </div>
              )}  
         
        </ul>
      </article>
    </>
  );
}
export default SubContactInfo;
