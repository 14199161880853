import React from "react";
function SubGoogleReview(props) {
  let rs = props?.rs;
  // console.log(rs,"!RF#E");
  let ff = rs?.googlereview;
  let loc = rs?.googleaddress
  let totlreview = rs?.review

  console.log("ffffff7",ff);
  // if (ff?.length) {
  //   ff = JSON?.parse(ff);
  // }
  // if (ff?.length) {
  //   ff = JSON?.parse(ff);
  // }

  // console.log(ff,"@%#Wadfff")
  //return {rs?.contactInfo && ( )
  return (
    <>
      <article class="contact sec">
        <h3 class="sectitle">Google Review</h3>
        {/* <p style={{color:"white",fontWeight:"800",marginBottom:"15px"}}>Total Review: {totlreview}</p> */}
        {/* <p style={{ fontFamily: `"Red Hat Display", sans-serif` ,color:"#D9D9D9",fontWeight:"400",textAlign:"center", fontSize:"13px", marginTop:"10px"}}>star {rs?.rating} <span style={{margin:"0 1px"}}>|</span> {totlreview } review</p> */}

        <ul class="conlink">
          <li>
          <a href={`https://www.google.com/search?q=${ff}-${loc}`} target="_blank" rel="noopener noreferrer">
                    <button 
                    style={{padding:"12px 30px",
                      background: "#9bc61e",
                      color: "#fff",
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: "16px",
                     
                      border: "none",
                      borderRadius: "6px",
                      }}
                    >Review us on Google</button>
                  </a>
          </li>
        </ul>
      </article>
    </>
  );
}
export default SubGoogleReview;
