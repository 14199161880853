import React, { useEffect } from "react";
import { useState } from "react";
import { environmentVariables } from "../../../../config/env.config";
import { Lightbox } from "../../../Components/PortfolioPopup/PortfolioPopup";

const GalleryCarousel = ({ slides, video }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const [selectedImage, setSelectedImage] = useState(null);
  
  const openLightbox = (index) => {
    setSelectedImage(index);
  };
  
  const closeLightbox = () => {
    setSelectedImage(null);
  };
  return (
    <div className="newgallery" style={{marginBottom:"30px"}}>
      <ul>
        {slides.length > 0 &&
          slides.map((val,index) => (
            <li>
              <img
                src={`${environmentVariables?.apiUrl}uploads/gallery/${val?.image}`}
                onClick={() => openLightbox((currentIndex + index) % slides.length)}
              />
            </li>
          ))}
      </ul>
      {selectedImage !== null && (
                        <Lightbox
                          src={`${environmentVariables?.apiUrl}uploads/gallery/${slides[selectedImage]?.image}`}
                          slides={slides}
                          currentIndex={selectedImage}
                          onClose={closeLightbox}
                          onPrevious={(index) => setSelectedImage(index)}
                          onNext={(index) => setSelectedImage(index)}
                        />
                      )}
    </div>
  );
};

export default GalleryCarousel;
