import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import axios from "axios";
import { environmentVariables } from "../../config/env.config.js";
import { userContext } from "../../context/userContext.js";
import { currencyContext } from "../../context/currencyContext.js";
import PageName from "../Utils/PageName.js";
import successright from "../../Images/check.png";
import { json, useNavigate } from "react-router";
import CircularLoader from "../Components/CircularLoader/CircularLoader.js";
import { toast } from "react-toastify";

const MiniMktProductPayNowRedirect = () => {
  const [paymentData, setPaymentData] = useState(null);
  const [amount, setAmount] = useState(null);
  const [planPrice,setPlanPrice]=useState();
  const [planPriceCurrency,setPlanPriceCurrency]=useState();
  const [isVerifying, setIsVerifying] = useState(true);
  const [DeliveryDate, setDeliveryDate] = useState(null);
  const { userData } = useContext(userContext);
  const [paymentPopUp, setPaymentPopUp] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const navigate = useNavigate();
  const { search } = location;
  // const urlParams = new URLSearchParams(search);
  const urlParams = new URLSearchParams(window.location.search);
  const payStatus = urlParams.get("status");
  const [ttransID, setTtransID] = useState("NA");
  const [orderID, setOrderID] = useState("NA");
  let planData = JSON.parse(localStorage.getItem("plan_pay"));

  let businessUrlData = localStorage.getItem('businessUrl');    
  const handleClosePopUp = () => {
    setPaymentPopUp(false);
    if (paymentSuccess) {
      handleSendWhatsAppNotification();
    } else {
      window.location.href= businessUrlData;
    }
  };

  const handleSendWhatsAppNotification = ()=>{
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/minimarket/getOrdersDataForSendNotificationByPayId?pay_id=${orderID}`,
      headers: { }
    };

    axios.request(config)
    .then((response) => {
      let responseData = response?.data?.result;
      whatsappclick(responseData);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  
  const whatsappclick = (customerData) => {
    // for redirecting on orders page
    localStorage.setItem('needToRedirectOnOrders', 'Yes');
    window.location.href= businessUrlData;
  };

  const handleRedirect = async () => {
    // Extract transaction token and company reference from URL params
    const urlParams = new URLSearchParams(window.location.search);
    setTtransID(urlParams.get("orderid"))
    setOrderID(urlParams.get("orderid"))
    setPlanPrice(urlParams.get("amount"))
    setPlanPriceCurrency(urlParams.get("currency"))
    if (payStatus=="success" ) {
      try {
        setPaymentPopUp(true);
        setPaymentSuccess(true);
      } catch (error) {
        console.error("Error processing payment order:", error);
      }   
    } else {
      toast.error("Payment failed");
      setIsVerifying(false);
      window.location.href= businessUrlData;
    }
  };
  useEffect(() => {
    handleRedirect();    
  }, []);

  return (
    <>
      <PageName PageName="Razor Payment" />
      {isVerifying ? (
        <div className="loader-container">
          <CircularLoader />
        </div>
      ) : (
        <form>
          <button
            type="submit"
            className="btn try_theme_btn mt-3 mx-auto d-block"
          >
            <CircularLoader size={20} />
          </button>
        </form>
      )}

      {paymentPopUp && (
        <div className="popup-outer">
          <div className="popup-overlay"></div>
          <div className="popup-inner">
            <div className="fs-1 payment_heaader">
              {/* {paymentSuccess ? "Payment Successful" : "Payment Failed"} */}
            </div>
            <img src={successright} className="" />
            <div className="inner-box mt-4">
              <h1 className="name">Payment Details</h1>
              <div className="orderbox">
                <ul className="list-group mb-3">
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Receiver</span>
                    <strong>Businessbay</strong>
                  </li>
                  <li className="list-group-item d-flex justify-content-between">
                    <span>Payment Method Type</span>
                    <strong>Razor Pay</strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between">
                    <span> Amount Paid</span>
                    <strong>
                      {planPriceCurrency} {planPrice}
                    </strong>
                  </li>

                  <li className="list-group-item d-flex justify-content-between">
                    <span>Transaction Id</span>
                    <strong>{ttransID}</strong>
                  </li>
                </ul>
                <button
                  type="submit"
                  className="btn try_theme_btn mx-auto d-block submitclose"
                  onClick={handleClosePopUp}
                >
                  close
                </button>
              </div>
              <div className="order-box">{/* Your payment details here */}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MiniMktProductPayNowRedirect;
