import React, { useEffect, useContext } from "react";
import { useState } from "react";
import KeywordForm from "./KeywordForm";
import { businessObjContext } from "../../../context/businessObjContext";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css"
import { getServicesArray } from "../../../utils/utility";
import ServiceFormsaloon from "./ServiceFormsaloon";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import axios from "axios";
import { toast } from "react-toastify";

function NewBusinessDetilsCreateKeywords({ setKeywordArray, businessObj }) {
  const [formCount, setFormCount] = useState(0);
  const [isAddServiceDisable, setIsAddServiceDisable] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [buttonLink, setButtonLink] = useState();
  const [servicesData, setServicesData] = useState([]);
  const { businessObjData } = useContext(businessObjContext);
  const [showFileInput, setShowFileInput] = useState(false);
  const { userData, setUserData } = useContext(userContext);

  const createForm = () => {
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);

    const newForm = {
      formCount: newFormCount,
      id:null,
      banner: null,
      name: "",
      description: "",
      buttonLink: "",
      buttonName: "",
      bannerImage: null,
      isImageUpload:true
    };
    const updatedData = [...servicesData, newForm];
    setServicesData([...servicesData, newForm]);

    // setServicesData([
    //   ...servicesData,
    //   {
    //     formCount: formCount,
    //     banner: null,
    //     name: "",
    //     description: "",
    //     buttonLink: "",
    //     buttonName: "",
    //     bannerImage: null,
    //   },
    // ]);
    setIsAddServiceDisable(true);
    setIsAddDisable(true);
    // const newForm = (
    // );
    // setFormList([...formList, newForm]);
    // setFormCount(formCount + 1);
  };
  const checkAlreadyInUsedService = (idx, catData)=>{
    return new Promise((resolve, reject)=>{
      try{
        let getRemoveData = servicesData[idx];
        let getCatSlug = catData.find(cat => cat.id == getRemoveData?.id);
        let config={
          method:"post",
          url:`${environmentVariables?.apiUrl}api/v1/business/checkCategoryIsAlreadyInUsed`,
          data:{
            bid:businessObjData?.id,
            cat_slug:getCatSlug?.category_slug
          },
          headers: {
            _token: userData?.token,
          },
        }
        axios.request(config).then(response=>{
          if(!!response.data){
            if(!!response?.data?.status){
              resolve(true);
            }else{
              resolve(false);
            }
          }else{
            resolve(false);
          }
        }).catch(error=>{
          console.log(error)
          resolve(false);
        }) 
      }catch(err){
        console.log(err);
        resolve(false);
      }
    })
       
  }

  const removeForm = async(index, categoryData) => {
    if(businessObjData?.business_category == 'mini_marketplace'){
      let checkCategory = await checkAlreadyInUsedService(index, categoryData);  
      if(!checkCategory){
          toast.error("The category is in use and cannot be deleted.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
        });
      }else{
        const newServicesData = [...servicesData];
        newServicesData.splice(index, 1);
        setServicesData(newServicesData);
        setKeywordArray(newServicesData);
        setIsAddServiceDisable(false);
        // const updatedFormList = formList.filter((form) => form.key !== formKey);
        // setFormList(updatedFormList);
      }
    }else{
      const newServicesData = [...servicesData];
      newServicesData.splice(index, 1);
      setServicesData(newServicesData);
      setKeywordArray(newServicesData);
      setIsAddServiceDisable(false);
      // const updatedFormList = formList.filter((form) => form.key !== formKey);
      // setFormList(updatedFormList);
    } 
  };

  useEffect(() => {
    if (businessObjData?.metaKeywordObj && businessObjData?.metaKeywordObj?.keywords) {
      const serviceDataFromLocal = getServicesArray(businessObjData?.metaKeywordObj?.keywords);
      const updatedServiceData = serviceDataFromLocal.map((service, index) => ({
        ...service,
        formCount: index,
        isImageUpload:false
      }));
      // console.log(updatedServiceData,"updatedServiceData@$ ")
      setFormCount(updatedServiceData.length)
      // setServicesData([...servicesData, ...serviceDataFromLocal]);//// edit here
      setServicesData([...updatedServiceData]);
    } else {
      setServicesData([]);
    }
  }, [businessObjData]);

  useEffect(() => {
    setKeywordArray(servicesData);
  }, [servicesData]);
  
  return (
    <>
      <div className="row" id="AddNewStyleForm">
      {servicesData.map((form, index) => (
        <>
          <KeywordForm
            formCount={formCount}
            servicesData={servicesData}
            setIsAddServiceDisable={setIsAddServiceDisable}
            setServicesData={setServicesData}
            removeForm={removeForm}
            index={index}
            businessObjData={businessObjData}
          ></KeywordForm>
        </>
      ))}
    </div>
  
    
      <button
        disabled={isAddServiceDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add
      </button>
    </>
  );
}

export default NewBusinessDetilsCreateKeywords;
