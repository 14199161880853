import styled from "styled-components";

export const Root = styled.div`
display:flex;
justify-content:center;
`;
export const Container = styled.div`
width: 1260px;
padding: 20px;
background-color: #fff;
`;
export const ContainerJs = styled.div`
width: 430px;
padding: 20px;
background-color: #fff;
`;
export const ResumeLeftRightContainer = styled.div`
display:flex;
width: 100%;
@media(max-width: 767px){
    display:flex;
    flex-direction: column ;
}
`;

export const ResumeLeftRightContainerjs = styled.div`
display:flex;
width: 100%;
flex-direction: column ;
`;
export const ResumeLeftWrapper = styled.div`
width: 40%;
@media(max-width: 767px){
    width: 100%;
}
`;
export const ResumeLeftWrapperJs = styled.div`
width: 100%;

`;

export const ResumeRightWrapper = styled.div`
width: 60%;
@media(max-width: 767px){
    width: 100% !important;
}
`;

export const ResumeRightWrapperJs = styled.div`
width: 100%;
`;

export const RightContainer = styled.div`

`;
export const ProfileHeadingWrapper = styled.div`
${(p)=>p.Profile && `
@media(max-width: 767px){
    margin-top: 30px;
}
`}
`;
export const ProfileHeadingWrapperJs = styled.div`
margin-top: 30px;
`;
export const ProfileHeaading = styled.div`
font-family:inter;
font-weight: 600;
font-size: 28px;
text-transform:uppercase;
color:#000000;

@media(max-width: 767px){
    font-size: 18px;
}
`;

export const ProfileHeaadingJs = styled(ProfileHeaading)`
font-size: 20px;
`;

export const HorizontalLineWrapper = styled.div``;
export const HorizontalLine = styled.div`
width: 50px;
height: 5px;
margin: 15px 0;
letter-spacing:2px;
background: linear-gradient(to right, #583EFF, #8F00FF);
`;

export const ProfileSummaryWrapper = styled.div`
margin: 20px 0;
padding: 10px 20px 15px 0;
`;
export const ProfileSummary = styled.div`
font-family:inter;
font-weight: 500;
font-size: 20px;
@media(max-width: 767px){
    font-size: 16px;
   
}
`;

export const ProfileSummaryJs = styled(ProfileSummary)`
font-size: 18px;
`;

export const WorkExperienceBoldHeadingWrapper = styled.div``;
export const WorkExperienceHeading = styled.div`
font-family:inter;
font-weight: 700;
font-size: 28px;
text-transform:uppercase;
color:#000000;

@media(max-width: 767px){
    font-size: 18px;
    font-weight: 600;
}
`;

export const WorkExperienceHeadingJs = styled(WorkExperienceHeading)`
font-size: 20px;
`;


export const DesignationWrapper = styled.div`
padding: 0 0 10px 0;

${(p)=>p.DesignationName && `
padding: 0 0 0px 0;
`}
`;
export const Designation = styled.div`
font-family:inter;
font-weight: 600;
font-size: 24px;
text-transform:uppercase;
color:#000000;
@media(max-width: 767px){
    font-size: 16px;
}
`;

export const DesignationJs = styled(Designation)`
font-size: 18px;
`;

export const DateCompanyLocationWrapper = styled.div`
display:flex;
align-items: center;
text-align: center;

@media(max-width: 767px){
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    text-align: center;
    justify-content: center;
}

`;
export const DateCompanyLocationWrapperJs = styled.div`
width: 100%;
display: flex;
align-items: center;
text-align: start;
justify-content: start;
padding-bottom: 10px;

`;
export const DateWrapper = styled.div`
background: linear-gradient(to right, #583EFF, #8F00FF);
padding: 2px 10px;
font-family:inter;
font-weight: 600;
font-size: 12px;
color: #fff;

@media(max-width: 767px){
    // width: 50%;
}
`;

export const DateWrapperJs = styled.div`
background: linear-gradient(to right, #583EFF, #8F00FF);
padding: 2px 10px;
font-family:inter;
font-weight: 600;
font-size: 12px;
color: #fff;
width: 50%;
text-align: center;
`;


export const Date = styled.div``;
export const CompanyLocationWrapper = styled.div`
padding: 0 10px;
@media(max-width: 767px){
    // width: 50%;
    padding: 5px 0;
}
`;
export const CompanyLocationWrapperJs = styled.div`
width: 50%;
padding: 0 10px;


`;
export const CompanyLocationName = styled.div`
font-family:inter;
font-weight: 600;
font-size: 20px;
color:#000000;

@media(max-width: 767px){
    font-size: 16px;
}
`;

export const CompanyLocationNameJs = styled(CompanyLocationName)`
font-size: 14px;
`;
export const WorkexperienceSummaryWrapper = styled.div``;
export const WorkexperienceSummary = styled.div`
font-family:Cabin;
color: #4D4D4D;
font-weight: 500;
font-size: 20px;
text-align: justify;
margin: 15px 0;
padding-right: 20px;
@media(max-width: 767px){
    font-size: 16px;
}
`;

export const WorkexperienceSummaryJs = styled(WorkexperienceSummary)`
font-size: 18px;
`;

export const SingleWorkExperience = styled.div`
margin-bottom: 30px;
`;

export const SingleWorkExperienceJs = styled(SingleWorkExperience)`
padding: 20px 0 0 0;
`;

export const ProjectLinkWrapper = styled.div``;
export const ProjectLink = styled.div`
color: #1D87E2;
cursor:pointer;
font-family:inter;
font-weight: 500;
font-size: 20px;
&:hover{
    color:#1313c2;
}

@media(max-width: 767px){
    font-size: 16px;
}
`;

export const ProjectLinkJs = styled(ProjectLink)`
font-size: 18px;
font-weight: 400;
font-style:italic;
`;

export const ProjectWrapperContainer = styled.div`
margin-top: 40px;
`;


export const EducationWrapper = styled.div`
margin: 40px 0;
`;
export const SchoolUniversity = styled.div`
font-family:inter;
font-weight: 500;
font-size: 20px;
color:#4D4D4D;
@media(max-width: 767px){
    font-size: 16px;
}

`;
export const SchoolUniversityWrapper = styled.div``;
export const PassingYearWrapper = styled.div``;
export const PassingYear = styled.div`
font-family:inter;
font-weight: 500;
font-size: 20px;
color:#000;

@media(max-width: 767px){
    font-size: 16px;
}
`;

export const EducationSingleContainer = styled.div`
margin: 10px 0 30px 0;
`;

export const SchoolUniversityBordName = styled.div`
font-family:inter;
font-weight: 500;
font-size: 20px;
color:#000;

@media(max-width: 767px){
    font-size: 16px;
}
`;

export const AchivementsWrapper = styled.div`
margin: 20px 0;
`;
export const AchivementsUl = styled.ul`
// padding:5px 0;
`;
export const ActivementsList = styled.li`
font-family:inter;
font-weight: 600;
font-size: 20px;
&:before {
    content: '•'; 
    font-size:25px;
    color: #000;
    display: inline-block;
    width: 1em;
    margin-left: 0px;
   
}
@media(max-width: 767px){
    font-size: 16px;
}

`;

export const PersonalInformationWrapper = styled.div``;

export const PersonalInfoWrapper = styled.div`
display:grid;
grid-template-columns: 1fr 1fr 1fr;
grid-gap: 40px;
padding: 5px 0;

@media(max-width: 1024px){
    grid-template-columns: 1fr 1fr; 
}
`;
export const PersonalInfoWrapperJs = styled.div`
display:grid;
grid-template-columns: 1fr 1fr;
grid-gap: 40px;
padding: 5px 0;
`;
export const PersonalWrapper = styled.div``;
export const PersonalName = styled.div`
font-family:inter;
font-weight: 600;
font-size: 20px;
@media(max-width: 767px){
    font-size: 16px;
}
`;

export const PersonalNameJs = styled(PersonalName)`
font-size: 18px;
`;
export const PersonalWrapperValue = styled.div``;
export const PersonalNameValue = styled.div`
font-family:inter;
font-weight: 400;
font-size: 20px;
@media(max-width: 767px){
    font-size: 16px;
}
`;

export const PersonalNameValueJs = styled(PersonalNameValue)`
font-size: 18px;
`;

export const LeftContainer = styled.div`
background-color: #F8F6FA;
width:90%;
@media(max-width: 767px){
    width:100%;
}
`; 
export const LeftContainerJs = styled.div`
background-color: #F8F6FA;
width:100%;

`; 
export const Imagewrapper = styled.div`
position: relative;
width: 100%;
`;
export const Image = styled.img`
width: 100%;
`;

export const PhoneResponsiveCustomisation = styled.div`
@media(max-width: 767px){
   display:flex;
   flex-direction: column;
}
`;


export const UserProfileNameWrapper = styled.div`
margin-bottom:10px;
`;
export const Username = styled.div`
font-family:inter;
font-weight: 600;
font-size: 32px;
color: #fff;
text-transform: capitalize;
break-word:break-all;
`;
export const UsernameJs = styled(Username)`
font-size: 30px;
`;
export const UserDesignationWrapper = styled.div``;
export const UserDesignation = styled.div`
font-family:inter;
font-weight: 600;
font-size: 20px;
color:#fff;
padding: 10px 35px;
background: linear-gradient(to right, #583EFF, #8F00FF);
break-word:break-all;
`;
export const UserDesignationJs = styled(UserDesignation)`
font-size: 18px;
`;

export const UserProfileDestinationWrapper = styled.div`
    display: flex;
    position: absolute;
    bottom: 50px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    // left: 70px;
    width: 100%;
`;
export const PersonalDetailContainerWrapper = styled.div`
background-color: #1A1B1C;
width: 100%;
display: flex;
flex-direction: column;
padding: 25px 20px;

`;
export const PersonalDetailsWrapper = styled.div`
display: flex;
padding: 10px 20px 20px 0;
align-items: center;
`;
export const PhoneIconTextWrapper = styled.div``;
export const PhoneWrapper = styled.img`
width: 30px;

@media(max-width: 767px){
    width: 24px;
}

`;

export const PhoneWrapperJs = styled(PhoneWrapper)`
width: 24px;
`;
export const PhoneTextValueWrapper = styled.div`
margin-left: 30px;

@media(max-width: 525px){
    margin-left: 20px;
}
`;

export const PhoneTextValueWrapperJs = styled(PhoneTextValueWrapper)`
margin-left: 20px;
`;
export const PhoneText = styled.div`
font-family:inter;
font-weight: 600;
font-size: 22px;
color:#fff;

@media(max-width: 1024px){
    font-size: 20px;
}
@media(max-width: 525px){
    font-size: 14px;
}
`;

export const PhoneTextJs = styled(PhoneText)`
font-size: 18px;
`;
export const PhoneValue = styled.div`
font-family:inter;
font-weight: 500;
font-size: 16px;
color:#fff;
word-break: break-word;
@media(max-width: 525px){
    font-size: 14px;
    padding: 2px 0;
}

`;


export const ExpertiesContainerWrapper = styled.div`
padding: 25px 20px;
`;

export const ExpertiesContainerWrapperJs = styled.div`
padding: 20px 0px;
`;

export const ExpertiexListWrapper = styled.div``;
export const Experties = styled.div`
font-family:inter;
font-weight: 600;
font-size: 22px;
color: #1A1B1C;
padding: 5px 0;

@media(max-width: 767px){
    font-size: 16px;
}
`;

export const ExpertiesJs = styled(Experties)`
font-size: 18px;
`;

export const KeySkillsContainerWrapper = styled.div`
padding: 20px;
`;

export const KeySkillsContainerWrapperJs = styled.div`
padding: 20px 0px;
`;

export const CertificatoinContainerWrapper = styled.div`
padding: 20px;
`;

export const HobbiesContainerWrapper = styled.div`
padding: 20px;
`;
export const HobbiesContainerWrapperJs = styled.div`
padding: 20px 0px;
`;

export const CertificationContainerWrapper = styled.div`
padding: 20px;
`;

export const CertificationContainerWrapperJs = styled.div`
padding: 20px 0px;
`;

export const CertificationName = styled.div`
color: #4D4D4D;
font-family:inter;
font-weight: 500;
font-size: 20px;
@media(max-width: 767px){
    font-size: 16px;
}
`;
export const CertificationValue = styled.div`
font-family:inter;
font-weight: 500;
font-size: 20px;
color:#000;

@media(max-width: 767px){
    font-size: 16px;
}
`;

export const OneProject = styled.div`
padding-bottom: 20px;
`;