import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../../../config/env.config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Cookies from "js-cookie";
import CaptchaService from "../../../../service/CaptchaService";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";
import { userContext } from "../../../../context/userContext";
import SignupPopup from "./SignupPopup";

const LoginSignup = ({
  isModalOpen,
  setIsModalOpen,
  isModalOpenRegister,
  setIsModalOpenRegister,
  isOtpModalOpen,
  setIsOtpModalOpen,
  forwardedemail,
  setFormwadedEmail,
  updatedState,
  setUpdatedState,
}) => {
  const { userData, setUserData } = useContext(userContext);

  const baseUrl = environmentVariables?.apiUrl;
  let lastLoginFormData = localStorage.getItem("login_form");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);
  const [invalidCaptchaErr, setInvalidCaptchaErr] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

 

  const handleChange = (e) => {
    if (e.target.name == "email") {
      setEmail(e.target.value);
    } else if (e.target.name == "password") {
      setPassword(e.target.value);
    }
    // if (email && password) {
    //   setIsLoginDisabled(false);
    // }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleRememberMeChange = (event) => {
    const isChecked = event.target.checked;
    setRememberMe(isChecked);
  };

  const onSubmit = () => {
    // if (email && password && captchaValue) {
    if (email && password) {
      // capcha code
      // if (email && password) {
      setIsSubmit(true);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(email);
      console.log("isCaptchaSolved====>>>", isCaptchaSolved);
      

      if (isValid) {
        if (!!isCaptchaSolved) {
          const storedCaptcha = JSON.parse(localStorage.getItem("captcha"));
          if (!storedCaptcha) {
            toast.error("Captcha expired. Please try again.");
            return;
          }

          axios
            .post(`${baseUrl}api/v1/user/customer_login`, {
              email,
              password,
              captchaValue:isCaptchaSolved,
              captchaId: storedCaptcha.id,
              captchaAns: isCaptchaSolved
            },
            {
              withCredentials: true, 
            })
            .then((res) => {
              setIsSubmit(true);
              localStorage.removeItem("captcha");
              localStorage.setItem("Customer", JSON.stringify(res?.data?.result));
              setUpdatedState(!updatedState);
              Cookies.set("access_token", res?.data?.result?.token, {
                expires: 7,
                domain: "localhost",
                path: "/",
                secure: true,
              });
              setUserData(res?.data?.result);
              toast.success("Logged in successfully", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              setIsSubmit(false);
              setTimeout(() => {
                if (res.data.result.user_type == "super_admin") {
                  // navigate("/super_dashboard");
                  return;
                } else {
                  if (res.data.result.user_type == "Customer") {
                    setIsModalOpen(false);
                    // let getCurrentInfluencerUrl = localStorage.getItem("currentBusinessUrl");
                    // window.location.href=getCurrentInfluencerUrl;
                    // navigate("/customer_profile");
                  }
                }
              }, 1000);
              setIsSubmit(false);
            })
            .catch((err) => {
              // console.log(
              //   "err?.response?.data?.message",
              //   err?.response?.data?.message,
              //   err?.message
              // );

              console.log("error===>>>", err?.response?.data);
              
              toast.error(
                err?.response?.data?.message ||
                  "Email and Password are not correct",
                {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                }
              );
              setIsSubmit(false);              
              if(err?.response?.data?.emailVerify == false){
                setIsOtpModalOpen(true);
                setIsModalOpenRegister(false);
                setIsModalOpen(false);
              }

            });
        }else{
          setInvalidCaptchaErr("Please enter correct captcha answer.");
          setIsSubmit(false);
        }
      } else {
        toast.error("Email is not valid.", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
      }
    } else {
      toast.error("Email, Password and Captcha are required", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
    }
  };

  useEffect(() => {
    if (!!rememberMe) {
      localStorage.setItem("login_form", JSON.stringify({ email, password }));
    }
  }, [rememberMe]);

  useEffect(() => {
    let loginData =
      lastLoginFormData != null ? JSON.parse(lastLoginFormData) : "";
    if (!!loginData) {
      setEmail(loginData?.email);
      setPassword(loginData?.password);
      setRememberMe(true);
    }
  }, []);

  const handleCaptchaSolved = (solved) => {
    setIsCaptchaSolved(solved);
    setInvalidCaptchaErr("");
  };
  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content innerlogin" style={{ bottom: "20vh" }}>
          <div className="form-section">
            {/* <div className="logo"></div> */}
            {/* <h3 >
                  <Link to={`${homelocation}`} className="mainlogoalignloginreg">
                    <img src={Mainlogo} style={{ width: "50%" }} />
                  </Link>
                </h3> */}
            <h3 className="innercreatetitle">Sign in</h3>
            <div className="login-inner-form">
              <form>
                <div className="form-group clearfix">
                  <div className="form-box innerform-box">
                    <input
                      type="text"
                      className="form-control clear_string login_inputfield"
                      placeholder="Enter Your Email*"
                      name="email"
                      value={email}
                      required=""
                      key="email"
                      onChange={(e) => handleChange(e)}
                    />
                    <i
                      className="fa fa-envelope input_icons"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="form-group clearfix">
                  <div className="innerform-box">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control clear_string login_inputfield"
                      placeholder="Enter Your Password*"
                      name="password"
                      key="password"
                      value={password}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <i className="fas fa-eye Eeye"></i>
                      ) : (
                        <i className="fas fa-eye-slash Eeye"></i>
                      )}
                    </span>
                    <i className="fa fa-key input_icons" aria-hidden="true"></i>
                  </div>
                </div>
                <CaptchaService onCaptchaSolved={handleCaptchaSolved} />
                {!!invalidCaptchaErr && <p className="text-danger">{invalidCaptchaErr}</p>}
                {/* <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                  onChange={onChangeRecaptcha}
                  key={captchaReset ? "reset" : "normal"}
                /> */}

                {/* <div className="checkbox form-group clearfix mt-3 PhonealignforgotePass">
                  <div className="form-check float-start">
                    <input
                      type="checkbox"
                      checked={rememberMe}
                      onChange={handleRememberMeChange}
                      className="form-check-input innerform-check-input"
                      id="rememberme"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="rememberme"
                      style={{ color: "black", paddingLeft:"10px" }}
                    >
                      Remember me
                    </label>
                  </div>
                  <Link
                    to="/forgot-password"
                    className="link-light float-end forgot-password PhoneforgotePass"
                  >
                    Forgot your password?
                  </Link>
                </div> */}
                <div className="form-group clearfix mb-0">
                  <button
                    onClick={onSubmit}
                    type="button"
                    className="btn-theme login_button"
                    disabled={isSubmit}
                    style={{width:"100%"}}
                  >
                    {!isSubmit ? "Login" : null}
                    {isSubmit && <CircularLoader size={20} />}
                  </button>
                </div>
              </form>
            </div>
            <p className="text-center donthaveaccount">
              Don't have an account?
              {/* <Link
                className="registerhere"
                to="/register"
                style={{ paddingLeft: "10px", color: "black" }}
              >
                Register here
              </Link> */}
              <button
                onClick={() => {
                  setIsModalOpenRegister(true);
                  setIsOtpModalOpen(false);
                  setIsModalOpen(false);
                }}
              >
                <span>Register here</span>
              </button>
            </p>
          </div>

          <button
            className="innermodal-close"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Close
          </button>
        </div>
      </div>
      {/* {console.log("isModalOpenRegister", isModalOpenRegister)} */}
      {isModalOpenRegister && (
        <SignupPopup
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          forwardedemail={forwardedemail}
          setFormwadedEmail={setFormwadedEmail}
        />
      )}
    </>
  );
};

export default LoginSignup;
