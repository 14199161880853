import React, { useContext, useEffect, useRef, useState } from "react";
import PageName from "../Utils/PageName";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import $ from "jquery";
import { useCallback,useMemo } from "react";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import moment from "moment";
import { fetchCountries } from "../../utils/utility";


function Influencerorders() {

  const debounceTimer = useRef(null);
  const [data, setData] = useState([]);
  
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [editOrderid, setEditOrderid] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [editedData, setEditedData] = useState({
    date: new Date(),
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeButtonRef = useRef(null);
  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [nameOrderId, setNameOrderId] = useState("");
  const [tempData, setTempData] = useState([]);
  const [businesslistData, setBusinesslistData] = useState([]);
  const [cardType, setCardType] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [activeBusinessName, setActiveBusinessName] = useState("");
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const [action] = useState({ fromUser: false });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const createdDate = editedData?.created_at ? new Date(editedData.created_at) : null;

  const [open, setOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");

  const [countries, setCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [businessName, setBusinessName] = useState("");


  const viewPdf = async (d) => {
    const routeName = `/ViewCardDownload/${d.id}`;
    const fullUrl = `${window.location.origin}${routeName}`;
    window.open(fullUrl, "_blank");
  };


  const showModal = () => {
    setIsModalOpen(true);
  };
  const hideModal = () => {
    if (closeButtonRef.current) {
      $(closeButtonRef.current).trigger("click");
    }
    setOpen(false);
    setIsModalOpen(false);
  };

  const statusMap = {
    "new":"Confirmed",
    "outfordelivery":"Out For Delivery",
    // "processing":"Processing",
    // "cancelled":"Cancelled",
    "delivered":"Delivered",
    // "return-request":"Return Request",
    // "return-failed":"Return Failed",
    // "return-success":"Return Success",
    // "refunded":"Refunded"
  };

  const MainData = (page, limit) => {
    axios
      .get(`${baseUrl}api/v1/minimarket/getallownerordersbyid?id=${userData?.id}&page=${page}&limit=${rowsPerPage}&name_order_id=${nameOrderId}&from_date=${selectedDateFrom}&to_date=${selectedDateTo}&business_name=${businessName}&order_status=${selectedOrderStatus}&country_code=${selectedCountryCode}`, {
        headers: {
          _token: userData?.token,
        },
        withCredentials: true,
      })
      .then((response) => {
        
        setData(response?.data?.result);
        setTempData(response?.data?.result);
        setIsLoading(false);
        setPaginationTotalRows(response?.data?.total)        
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const getAllBusinessByPhysicalCard = ()=>{
    axios
    .post(`${baseUrl}api/v1/phy_card/getAllBusinessByPhysicalCard`, {}, {
      headers: {
        _token: userData?.token,
      },
      withCredentials: true,
    })
    .then((response) => {
      let temp = response?.data?.result?.map((el) => el?.title);
      temp = temp.filter((el) => el != null);
      temp = new Set(temp);
      temp = [...temp];
      setBusinesslistData(temp);
    })
    .catch((error) => {
      console.log(error);
      setIsLoading(false);
    });
  }

  const fetchDataCountries = async () => {
    const countryData = await fetchCountries();
    setCountries(countryData);
  };

  const handleCountryChange = (e) => {
    const selectedCode = e.target.value;
    setSelectedCountryCode(selectedCode);
  };
  const handleStatusChange = (e) => {
    const selectedCode = e.target.value;
    setSelectedOrderStatus(selectedCode);
  };

  useEffect(()=>{
    getAllBusinessByPhysicalCard();
    fetchDataCountries();

  },[])

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page; 
    MainData(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); 
    setSelectedRowsPerPage([]);
  };

  useEffect(() => {    
    MainData(1, rowsPerPage);
  }, [rowsPerPage]);

  useEffect(() => {
    setEditedData({ ...editedData, status: selectedStatus });
  }, [selectedStatus]);

  const fetchData = async (id) => {
    console.log(id,"ifdd$%");
    
    try {
      showModal();
      setEditOrderid(id.order_id);
      let obj = id.order_id
      axios
        .get(`${baseUrl}api/v1/minimarket/viewMinimktOrdersByOrderId?id=${obj}`, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((response) => {
          console.log(response?.data?.result?.order_status,"updatedResponse55");
          setEditedData(response?.data?.result);
          setSelectedStatus(response?.data?.result?.order_status);
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setIsSubmit(true);

    editedData.selectedStatus = selectedStatus;
    editedData.physicalCardid = editOrderid;

    const sdata = {
      order_id: editedData.order_id,
      order_status: selectedStatus,
    };

    // sdata.editOrderid = editOrderid;
    // if (sdata?.date !== undefined && sdata?.delivery_date !== undefined) {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${baseUrl}api/v1/minimarket/updateMinimktOrderStatus`,
      headers: {
        _token: userData?.token,
        "Content-Type": "application/json",
      },
      data: sdata,
    };
    axios
      .request(config)
      .then((response) => {
        MainData();
        hideModal();
        toast.success("Successfully change status", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmit(false);
      })
      .catch((error) => {
        console.log(error, "error");
        setIsSubmit(false);
      });
    // } else {
    //   toast.warning("Kindly Fill the Date", {
    //     position: toast.POSITION.TOP_RIGHT,
    //     autoClose: 1000,
    //   });
    //   setIsSubmit(false);
    // }
  };

  const tableExtensions = { export: false, print: false };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        padding: "25px 20px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        padding: "6px 20px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
  };

  const columns = [
    {
      name: "Order Id",
      selector: row=>row.order_id,
      sortable: true,
      width: "15%",
    },
    // {
    //   name: "Business Name",
    //   selector: (row) => row.business_name || "--",
    //   sortable: true,
    // },
    {
      name: "Order Date",
      selector: (row) => {
        let dateString = row?.created_at.split("T")[0];
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
      },
      sortable: true,
    },
    {
      name: "Tx. ID",
      selector: row=>row.pay_id,
      sortable: true,
    },
    {
      name: "Order Amount",
      selector: (row)=> row?.currency + row.total,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: row=>row.payment_status,
      sortable: true,
      cell: (d) => {
        const capitalizedName =
          d?.payment_status?.charAt(0)?.toUpperCase() +
          d?.payment_status?.slice(1);
        return capitalizedName;
      },
    },
    {
      name: "Order Status",
      selector: (row) => {
        if (row?.order_status === "new") {
          return "Confirmed";
        } else if (row?.order_status == "outfordelivery") {
          return "Out For Delivery";
        } else if (row?.order_status == "processing") {
          return "Processing";
        } else if (row?.order_status == "cancelled") {
          return "Cancelled";
        } else if (row?.order_status == "delivered") {
          return "Delivered";
        } else if (row?.order_status == "return-request") {
          return "Return Request";
        } else if (row?.order_status == "return-failed") {
          return "Return Failed";
        } else if (row?.order_status == "return-success") {
          return "Return Success";
        } else if (row?.order_status == "refunded") {
          return "Refunded";
        }else if (row?.order_status == "failed") {
          return "Failed";
        } else {
          return "--";
        }
      },
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      selector: "null",
      cell: (d) => [
        <>
         {d?.order_status != "failed" &&
          <i
            key={d?.id}
            data-bs-toggle="modal"
            data-bs-target="#planselect"
            onClick={() => fetchData(d)}
            className="first fas fa-pen"
          ></i>
          }
          <i
            className="first fas fa-eye"
            onClick={() => handleViewMessage(d)}
            style={{ cursor: "pointer", marginRight: "10px" }}
          ></i>
        </>,
      ],
    },
  ];

  const handleViewMessage = (d) => {
    setOpen(true);
    setSelectedMessage(d);
    setIsModalOpen(true);
  };

  const tableData = {
    columns,
    data,
  };

  const setFilterValueWithDebounce = () => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    debounceTimer.current = setTimeout(() => {
      MainData(1, rowsPerPage);
    }, 1000 * 1);
  };
  useEffect(() => {
    setFilterValueWithDebounce();
    return () => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, [cardType,
    orderStatus,
    activeBusinessName,
    selectedDateFrom,
    selectedDateTo,
    nameOrderId,businessName,
    selectedCountryCode,
    selectedOrderStatus]);

  const handleReset = () => {
    setSelectedDateFrom("");
    setSelectedDateTo("");
    setNameOrderId("")
    setSelectedCountryCode("")
    setSelectedOrderStatus("")
    setBusinessName("")
  };

  return (
    <>
      <PageName PageName="Product Orders" />
      <article>
        <div className="main">
        <div className="" style={{display:"flex", gap:"20px", justifyContent:"flex-end", paddingBottom:"40px"}}>
                <select
                  className="form-select"
                  name="country"
                  value={selectedCountryCode}
                  onChange={handleCountryChange}
                  style={{width:"300px"}}
                >
                  <option value="">Select Country</option>
                  {!!countries && countries?.length > 0 && countries?.map((country) => (
                    !!country?.id &&
                    <option key={country?.name} value={country?.phone_code}>
                      {country?.name?.charAt(0)?.toUpperCase() + country?.name?.slice(1)}
                    </option>
                  ))}
                </select>

                
              </div>


        <div style={{display:"flex",alignItems:"center",justifyContent: "space-between", gap:"20px"}}>
            <input
              type="text"
              // class="form-control clear_string"
              class="superadminsearchinput"
              placeholder="Search by OrderId"
              value={nameOrderId}
              onChange={(e)=>setNameOrderId(e.target.value)}
              style={{width:"300px"}}
            />
              {/* <input
              type="text"
              class="form-control clear_string"
              placeholder="Business Name"
              value={businessName}
              onChange={(e)=>setBusinessName(e.target.value)}
              style={{width:"50%"}}
            /> */}
              <div style={{display:"flex"}}>
                <select
                  className="form-select"
                  name="country"
                  value={selectedOrderStatus}
                  onChange={handleStatusChange}
                  style={{width:"300px"}}
                >
                  <option value="">Select Order Status</option>
                  <option value="new">Confirmed</option>
                  <option value="outfordelivery">Out For Delivery</option>
                  <option value="delivered">Delivered</option>
                  <option value="failed">Failed</option>
                </select>
              <div style={{marginLeft:"10px"}}>
              <DatePicker
                  placeholderText="From Date"
                  selected={selectedDateFrom ? new Date(selectedDateFrom) : null}
                  onChange={(date) => {
                    if (date) {                 
                      const timestamp = date.getTime();
                      setSelectedDateFrom(timestamp);
                    }
                  }}
                  className="form-control"
                  ref={fromDate}
                  dateFormat="dd/MM/yyyy"
                  popperPlacement="bottom-end"
                  />
              </div>
                 <div style={{marginLeft:"10px"}}>
                 <DatePicker
                    placeholderText="To Date"
                    selected={selectedDateTo ? new Date(selectedDateTo) : null}
                    onChange={(date) => {
                      if (date) {
                        const timestamp = date.getTime();
                        setSelectedDateTo(timestamp);
                      }
                    }}
                    className="form-control"
                    ref={toDate}
                    minDate={selectedDateFrom}
                    dateFormat="dd/MM/yyyy"
                    popperPlacement="bottom-end"
                  />
                 </div>
                  <div class="contact_filter_buttonwrapper influcencer_admin_reset" >
                    <button
                      class="contact_filter_buttonstyle"
                      onClick={() => handleReset()}
                    >
                      Reset{" "}
                    </button>
                  </div>
              </div>
               

          
                  
            
          </div>
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <>
              {/* <div class="Contact_filter_fieldbutton_wrapper">
                <div class="Contact_filter_field_wrapper">
                  <div class="col-md-2 Contact_filter_Inputfield">
                    <select
                      value={cardType}
                      onChange={(e) => setCardType(e.target.value)}
                      class="form-select"
                    >
                      <option value="">All Type</option>
                      <option value="pvc">PVC </option>
                      <option value="metal">Metal </option>
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>
                  <div class="col-md-2 Contact_filter_Inputfield">
                    <select
                      value={activeBusinessName}
                      onChange={(e) => setActiveBusinessName(e.target.value)}
                      class="form-select"
                    >
                      <option value="All Business">All Business</option>
                      {businesslistData &&
                        businesslistData?.length &&
                        businesslistData?.map((el, index) => {
                          return <option value={el}>{el}</option>;
                        })}
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>
                  <div class="col-md-2 Contact_filter_Inputfield">
                    <select
                      value={orderStatus}
                      onChange={(e) => setOrderStatus(e.target.value)}
                      class="form-select"
                    >
                      <option value="">All Status</option>
                      <option value="new">Accepted </option>
                      <option value="outfordelivery">Printed </option>
                      <option value= "processing">Dispatched </option>
                      <option value="cancelled">Cancelled </option>
                      <option value="delivered">Completed </option>
                      <option value="return-request">Completed </option>
                      <option value="return-failed">Completed </option>
                      <option value="return-success">Completed </option>
                      <option value="refunded">Completed </option>
                    </select>
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",
                        bottom: "70px",
                      }}
                    ></div>
                  </div>

                  <div class="col-md-2 Contact_filter_Inputfield">
                    <div className="dateiconmain">
                      <DatePicker
                        placeholderText="Oder Date From"
                        selected={selectedDateFrom}
                        onChange={(date) => setSelectedDateFrom(date)}
                        className="form-control"
                        ref={fromDate}
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-end"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => fromDate.current.setOpen(true)}
                      >
                        <i class="fa-solid fa-calendar"></i>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-2 Contact_filter_Inputfield">
                    <div className="dateiconmain">
                      <DatePicker
                        placeholderText="Order Date To"
                        selected={selectedDateTo}
                        onChange={(date) => setSelectedDateTo(date)}
                        className="form-control"
                        ref={toDate}
                        dateFormat="dd/MM/yyyy"
                        popperPlacement="bottom-end"
                      />
                      <div
                        class="contact_filter_toDate_calander_icon"
                        onClick={() => toDate.current.setOpen(true)}
                      >
                        <i class="fa-solid fa-calendar"></i>
                      </div>
                    </div>
                  </div>

                  <div class="contact_filter_buttonwrapper">
                    <button
                      class="contact_filter_buttonstyle"
                      onClick={() => handleReset()}
                    >
                      Reset{" "}
                    </button>
                  </div>
                </div>
              </div> */}
              {" "}
             <div id="specific-table" style={{marginTop:"30px"}}>
             <DataTableExtensions
                {...tableData}
                {...tableExtensions}
                customStyles={customStyles}
                filterPlaceholder="Search Physical Card"
              >
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  sortIcon={<SortIcon />}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  paginationServer
                  paginationTotalRows={paginationTotalRows}
                  paginationPerPage={paginationPerPage}
                  paginationRowsPerPageOptions={[10,20,50,100,300,1000,10000]}
                  onChangePage={handlePageChange}
                  onChangeRowsPerPage={handleRowsPerPageChange}
                  onSelectedRowsChange={handleOnSelectedRowsChange}
                  dense
                  customStyles={customStyles}
                />
              </DataTableExtensions>
             </div>
            </>
          )}
        </div>
      </article>

      <div
        className={`modal fade ${isModalOpen ? "show" : ""}`}
        id="planselect"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content ">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Edit Status
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={closeButtonRef}
              ></button>
            </div>
            <div class="modal-body">
              <h5>Order id : {editedData?.order_id}</h5>
              <hr />
              <form onSubmit={handleUpdate}>
                <div className="row g-3 mb-3">
                  <div className="col">
                    <h5>Status </h5>
                    <br />
                    <select
                      className="form-select"
                      value={selectedStatus}
                      onChange={(e) => setSelectedStatus(e.target.value)}

                    >
                      {Object.keys(statusMap)?.map((statusKey, inde) => (
                        <option key={inde} value={statusKey}
                        disabled={
                          (selectedStatus === "outfordelivery" && statusKey === "new") ||
                          (selectedStatus === "delivered" &&
                            (statusKey === "new" || statusKey === "outfordelivery"))
                        }
                       
                        >
                          {statusMap[statusKey]}
   
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <div className="col ">
                    <h5>Delivery Date</h5>
                    <br />
                    <DatePicker
                      selected={
                        editedData?.delivery_date
                          ? new Date(editedData.delivery_date)
                          : null
                      }
                      onChange={handleDateChange}
                      className="form-select"
                      dateFormat="yyyy-MM-dd"
                      popperPlacement="bottom-end"
                      minDate={createdDate}
                    />
                  </div> */}
                </div>
                <div id="Coupanadmin_Button_Wrapper">
                  <button type="submit" className="btn btn-primary save-cta">
                    {isSubmit ? <CircularLoader size={20} /> : " Save changes"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => hideModal()}
        // onClose={() => setOpen(false)}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        // style={{ outline: "none" }}
        // style={{overflow:"scroll", outline:"none"}}
      >
        <Sheet
          variant="outlined"
          sx={{
            maxWidth: 500,
            minWidth:390,
            borderRadius: "md",
            p: 3,
            boxShadow: "lg",
          }}
          className="Infproductdetails"
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={2}
            textAlign="center"
          >
            ORDER DETAILS
          </Typography>
          <div style={{display:"flex", justifyContent:"space-between"}}><span>Order Id:</span> <span style={{fontWeight:"700"}}>#{selectedMessage?.order_id}</span></div>
          <div style={{display:"flex", justifyContent:"space-between"}}><span>Order Date:</span> <span style={{fontWeight:"700"}}>{moment(selectedMessage?.created_at).format("DD-MMM-YYYY")}</span></div>
          <div style={{display:"flex", justifyContent:"space-between"}}><span>Amount Paid:</span> <span style={{fontWeight:"700"}}>{selectedMessage?.currency}{selectedMessage?.total}</span></div>
          {/* <div style={{display:"flex", justifyContent:"space-between"}}><span>Discount:</span> <span style={{fontWeight:"700"}}>{selectedMessage?.currency}{selectedMessage?.discount}</span></div> */}

          {selectedMessage?.product_data &&
            selectedMessage?.product_data.length > 0 &&
            selectedMessage?.product_data.map((product, index) => (
              <div key={index} className="product-container" style={{marginBottom:"20px", display:"flex", flexDirection:"column",marginTop:"20px"}}>

                  <div style={{display:"flex"}}>
                    <div className="product-info productinfodiv">
                      {/* <div className="modalheadingdiv">Image:</div> */}
                      <img
                        src={`${baseUrl}uploads/${product?.productInfo?.image}`}
                        alt={product?.productInfo?.product_name}
                        className="item-image popupproductimg"
                      />
                    </div>
                    <div>
                      <div className="product-info productinfodiv">
                        {/* <div className="modalheadingdiv">Product Name:</div> */}
                        {/* {console.log(product,"4r4r")} */}
                        <div className="modalcontentdiv">
                          {product?.productInfo?.product_name}
                        </div>
                      </div>

                      <div className="product-info productinfodiv">
                        {/* <div className="modalheadingdiv">Price:</div> */}
                        <div className="modalcontentdiv">
                        {selectedMessage?.currency}{product?.single_price} x {product?.quantity}
                        </div>
                      </div>
                    </div>
                  </div>
                 
                
                {/* <div className="product-info productinfodiv">
                  <div className="modalcontentdiv">{product?.quantity}</div>
                </div> */}

                {/* Add more fields if needed */}
              </div>
            ))}

            <div>
              <div><h4>Shipping Address</h4></div>
              <div>
                <div>Name: <strong>{selectedMessage?.user_name}</strong></div>
                <div>Email: <strong>{selectedMessage?.user_email}</strong></div>
                <div>Phone: <strong>{selectedMessage?.user_country_code} {selectedMessage?.user_mobile}</strong></div>
                <div>Address: <strong>{selectedMessage?.user_address}</strong></div>
              </div>
            </div>
        </Sheet>
      </Modal>
    </>
  );
}
export default Influencerorders;