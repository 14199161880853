import React from 'react';
import './Theme53.css';
import axios from 'axios';
import { environmentVariables } from '../../../../config/env.config';
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getOrderStatus } from '../../../../utils/utility';

export const OrderHistoryDetailPage = ({onBack, orderDetails})=>{
console.log(orderDetails,"orderDetails^YT")

const baseUrl = environmentVariables?.apiUrl;

 const downloadInvoice=async(orderid)=>{
    try {
      const response = await axios.get(
        `${baseUrl}api/v1/influencer/downloadInvoice?orderid=${orderid}`,
        {
          responseType: "blob", 
        }
      );
      console.log("response", response?.data);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `Invoice_${orderid}.pdf`;
      a.click();
    } catch (error) {
      console.log(error.message);
      Swal.fire({
        icon: "error",
        text: error.message || error?.response?.data?.message,
      });
    }
  }

    return(
        // <div>
           <section className='order-history-page' >
            <div className='order-history-page-inner'>
                <article>
                    <h3 className='sectitle'>Order Details</h3>  
                    <p className='backArrowHistory'  >
                        <span><i onClick={onBack} className="fa-solid fa-arrow-left"></i></span>
                    </p> 
                    <div className='orderhistorylist'>
                        <div style={{padding:"20px 0 10px"}}>
                          <h3><span>Order Id:</span> <span style={{color:"#818181"}}>#{orderDetails?.order_id}</span></h3>
                          <h3><span>Order Date:</span> <span style={{color:"#818181"}}>{moment(orderDetails?.created_at).format("DD-MMM-YYYY")}</span></h3>
                          <h3><span>Amount Paid:</span> <span style={{color:"#818181"}}>{orderDetails?.currency}{orderDetails?.total} ({orderDetails?.product_data?.length == 1? '1  item':orderDetails?.product_data?.length+' items'})</span></h3>
                          <h3><span>Discount:</span> <span style={{color:"#818181"}}>{orderDetails?.currency}{orderDetails?.discount}</span></h3>
                        </div>
                        <ul style={{maxHeight:"240px", overflowY:"overlay"}}>
                        {orderDetails?.product_data?.length > 0 ? (
                            orderDetails.product_data.map((product, index) => (
                            <li key={index}>
                                <div style={{ display: "flex", gap: "20px" }}>
                                {/* Product Image */}
                                <div className="orderhistoryimg">
                                    <img
                                    src={`${baseUrl}uploads/${product?.productInfo?.image}`}
                                    alt={product?.productInfo?.product_name || "Product Image"}
                                    />
                                </div>

                                {/* Product Details */}
                                <div>
                                    <h2>
                                    {orderDetails?.order_status === "new" ? "Order Placed" : getOrderStatus(orderDetails?.order_status)}{" "}
                                    On {" "}
                                    {new Intl.DateTimeFormat("en-US", {
                                        weekday: "short",
                                        month: "short",
                                        day: "numeric",
                                    }).format(new Date(orderDetails?.updated_at))}
                                    </h2>
                                    <h3>{product?.productInfo?.product_name}</h3>
                                    <h4>
                                    Quantity: {product?.quantity} | {orderDetails?.currency}{product?.price_x_qty?.toFixed(2)}
                                    </h4>
                                </div>
                                </div>
                            </li>
                            ))
                        ) : (
                            <p>No products found in this order.</p>
                        )}
                        </ul>

                        <div className='shippingaddress'>
                            <h4>Shipping Address</h4>
                            {/* <p>{orderDetails?.user_name} </p> */}
                            <p style={{display:"flex", flexDirection:"column"}}><span style={{fontWeight:"500", textTransform:"capitalize"}}>{orderDetails?.user_name}</span> {orderDetails?.user_address} </p>
                            <p><span>Email :</span> {orderDetails?.user_email}</p>
                            <p><span>Phone :</span> {orderDetails?.user_country_code + ' '+ orderDetails?.user_mobile}</p>
                        </div>
                         <div className='invoicemain' style={{color: "white"}} >
                            <button disabled={(orderDetails?.payment_status == "complete")?false:true} onClick={()=>downloadInvoice(orderDetails?.order_id)} >Download Invoice 
                              <FontAwesomeIcon icon="fa-brands fa-x-twitter" />
                            </button>
                        </div>
                    </div>
                </article>
            </div>
        </section> 
        // </div>
        
    )
}