import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function InfluenerTransactions() {
  const fromDate = useRef(null);
  const toDate = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [isCoupon, setIsCoupon] = useState(false);
  const [payinOut, setPayinOut] =useState("");
  const [paidAmount, setPaidAmount] =useState("");
  const [amountStatus, setAmountStatus] =useState("");
  const [paymentdate, setPaymentdate] = useState("");
  const [description, setDescription] = useState("");
  const [editStatus, setEditStatus] =useState("");

  const [selectedDateFrom, setSelectedDateFrom] = useState("");
  const [selectedDateTo, setSelectedDateTo] = useState("");
  const [nameOrderId, setNameOrderId] = useState("");




  // const [couponName, setCouponName] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  // const [couponCode, setCouponCode] = useState("");
  // const [discountType, setDiscountType] = useState("");
  // const [discountValue, setDiscountValue] = useState("");
  const [activate, setActivate] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const createCouponModalRef = useRef(null);
  const tableExtensions = { export: false, print: false };

  const [isUpdate, setIsUpdate] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCoupon = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/influencer/getAllClaimRequest?name_order_id=${nameOrderId}&from_date=${selectedDateFrom}&to_date=${selectedDateTo}`)
        .then((response) => {
          setData(response?.data?.result);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false)
        });
    } catch (err) { }
  };
  useEffect(() => {
    fetchCoupon();
    return () => {
      // Remove the event listener when the component unmounts
      $(createCouponModalRef.current).off("hidden.bs.modal");
    };
  }, [selectedDateFrom,selectedDateTo,nameOrderId]);

  const handleReset = () => {
    setSelectedDateFrom("");
    setSelectedDateTo("");
    setNameOrderId("")
  };


const columns = [
  {
    name: "Influencer Name",
    selector: row => row.infUser ? row.infUser.name : "--",
    sortable: true,
  },
  {
    name: "Influencer Email",
    selector: (row) =>
      row.infUser && row.infUser.email
        ? row.infUser.email
        : "--",
    sortable: true,
    width: "200px",
    cell: (row) => (
      <div style={{textTransform:"lowercase"}}>
        {row.infUser && row.infUser.email ? row.infUser.email : "--"}
      </div>
    ),
  },
  {
    name: "Requested Date",
    selector: (row) => {
      console.log("row===>>>", row);      
      const date = new Date(row.created_at);
      const options = { year: 'numeric', month: 'short', day: 'numeric'};
      return date.toLocaleString('en-US', options); 
    },
    sortable: true,
  },
  {
    name: "Requested Amount",
    selector: (row) => row.requested_currency + row.requested_amount,
    sortable: true,
  },
  {
    name: "Paid Amount",
    selector: (row) => row.payment_status == "failed" ? "--" : row.requested_currency + row.paid_amount || "--",
    sortable: true,
  },
  {
    name: "Payment ID",
    selector: (row) => row.payment_id || "--",
    sortable: true,
  },
  {
    name: "Payment Date",
    selector: (row) => {
      const date = new Date(row.payment_date);
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      if (isNaN(date) || date.getFullYear() === 1970) {
        return '--';
      }
      return date.toLocaleString('en-US', options);
    },
    sortable: true,
  },
  {
    name: "Payment Status",
    selector: (row) => row.payment_status == "failed" ? "Cancel" : row.payment_status,
    sortable: true,
  },
 
  {
    name: "Action",
    sortable: false,
    cell: (d) => [
      <>
        <i
          key={`edit-${d.id}`}
          className="first fas fa-pen"
          onClick={() => {
            handleEdit(d);
          }}
        ></i>
      </>,
    ],
  },
];

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };

  const tableData = {
    columns,
    data,
  };

  const handleSubmit = async (e) => {
    try {
      // if (isUpdate == false && couponId == "") {

      //   if (couponName == "" || couponName?.trim() == "") {
      //     toast.error("Coupon name is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (couponCode == "" || couponCode?.trim() == "") {
      //     toast.error("Coupon Code is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (discountType == "" || discountType?.trim() == "") {
      //     toast.error("Discount Type is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (discountType == "percentage" && discountValue > 99) {
      //     toast.error("Discount Value cannot be equal to or more than 100%", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (discountValue == "" || discountValue?.trim() == "") {
      //     toast.error("Discount Value is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   } else if (limit == "" || limit?.trim() == "") {
      //     toast.error("limit is mandatory", {
      //       position: toast.POSITION.TOP_RIGHT,
      //       autoClose: 1000,
      //     });
      //     return;
      //   }
      // }

      let obj = {
        payinpayout_id: payinOut,
        paid_amount: paidAmount,
        payment_status: amountStatus,
        payment_date: paymentdate,
        "payment_id": paymentId,
        payment_remarks: description
      };
      // console.log(obj,"objectt",activate)
      axios.post(`${baseUrl}api/v1/influencer/updateClaimRequest`,obj)
      .then((res)=>{
        console.log(res,"asdasdasdasdd")
        if (res && res?.data && res?.data?.success) {
          fetchCoupon();
          handleClearState();
          toast.success(res?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          $(createCouponModalRef.current).modal("hide"); 
        }
      }).catch((err)=>{
        console.log(err)
        toast.error(err?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      })
      
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const handleClearState = () => {
    try {
      // e.preventDefault();
      setPayinOut("");
      setPaidAmount("");
      setAmountStatus("");
      setPaymentdate("");
      setEditStatus("")
      setDescription("");
      setActivate(false);
      setPaymentId("");
      setIsUpdate(false);
      setCouponId("");
      setIsCoupon(false);
    } catch (err) {
      console.log(err, "erororo");
    }
  };

  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      // setCouponId(el?.id);
      console.log(el, "ellll2l");
      setPayinOut(el?.id);
      // setPaidAmount(el?.requested_amount);
      if (el?.payment_status === "pending") {
        setPaidAmount(el?.requested_amount); // Set requested amount to paidAmount
      } else {
        setPaidAmount(el?.paid_amount || ""); // Otherwise, set it to paid amount
      }
      setAmountStatus(el?.payment_status);
      setEditStatus(el?.payment_status)
      const formattedDate = el?.payment_date
      ? new Date(el.payment_date).toISOString().split("T")[0]
      : "";
      setPaymentdate(formattedDate);
      setDescription(el?.payment_remarks);
      // setActivate(el?.is_active == 0 ? false : true);
      setPaymentId(el?.payment_id);
      setIsCoupon(true);
      setIsStatus(false);
      $(createCouponModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "Errorro");
    }
  };

  return (
    <>
      <PageName PageName="Manage Coupon" />
      <article>
        <div className="main">  
        <div style={{display:"flex",alignItems:"center",justifyContent: "flex-start"}}>
            <input
              type="text"
              class="form-control clear_string"
              placeholder="Payment Id"
              value={nameOrderId}
              onChange={(e)=>setNameOrderId(e.target.value)}
              style={{width:"300px"}}
            />
            <div style={{display:"flex"}}>
          <div style={{marginLeft:"10px"}}>
          <DatePicker
              placeholderText="From Date"
              selected={selectedDateFrom ? new Date(selectedDateFrom) : null}
              onChange={(date) => {
                if (date) {                 
                  const timestamp = date.getTime();
                  setSelectedDateFrom(timestamp);
                }
              }}
              className="form-control"
              ref={fromDate}
              dateFormat="dd/MM/yyyy"
              popperPlacement="bottom-end"
            />

          </div>
           <div style={{marginLeft:"10px"}}>
           <DatePicker
                placeholderText="To Date"
                selected={selectedDateTo ? new Date(selectedDateTo) : null}
                onChange={(date) => {
                  if (date) {
                    const timestamp = date.getTime();
                    setSelectedDateTo(timestamp);
                  }
                }}
                className="form-control"
                ref={toDate}
                minDate={selectedDateFrom}
                dateFormat="dd/MM/yyyy"
                popperPlacement="bottom-end"
             
              />
           </div>
            </div>
            <div class="contact_filter_buttonwrapper influcencer_admin_reset" >
                    <button
                      class="contact_filter_buttonstyle"
                      onClick={() => handleReset()}
                    >
                      Reset{" "}
                    </button>
                  </div>
            
          </div>        
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <div id="specific-table" style={{marginTop:"30px"}}>
              <DataTableExtensions 
            {...tableData} 
            filterPlaceholder="Search Coupon"
            customStyles={customStyles}
            {...tableExtensions}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                customStyles={customStyles}
              />
            </DataTableExtensions>
          </div>
          )}
        </div>
        <div
          className={`modal fade ${isCoupon ? "show" : ""}`}
          id="createCouponModal" 
          tabIndex="-1"
          ref={createCouponModalRef}
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  Update Transaction
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClearState(e)}
                ></button>
              </div>
              <div class="modal-body" style={{padding:"0"}}>
                <div className="mb-2" id="Coupons_admin_createform_container">
                  {/* <div>
                    <input
                     placeholder="Payin/Payout id"
                      type="text"
                      value={payinOut}
                      className="form-control"
                      onChange={(e) => setPayinOut(e.target.value)}
                    />
                  </div> */}

                  <div>
                    {/* Code */}
                    <input
                      placeholder="Paid Amount"
                      type="text"
                      className="form-control"
                      value={paidAmount}
                      onChange={(e) => setPaidAmount(e.target.value)}
                      readOnly={editStatus === "pending" || editStatus === "completed"}
                    />
                  </div>
                  <div style={{position:"relative"}}>
                    {/* Discount Type */}
                    {/* <div style={{position:"absolute",top:"15px", right:"25px", fontSize:"20px",cursor:"pointer"}}>
                    <i class="fa-solid fa-chevron-down"></i>
                    </div> */}
                    <select
                      className="form-control"
                      onChange={(e) => setAmountStatus(e.target.value)}
                      value={amountStatus}
                      style={{cursor: 'pointer',appearance:"auto", borderRight: "26px solid transparent"}}
                      disabled={editStatus === "completed" || editStatus == "failed"}
                    >
                      <option value="" disabled> select Payment Status </option>
                      <option value="pending">Pending</option>
                      <option value="completed">completed</option>
                      <option value="failed">Cancel</option>
                    </select>
                  </div>
                  <div>
                    <input
                    placeholder="Payment Date"
                      type="date"
                      className="form-control"
                      value={paymentdate}
                      onChange={(e) => setPaymentdate(e.target.value)}
                      readOnly={editStatus === "completed"}
                    />
                  </div>
                </div>
              </div>
              <div class="modal-body" style={{padding:"0"}}>
                <div className="mb-2">
                  <div>
                    {/* paymentId */}
                    <input
                    placeholder="Payment ID"
                      type="text"
                      className="form-control"
                      value={paymentId}
                      onChange={(e) => setPaymentId(e.target.value)}
                      readOnly={editStatus === "completed"}
                    />
                  </div>
                  <div>
                    {/* Description */}
                    <input
                    placeholder="Payment Remarks"
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      readOnly={editStatus === "completed"}
                    />
                  </div>

              
                </div>
              </div>

              <div class="modal-footer" id="Coupanadmin_Button_Wrapper">
                <button
                 id="Coupan_edit_Button"
                  type="button"
                  class="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isStatus ? (
                    <CircularLoader size={20} />
                  ) : isUpdate && couponId != "" ? (
                    "Update"
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default InfluenerTransactions;
