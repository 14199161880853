import React from "react";
import { getServicesArray } from "../../../../utils/utility";

function SubAlternateUrls(props) {
  let rs = props.rs;

  return (
    <>
      <article className="servicesName sec">
        <h3 class="sectitle service">Useful Links </h3>
        <div className="mainservicebox">
          {rs?.alternatUrlObj?.content && (
            <ul className="servesbox">
              {getServicesArray(rs?.alternatUrlObj?.content).map((val) => {
                const url = val?.description?.startsWith("http")
                  ? val?.description
                  : `https://${val?.description}`;
                return (
                  val?.name &&
                  val?.description && (
                    <li key={val?.name} className="service-list">
                      <a href={url} target="_blank" style={{color: "#F0E4CE"}} rel="noopener noreferrer">
                        {val?.name}
                      </a>
                    </li>
                  )
                );
              })}
            </ul>
          )}
        </div>
      </article>
    </>
  );
}
export default SubAlternateUrls;
