import React, { useEffect, useContext } from "react";
import { useState } from "react";
import AlternateUrlForm from "./alternateUrlForm";
import { businessObjContext } from "../../../context/businessObjContext";

function NewBusinessDetilsCreate({ setAlternateUrlArray }) {
  const [formList, setFormList] = useState([]);
  const [formCount, setFormCount] = useState(0);
  const [isAddTestimonialDisable, setIsAddAlternateUrlDisable] = useState(false);
  const [isAddDisable, setIsAddDisable] = useState(false);
  const [testimonialData, setTestimonialData] = useState([]);
  const [data, setData] = useState({
    formCount: formCount,
    image: null,
    name: "",
    description: "",
    imageView: null,
  });
  const { businessObjData } = useContext(businessObjContext);

  
  const removeForm = (formKey) => {
    const newFormList = testimonialData?.filter(
      (val, index) => index !== formKey
    );
    setTestimonialData([...newFormList]);
    if (formKey == testimonialData?.length - 1) {
      setIsAddAlternateUrlDisable(false);
    }
  };

  useEffect(() => {
    let getAlternateUrlData;

    if (businessObjData && businessObjData?.alternatUrlObj) {
      getAlternateUrlData = JSON.parse(businessObjData?.alternatUrlObj?.content);
    }
    if (getAlternateUrlData && getAlternateUrlData?.length) {
      const updatedTestimonialData = getAlternateUrlData.map((el, index) => ({
        ...el,
        formCount: index,
        isImageUpload: false,
      }));
      setFormList(updatedTestimonialData);
      setFormCount(updatedTestimonialData?.length);
      setTestimonialData(updatedTestimonialData);
    } else {
      setFormList([]);
      setFormCount(0);
      setTestimonialData([]);
    }
  }, [businessObjData]);

  const createForm = () => {
    setIsAddAlternateUrlDisable(true);
    const newFormCount = formCount + 1;
    setFormCount(newFormCount);
    const newForm = {
      formCount: newFormCount,
      image: null,
      name: "",
      description: "",
      imageView: null,
      isImageUpload: true,
    };
    const updatedData = [...formList, newForm];
    setTestimonialData(updatedData);
  };

  useEffect(() => {
    setFormList(testimonialData);
    setAlternateUrlArray(testimonialData);
    if (testimonialData.length == 0) {
      setIsAddAlternateUrlDisable(false);
    }
  }, [testimonialData]);

  return (
    <>
      <div className="row" id="AddNewStyleForm">
        {testimonialData.map((form, index) => (
          <AlternateUrlForm
            formCount={formCount}
            testimonialData={testimonialData}
            setIsAddAlternateUrlDisable={setIsAddAlternateUrlDisable}
            setTestimonialData={setTestimonialData}
            removeForm={removeForm}
            index={index}
          ></AlternateUrlForm>
        ))}
      </div>
      <button
        disabled={isAddTestimonialDisable}
        type="button"
        onClick={createForm}
        className="btn btn-cu addbuttonedit"
      >
        + Add
      </button>
    </>
  );
}

export default NewBusinessDetilsCreate;
