// store.js
import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combineReducers } from 'redux';
// Reducer
const counterReducer = (state = { count: 0 }, action) => {
  switch (action.type) {
    case 'INCREMENT':
      return { count: state.count + 1 };
    case 'DECREMENT':
      return { count: state.count - 1 };
    default:
      return state;
  }
};



const userReducer = (state = { userObject: null }, action) => {
  
  switch (action.type) {
    case 'SET_USER_OBJECT':
      return { userObject: action.payload };
    case 'SET_USER':
      return { userObject: action.payload };
    case 'CLEAR_USER':
      return { userObject: null };
    default:
      return state;
  }
};

const productCartItemReducer = (state = { productCartItemData: [] }, action) => {
  
  switch (action.type) {
    case 'SET_PRODUCT_CART_ITEM':
      return {
        ...state,
        productCartItemData: [...state.productCartItemData, action.payload.newItem], // Add new item to the array
      };
    case 'REMOVE_PRODUCT_CART_ITEM':
      return {
        ...state,
        productCartItemData: state.productCartItemData.filter(
          (item) => item.id !== action.payload.id // Remove item by id
        ),
      };
    case 'CLEAR_PRODUCT_CART_ITEM':
      return {
        ...state,
        productCartItemData: [], // Clear the cart array
      };
    default:
      return state;
  }
};

const showCartReducer = (state = { showCartPage: false }, action) => {
  
  switch (action.type) {
    case 'SET_SHOW_CART':
      return {
        ...state,
        showCartPage: action.payload.status, // Add new item to the array
      };
    case 'REMOVE_SHOW_CART':
      return {
        ...state,
        showCartPage: action.payload.status,
      };
    default:
      return state;
  }
};

const navigationReducer = (state = { navigationKey: null }, action) => {
  switch (action.type) {
    case 'SET_NAVIGATION_KEY':
      return {
        ...state,
        navigationKey: action.payload, 
      };
    default:
      return state;
  }
};
  
  const rootReducer = combineReducers({
    counter: counterReducer,
    user: userReducer,
    productCartItem: productCartItemReducer,
    showCart: showCartReducer,
    navigation: navigationReducer,
  });


const store = createStore(
  rootReducer,
  composeWithDevTools() 
);

export default store;
