import React, { useState, useContext,useCallback } from 'react';
import Drawer from "react-bottom-drawer";
import BottomDrawer from './BottomDrawer';
import ProductDetailpage from './ProductDetailpage';
import { environmentVariables } from "../../../../config/env.config";
import { currencyContext } from "../../../../context/currencyContext.js";
import { Base64 } from 'js-base64';
import Captcha from '../../../../utils/Captcha.js';
import { Link } from 'react-router-dom';
import { handleErrorImage } from "../../../../utils/utility";
import leftIcon from "../../../../../src/Images/MarleftIcon.png";
import RightIcon from "../../../../../src/Images/MarRightIcon.png";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';


const ExtAllCollection = ({currencySymbol, products, sendDatafromParent, rs, currentPage, totalPages, onNextPage, onPrevPage}) => {
    const [open, setOpen] = useState(true);
    const fronturl = environmentVariables?.frntUrl;
    const httpProtocol = environmentVariables?.httpProtocol;
    const [isDetailPageVisible, setIsDetailPageVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);
    const dispatch = useDispatch();
    const ReduxObject = useSelector(state => state.productCartItem?.productCartItemData);
    

    const openDrawer = useCallback((item) => {
        setSelectedItem(item);
        setIsVisible(true);
    }, []);

    const closeDrawer = useCallback(() => {
        setIsVisible(false);
        setIsCaptchaSolved(false);
    }, []);

    if (isDetailPageVisible) {
        return <ProductDetailpage />;
    }

    const handleFormSubmit = (formData) => {
        sendDatafromParent(formData);
    };

    const handleAddToCart = (menuItem) => {
        const newItem = {
          id: menuItem.id,
          name: menuItem.product_name,
          price: menuItem.product_price,
          image: `${environmentVariables.apiUrl}uploads/${menuItem.image}`,
          quantity: 1,
          currencySymbol: rs.currency_symbol,
        };
      
        dispatch({
          type: 'SET_PRODUCT_CART_ITEM',
          payload: { newItem }, 
        });
    };
    
    const handleRemoveToCart = (itemId) => {      
        dispatch({
          type: 'REMOVE_PRODUCT_CART_ITEM',
          payload: { id: itemId }
        });
    };

    // console.log("products====>>>", products);
    

    return (
        <div>
            <div className='py-4 px-0 grid grid-cols-2 gap-2'>
                {products?.map((item, index) => {
                    let pID = Base64.encode(item?.id);
                    let detailsLink = (rs?.slug_subdomain != null) ? httpProtocol + rs?.slug_subdomain : (rs?.slug != null) ? fronturl + rs?.slug : "/";
                    return (
                        <div key={index} className='border border-efefef p-2 rounded-2xl'>
                            <Link to={detailsLink + '/product-details/' + pID} onClick={()=>dispatch({ type : "SET_NAVIGATION_KEY", payload: "List_Page"})}>
                                <img src={item?.imageView ? item.imageView : `${environmentVariables?.apiUrl}uploads/${item?.image}`} onError={handleErrorImage} className='object-cover prodimg' alt={item?.name} />
                            </Link>
                            <div className="text-start font-normal text-base mt-2 px-1 pt-2 font-Tajawal" onClick={()=>dispatch({ type : "SET_NAVIGATION_KEY", payload: "List_Page"})}>
                                <Link to={detailsLink + '/product-details/' + pID} className='minimarketname'>{item?.name || item?.product_name}</Link>
                            </div>
                            <div className="flex justify-between items-center mt-1 px-1 pb-2">
                                <div className='bg-coffee rounded'>
                                    {item?.product_category === 'enquiry'?
                                        <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded" onClick={() => openDrawer(item)} style={{width:"95px"}}>
                                            Enquire Now
                                        </button>
                                    :
                                        ReduxObject.some(cartItem => cartItem.id === item?.id) ?
                                        <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded" onClick={() => handleRemoveToCart(item?.id)} style={{width:"95px"}}>
                                            Added
                                        </button>
                                        :
                                        <button className="bg-blue-500 text-sm font-semibold text-white font-bold px-2 py-2 rounded" onClick={() => handleAddToCart(item)} style={{width:"95px"}}>
                                            Add to cart
                                        </button>
                                    }
                                </div>
                                
                                {item?.product_category !== 'enquiry' && <p>{rs.currency_symbol}{item?.product_price ? Math.trunc(Number(item?.product_price) * 100) / 100 : null}</p>}
                            </div>
                        </div>
                    )
                })}
            </div>

            {/* Pagination Buttons */}
            {/* <div className="paginationContainer" style={{marginBottom:"40px"}}> 
                <button className="bg-gray-200 px-4 py-2 rounded" onClick={onPrevPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button className="bg-gray-200 px-4 py-2 rounded" onClick={onNextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div> */}
            <div className="flex justify-between items-center py-4" style={{ marginBottom: "40px", display: "flex", justifyContent: "center" }}>
    <button
        style={{
            padding: "10px 13px",
            borderRadius: "6px",
            cursor: "pointer",
            backgroundColor: "#936F5E",
            color: "#fff",
            fontWeight: "600",
            border: "none",
            margin: "0 10px",
            opacity: currentPage === 1 ? 0.5 : 1,
            pointerEvents: currentPage === 1 ? "none" : "auto",
            boxShadow: "none",
        }}
        onClick={onPrevPage}
        disabled={currentPage === 1}
    >
        {/* Previous */}
        <i class="fa-solid fa-angle-left"></i>
    </button>
    
    <div style={{
            fontSize: "15px",
            fontWeight: "600",
            color: "#6e6262",
            display:"flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
        
    }}>
        Page
         <span style={{
        backgroundColor: "#F9E9E2",
    border: "none",
    color: "red",
    padding: "10px 13px",
    borderRadius: "7px",
    textAlign: "center",
    margin:"0 10px"
    }}> {currentPage} </span> of {totalPages}</div>
    
    <button
        style={{
            padding: "10px 13px",
            borderRadius: "6px",
            cursor: "pointer",
            backgroundColor: "#936F5E",
            color: "#fff",
            fontWeight: "600",
            border: "none",
            margin: "0 10px",
            opacity: currentPage === totalPages ? 0.5 : 1,
            pointerEvents: currentPage === totalPages ? "none" : "auto",
            boxShadow: "none",
        }}
        onClick={onNextPage}
        disabled={currentPage === totalPages}
    >
        {/* Next */}
        <i class="fa-solid fa-angle-right"></i>
    </button>
</div>


            <div 
                   
            >
                <Drawer 
                style={{width:"520px"}}
              
                    duration={150}
                    hideScrollbars={true}
                    onClose={closeDrawer}
                    isVisible={isVisible}
                    className="drawer"
                >
                    <BottomDrawer closeDrawer={closeDrawer} onSubmit={handleFormSubmit} sendDatafromParent={sendDatafromParent} selectedItem={selectedItem}/>
                </Drawer>
            </div>
        </div>
    )
}

export default ExtAllCollection