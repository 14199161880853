import React from 'react'
import {OrderCardWrapperContainerSM, TabSocialContainer } from './Theme14ExtStyleComponent'
import { getServicesArray } from "../../../../utils/utility";

export const TabAlternateUrls = (props) => {
  const rs = props.rs;

  return (
    <OrderCardWrapperContainerSM>
      <TabSocialContainer>
       
      <ul className="servesbox">
                    {getServicesArray(rs?.alternatUrlObj?.content).map((val) => {
                      const url = val?.description?.startsWith("http")
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name} className="service-list">
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
        </TabSocialContainer>
    </OrderCardWrapperContainerSM>
  )
}
