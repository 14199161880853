import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { ToastContainer, toast } from "react-toastify";
import ReactDOM from "react-dom";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { businessContext } from "../../context/businessContext";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import Swal from 'sweetalert2';

function InfluencerCoupons() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [isCoupon, setIsCoupon] = useState(false);
  const [couponName, setCouponName] = useState("");
  const [isStatus, setIsStatus] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [discountType, setDiscountType] = useState("");
  
  const [discountValue, setDiscountValue] = useState("");
  const [description, setDescription] = useState("");
  const [activate, setActivate] = useState(false);
  const [limit, setLimit] = useState("");
  const [infDiscount, setInfDiscount] = useState("");
  const createCouponModalRef = useRef(null);
  const tableExtensions = { export: false, print: false };

  const [isUpdate, setIsUpdate] = useState(false);
  const [couponId, setCouponId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchCoupon = async () => {
    try {
      axios
        .get(`${baseUrl}api/v1/Influencercoupon/getAllInfluencerCoupons`, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
        //   console.log(response ,"@@@@@@@@@@CCCCCCCC");
          setData(response?.data?.result);
          setIsLoading(false)

        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          setData([]);
        });
    } catch (err) { }
  };
  useEffect(() => {
    fetchCoupon();
    return () => {
      // Remove the event listener when the component unmounts
      $(createCouponModalRef.current).off("hidden.bs.modal");
    };
  }, []);
  const handleClearState = () => {
    try {
      // e.preventDefault();
      setCouponName("");
      setCouponCode("");
      setDiscountType("");
      setDiscountValue("");
      setDescription("");
      setActivate(false);
      setLimit("");
      setIsUpdate(false);
      setCouponId("");
      setIsCoupon(false);
    } catch (err) {
      console.log(err, "erororo");
    }
  };
  const handleEdit = (el) => {
    try {
      setIsUpdate(true);
      setCouponId(el?.id);
      // console.log(el, "ellll2l");
      setCouponName(el?.name);
      setCouponCode(el?.code);
      // setDiscountType(el?.dis_type);
      setDiscountValue(el?.discount);
      setDescription(el?.description);
      setActivate(el?.is_active == 0 ? false : true);
      setLimit(el?.limit);
      setIsCoupon(true);
      setIsStatus(false);
      $(createCouponModalRef.current).modal("show");
    } catch (err) {
      console.log(err, "Errorro");
    }
  };

const handleDelete = async (d) => {
  try {
    // Display confirmation dialog using Swal.fire
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    // Check if the user confirmed the deletion
    if (result.isConfirmed) {
      // Proceed with the deletion API call
      let { data: res } = await axios.delete(
        `${baseUrl}api/v1/Influencercoupon/InfluencerdeleteCoupon/${d?.id}`,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );

      // Check the response from the API call
      if (res && res?.success) {
        fetchCoupon();
        toast.success(res?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    }
  } catch (err) {
    console.log(err, "error");
  }
};


const GetCouponDiscountDetails = async ()=>{
    axios.get(`${baseUrl}api/v1/influencer/getInfluencerProductTotals/${userData?.id}`)
    .then((response)=>{
        setInfDiscount(response?.data?.data)
    }).catch((err)=>{
        console.log(err,"responseresponse")
    })
}


useEffect(()=>{
    GetCouponDiscountDetails()
},[])


// console.log(userData,"userDatauserData")
// console.log(infDiscount,"infDiscountinfDiscount")

let totaldisamount = Math.floor(((((infDiscount?.total_listing_price) - (infDiscount?.total_base_price)) / (infDiscount?.total_listing_price)) * 100) || 0 ); 
// console.log(infDiscount, totaldisamount,"infDiscountinfDiscount")

totaldisamount = Math.round(totaldisamount * 100) / 100;

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Code",
      selector: (row) => row.code,
      sortable: true,
    },
    {
      name: "Discount (%)",
      selector: (row) => row.discount,
      sortable: true,
    },
    {
      name: "Limit",
      selector: (row) => row.limit,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.is_active == 1? 'Active':"Inactive",
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={`edit-${d.id}`}
            className="first fas fa-pen"
            onClick={() => {
              handleEdit(d);
            }}
          ></i>
        </>,
      ],
    },
  ];

  const customStyles = {
    
    headRow: {
      style: {
        display: "flex",
        alignItems: "stretch",
        width: "100%",
        backgroundColor: "#E9E9E9",
        minHeight: "52px",
        borderRadius: "2px 2px 0px 0px",
        padding: "8px 25px",
        border: "none",
       
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        fontSize:"15px",
      
      },
    },
    rows: {
      style: {
        padding: "20px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px ",
      },
    },
  };



  const tableData = {
    columns,
    data,
  };

  const handleSubmit = async (e) => {
    try {
      if (isUpdate == false && couponId == "") {

        if (couponName == "" || couponName?.trim() == "") {
          toast.error("Coupon name is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        } else if (couponCode == "" || couponCode?.trim() == "") {
          toast.error("Coupon Code is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        } else if (discountValue == "") {
          toast.error("discount value is mandatory", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          return;
        }
      }


      let obj = {
        name: couponName,
        manualCode: couponCode,
        // dis_type: discountType,
        discount: discountValue.toString(),
        description,
        is_active: activate,
        limit: limit || 0,
        influencer_id: userData?.id
      };
      let res;
      // setIsStatus(true);
      if (isUpdate == true && couponId != "") {
        // console.log(isUpdate, "isUpdate", couponId, "isUpdateisUpdate");
        res = await axios.put(
          `${baseUrl}api/v1/Influencercoupon/InfluencereditCoupon/${couponId}`,
          obj,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
      } else {
        // console.log(isUpdate, "isUpdate", couponId, "isUpdateisUpdate111");
        res = await axios.post(`${baseUrl}api/v1/Influencercoupon/createInfluencerCoupon`, obj, {
          headers: {
            _token: userData?.token,
          },
        });
      }

      if (res && res?.data && res?.data?.success) {
        fetchCoupon();
        handleClearState();
        toast.success(res?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        // Close the modal backdrop and reset the modal
        $(createCouponModalRef.current).modal("hide");
        // This line above manually closes the modal and its backdrop
      }
    } catch (err) {
      // alert("errro")

      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <PageName PageName="Manage Coupon" />

      <article>
        <div className="main">
          {/* <li data-bs-toggle="modal" data-bs-target="#createCouponModal">
            <span
              className="app-nav__item btnhead"
              onClick={(e) => setIsCoupon(!isCoupon)}
            >
              + Create Coupon{" "}
            </span>
          </li> */}
          {/* <li data-bs-toggle="modal" data-bs-target="#createCouponModal"> */}
            <div data-bs-toggle="modal" data-bs-target="#createCouponModal">
            <button
            id="Currency_admin_button"
              type="button"
              className="save-cta"
              onClick={(e) => setIsCoupon(!isCoupon)
                
              }
              
            >
              + Create Coupon{" "}
            </button>
            </div>
          {/* </li> */}
          
          {isLoading ? (
            <CircularLoader size={40} />
          ) : (
            <DataTableExtensions 
            {...tableData} 
            filterPlaceholder="Search Coupon"
            customStyles={customStyles}
            {...tableExtensions}
            >
              <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                sortIcon={<SortIcon />}
                defaultSortAsc={true}
                pagination
                highlightOnHover
                dense
                customStyles={customStyles}
              />
            </DataTableExtensions>
          )}
        </div>
        {/* {console.log(isCoupon,"isc333333")} */}
        <div
          className={`modal fade ${isCoupon ? "show" : ""}`}
          id="createCouponModal" // Match this with data-bs-target
          // data-bs-backdrop={isCoupon ? "static" : true}
          // data-bs-keyboard={isCoupon ? "false" : true}
          tabIndex="-1"
          ref={createCouponModalRef}
        >
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title">
                  {" "}
                  {isUpdate && couponId != "" ? "Edit" : "Create New"} Coupon
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => handleClearState(e)}
                ></button>
              </div>
              <div class="modal-body" style={{padding:"0"}}>
                <div className="mb-2" id="Coupons_admin_createform_container">
                  <div>
                    <label className="influencer_coupon_label">Name</label>
                    <input
                    placeholder="Name"
                      type="text"
                      value={couponName}
                      className="form-control"
                      onChange={(e) => setCouponName(e.target.value)}
                    />
                  </div>

                  <div>
                    {/* Code */}
                    <label className="influencer_coupon_label">Code</label>

                    <input
                    placeholder="Code"
                      type="text"
                      className="form-control"
                      value={couponCode}
                      onChange={(e) => setCouponCode(e.target.value)}
                    />
                  </div>
                  {/* <div>
                   
                    <input
                     placeholder={`Discount cannot exceed ${totaldisamount}%`}
                      type="text"
                      className="form-control"
                      value={discountType}
                    //   onChange={(e) => setDiscountType(e.target.value)}
                    onChange={handleAmountChange}
                      max={totaldisamount}
                    />
                  </div> */}
                  {/* <div>
                   
                    <select
                      className="form-control"
                      onChange={(e) => setDiscountType(e.target.value)}
                      value={discountType}
                    >
                      <option value="" disabled> select Discount Type </option>
                      <option value="percentage">Percentage</option>
                      <option value="flat">Flat</option>
                    </select>
                  </div> */}
                   <div>
                   <label className="influencer_coupon_label">Discount Value</label>

                   <input
                      placeholder="Discount value"
                      type="number"
                      className="form-control"
                      onWheel={(e) => e.target.blur()} // Prevent scrolling in the input field
                      onInput={(e) => {
                        // Remove invalid characters but preserve valid digits
                        e.target.value = e.target.value.replace(/[^0-9]/g, ""); // Allow only digits
                      }}
                      onChange={(e) => {
                        let inputValue = e.target.value;

                        // Parse the input value as an integer
                        let parsedValue = parseInt(inputValue, 10);

                        // Validate the parsed value
                        if (isNaN(parsedValue) || parsedValue < 0) {
                          parsedValue = ""; // Reset if invalid or negative
                        } else if (parsedValue > totaldisamount) {
                          parsedValue = totaldisamount; // Restrict to the max value
                        }

                        setDiscountValue(parsedValue); // Update the state with the valid integer value
                      }}
                      value={discountValue}
                      max={totaldisamount}
                      min={0}
                      step="1" // Step set to 1 to avoid decimal inputs
                    />
                  </div> 
                  <div className="influencer_coupon_notedtextlabel">Note :- {`Discount value cannot exceed ${totaldisamount}%`}</div>
                </div>
              </div>
              <div class="modal-body" style={{padding:"0"}}>
                <div className="mb-2">
                  <div>
                    {/*  */}
                    <label className="influencer_coupon_label">Limit </label>

                    <input
                    placeholder="Limit"
                      type="number"
                      className="form-control"
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </div>
                  <div>
                    {/* Description */}
                    <label className="influencer_coupon_label">Description </label>

                    <textarea
                    placeholder="Description"
                      type="text"
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    >
                    </textarea>
                  </div>

                  <div className="mb-2" id="Currency_Activate_Name">
                    {/* Activate */}
                    <label className="influencer_couponActivate_label">Activate </label>

                    <div id="Currency_Radio_wrapper">
                      <label>
                        <input
                          type="radio"
                          checked={activate == true}
                          onChange={() => setActivate(true)}
                        />{" "}
                        Yes
                      </label>
                      <label id="Currency_radio_No">
                        <input
                          type="radio"
                          checked={activate == false}
                          onChange={() => setActivate(false)}
                        />{" "}
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer" id="Coupanadmin_Button_Wrapper">
                <button
                 id="Coupan_edit_Button"
                  type="button"
                  class="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  {isStatus ? (
                    <CircularLoader size={20} />
                  ) : isUpdate && couponId != "" ? (
                    "Update"
                  ) : (
                    "Create"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </>
  );
}

export default InfluencerCoupons;
