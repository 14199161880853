import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
import CircularLoader from "../Components/CircularLoader/CircularLoader";
import { toast } from "react-toastify";
import Icon from "../../Images/Businessbayinactivepng.png";

// Import themes
import Theme1Ext from "./themes/theme1/Theme1Ext";
import Theme2Ext from "./themes/theme2/Theme2Ext";
import Theme3Ext from "./themes/theme3/Theme3Ext";
import Theme4Ext from "./themes/theme4/Theme4Ext";
import Theme5Ext from "./themes/theme5/Theme5Ext";
import Theme6Ext from "./themes/theme6/Theme6Ext";
import Theme7Ext from "./themes/theme7/Theme7Ext";
import Theme8Ext from "./themes/theme8/Theme8Ext";
import Theme9Ext from "./themes/theme9/Theme9Ext";
import Theme10Ext from "./themes/theme10/Theme10Ext";
import Theme11Ext from "./themes/theme11/Theme11Ext";
import Theme12Ext from "./themes/theme12/Theme12Ext";
import Theme14Ext from "./themes/theme14/Theme14Ext";
import Theme15Ext from "./themes/theme15/Theme15Ext";
import Theme16Ext from "./themes/theme16/Theme16Ext";
import Theme17Ext from "./themes/theme17/Theme17Ext";
import Theme18Ext from "./themes/theme18/Theme18Ext";
import Theme20Ext from "./themes/theme20/Theme20Ext";
import Theme22Ext from "./themes/theme22/Theme22Ext";
import Theme27Ext from "./themes/theme27/Theme27Ext";
import { userContext } from "../../context/userContext";
import LandingPage from "../../frontend/LandingPage";
import Theme28Ext from "./themes/theme28/Theme28Ext";
import Theme29Ext from "./themes/theme29/Theme29Ext";
import Theme33Ext from "./themes/theme33/Theme33Ext";
import Theme32Ext from "./themes/theme32/Theme32Ext";
import Theme31Ext from "./themes/theme31/Theme31Ext";
import Theme30Ext from "./themes/theme30/Theme30Ext";
import Theme34Ext from "./themes/theme34/Theme34Ext";
import Theme35Ext from "./themes/theme35/Theme35Ext";
import Theme36Ext from "./themes/theme36/Theme36Ext";
import Theme37Ext from "./themes/theme37/Theme37Ext";
import Theme38Ext from "./themes/theme38/Theme38Ext";
import Theme39Ext from "./themes/theme39/Theme39Ext";
import Theme40Ext from "./themes/theme40/Theme40Ext";
import Theme41Ext from "./themes/theme41/Theme41Ext";
import Theme42Ext from "./themes/theme42/Theme42Ext";
import Theme43Ext from "./themes/theme43/Theme43Ext";
import Theme44Ext from "./themes/theme44/Theme44Ext";
import Theme45Ext from "./themes/theme45/Theme45Ext";
import Theme46Ext from "./themes/theme46/Theme46Ext";
import Theme47Ext from "./themes/theme47/Theme47Ext";
import Theme48Ext from "./themes/theme48/Theme48Ext";
import Theme49Ext from "./themes/theme49/Theme49Ext";
import Theme50Ext from "./themes/theme50/Theme50Ext";
import Theme51Ext from "./themes/theme51/Theme51Ext";
import Theme52Ext from "./themes/theme52/Theme52Ext";
import Theme53Ext from "./themes/theme53/Theme53Ext";
import Theme54Ext from "./themes/theme54/Theme54Ext";
import Theme55Ext from "./themes/theme55/Theme55Ext";
import Theme56Ext from "./themes/theme56/Theme56Ext";
import { Helmet } from "react-helmet";


const Template2 = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = props?.Slug || location?.pathname?.split("/")[1];
  const baseUrl = environmentVariables?.apiUrl;
  const frntUrl = environmentVariables?.frntUrl;
  
  const { userData } = useContext(userContext);
  const [themeName, setThemeName] = useState(null);
  const [businessData, setBusinessData] = useState(null);
  const [currencySymbol,setCurrencySymbol]=useState('₹');
  const [isLoading, setIsLoading] = useState(true);
  const [businessId, setBusinessId] = useState(null);
    const [metaTag, setMetaTag] = useState("");
  
  const countViewerBySlug = async () => {
    try {
      const data = { business_id: businessId, slug: params };
      const response = await axios.put(
        `${baseUrl}api/v1/business/countViewerBySlug`,
        data
      );
      if (response.status !== 200) {
        console.error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
    }
  };
  const checkSubDomain = (slugData)=>{
    let reservedSubdomain = ['www', 'uat', 'app', 'test', 'dashboard', 'appoinments','super_dashboard','businesslist','Roles','user','requestCard_admin','PlansSuperAdmin','Coupons_admin','Events_admin','Plan_enquiry','bulk_upload','userUploadList','Setting_admin','plans','contact','MinimarketEnquiry','send-notification','restaurantorders','profile','create-card'];
    return reservedSubdomain.includes(slugData);
  }
  const getPlanName = async () => {
    setIsLoading(true);
    try {    
      if(!checkSubDomain(params)){
        const res = await axios.get(
          `${baseUrl}api/v1/business/fetchBusinessBySlug`,
          {
            params: { slug: params },
          }
        );
        if(!res?.data?.success){
           if(res?.data?.planExpired==true){
            setThemeName("planexpired");
            return;
          }
          if(!!userData?.id){
            navigate('/dashboard');
          }
          else{
            setThemeName("redirecting");
            setTimeout(()=>{
              window.location.href = frntUrl;
            },1500)
          }
        }else{
          setBusinessData(res?.data?.data);
          setCurrencySymbol(res?.data?.currencySymbol);
          if (res?.data?.data?.status === "active") {
            setThemeName(res?.data?.data?.theme_color);
          } else {
            setThemeName("inactive");
          }
          setIsLoading(false);
          setBusinessId(res?.data?.data?.id);
        }
      }else{
        if(!!userData?.id){
          navigate('/dashboard');
        }else{
          setThemeName("home"); 
          navigate('/');
        }
      }
    } catch (err) {
      setIsLoading(false);
      console.error("Error fetching business data:", err?.response);
      if (err?.response?.status === 400) {
        setThemeName("inactive");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 6000);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlanName();
  }, []);
  
  const getUpdatedKeyWords = (keywords)=>{
    return keywords.map((item) => item.name).join(", ");
  }

 useEffect(() => {   
   if(businessData?.metaKeywordObj && businessData.metaKeywordObj[0]?.keywords){
    const Metakeywords = JSON.parse(businessData?.metaKeywordObj[0]?.keywords);
    const updateKeywords = typeof Metakeywords == 'string'? getUpdatedKeyWords(JSON.parse(Metakeywords)): getUpdatedKeyWords(Metakeywords); // Create a string with comma-separated names
    // console.log(updateKeywords, "Meta keywords are being set correctly");
    setMetaTag(updateKeywords); 
  }
  }, [businessData]);


  useEffect(() => {
    if (businessId) countViewerBySlug();
  }, [businessId]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <CircularLoader />
      </div>
    );
  }
  const handleReload = () => {
    window.location.reload();
  };

  const getMetaKeywords = () => {
    return(
      <Helmet>
        <title> {businessData?.metaTitle}</title>
        <meta charSet="utf-8" />
        <link rel="canonical" href={businessData?.canonicalTag} />
        <meta name="description" content={businessData?.metaDescription} />
        <meta name="keywords" content={metaTag} />
      </Helmet>
    )
  }
  const renderTheme = () => { 
    if (!!themeName && themeName != 'home') {
      switch (themeName) {
        case "theme56":
          return <>{getMetaKeywords()} <Theme56Ext rs={businessData} id={businessId} /> </>;
        case "theme55":
          return <>{getMetaKeywords()} <Theme55Ext rs={businessData} id={businessId} /> </>;
        case "theme54":
          return <> {getMetaKeywords()} <Theme54Ext rs={businessData} id={businessId} /></>;
        case "theme53":
          return <> {getMetaKeywords()} <Theme53Ext rs={businessData} id={businessId} /></>;
        case "theme52":
          return <> {getMetaKeywords()} <Theme52Ext rs={businessData} id={businessId} /></>;
        case "theme51":
          return <> {getMetaKeywords()} <Theme51Ext rs={businessData} id={businessId} /></>;
        case "theme50":
          return <> {getMetaKeywords()} <Theme50Ext rs={businessData} id={businessId} /></>;
        case "theme49":
          return <> {getMetaKeywords()} <Theme49Ext rs={businessData} id={businessId} /></>;
        case "theme48":
          return <> {getMetaKeywords()} <Theme48Ext rs={businessData} id={businessId} /></>;
        case "theme47":
          return <> {getMetaKeywords()} <Theme47Ext rs={businessData} id={businessId} /></>;
        case "theme46":
          return <> {getMetaKeywords()} <Theme46Ext rs={businessData} id={businessId} /></>;
        case "theme45":
          return <> {getMetaKeywords()} <Theme45Ext rs={businessData} id={businessId} /></>;
        case "theme44":
          return <> {getMetaKeywords()} <Theme44Ext rs={businessData} id={businessId} /></>;
        case "theme43":
          return <> {getMetaKeywords()} <Theme43Ext rs={businessData} id={businessId} /></>;
        case "theme42":
          return <> {getMetaKeywords()} <Theme42Ext rs={businessData} id={businessId} /></>;
        case "theme41":
          return <> {getMetaKeywords()} <Theme41Ext rs={businessData} id={businessId} /></>;
        case "theme40":
          return <> {getMetaKeywords()} <Theme40Ext rs={businessData} id={businessId} /></>;
        case "theme39":
          return <> {getMetaKeywords()} <Theme39Ext rs={businessData} id={businessId} /></>;
        case "theme38":
          return <> {getMetaKeywords()} <Theme38Ext rs={businessData} id={businessId} /></>;
        case "theme37":
          return <> {getMetaKeywords()} <Theme37Ext rs={businessData} id={businessId} /></>;
        case "theme36":
          return <> {getMetaKeywords()} <Theme36Ext rs={businessData} id={businessId} /></>;
        case "theme35":
          return <> {getMetaKeywords()} <Theme35Ext rs={businessData} id={businessId} /></>;
        case "theme34":
          return <> {getMetaKeywords()} <Theme34Ext rs={businessData} id={businessId} /></>;
        case "theme33":
          return <> {getMetaKeywords()} <Theme33Ext rs={businessData} id={businessId} /></>;
        case "theme32":
          return <> {getMetaKeywords()} <Theme32Ext rs={businessData} id={businessId} /></>;
        case "theme31":
          return <> {getMetaKeywords()} <Theme31Ext rs={businessData} id={businessId} /></>;
        case "theme30":
          return <> {getMetaKeywords()} <Theme30Ext rs={businessData} id={businessId} /></>;
        case "theme29":
          return <> {getMetaKeywords()} <Theme29Ext rs={businessData} id={businessId} /></>;
        case "theme28":
          return <> {getMetaKeywords()} <Theme28Ext rs={businessData} id={businessId} /></>;
        case "theme27":
          return <> {getMetaKeywords()} <Theme27Ext rs={businessData} id={businessId} /></>;
        case "theme22":
          return <> {getMetaKeywords()} <Theme22Ext rs={businessData} id={businessId} /></>;
        case "theme20":
          return <> {getMetaKeywords()} <Theme20Ext rs={businessData} id={businessId} currencySymbol={currencySymbol} /></>;
        case "theme18":
          return <> {getMetaKeywords()} <Theme18Ext rs={businessData} id={businessId} /></>;
        case "theme17":
          return <> {getMetaKeywords()} <Theme17Ext rs={businessData} id={businessId} /></>;
        case "theme16":
          return <> {getMetaKeywords()} <Theme16Ext rs={businessData} id={businessId} /></>;
        case "theme14":
          return <> {getMetaKeywords()} <Theme14Ext rs={businessData} id={businessId} /></>;
        case "theme12":
          return <> {getMetaKeywords()} <Theme12Ext rs={businessData} id={businessId} /></>;
        case "theme15":
          return <> {getMetaKeywords()} <Theme15Ext rs={businessData} id={businessId} /></>;
        case "theme11":
          return <> {getMetaKeywords()} <Theme11Ext rs={businessData} id={businessId} /></>;
        case "theme10":
          return <> {getMetaKeywords()} <Theme10Ext rs={businessData} id={businessId} /></>;
        case "theme9":
          return <> {getMetaKeywords()} <Theme9Ext rs={businessData} id={businessId} /></>;
        case "theme8":
          return <> {getMetaKeywords()} <Theme8Ext rs={businessData} id={businessId} /></>;
        case "theme7":
          return <> {getMetaKeywords()} <Theme7Ext rs={businessData} id={businessId} /></>;
        case "theme6":
          return <>{getMetaKeywords()} <Theme1Ext rs={businessData} id={businessId} /> </>;
        case "theme5":
          return <>{getMetaKeywords()} <Theme2Ext rs={businessData} id={businessId} /> </>;
        case "theme3":
          return <>{getMetaKeywords()}<Theme3Ext rs={businessData} id={businessId} /></>;
        case "theme4":
          return <>{getMetaKeywords()}<Theme4Ext rs={businessData} id={businessId} /></>;
        case "theme2":
          return <>{getMetaKeywords()}<Theme5Ext rs={businessData} id={businessId} /></>;
        case "theme1":
          return <>{getMetaKeywords()} <Theme6Ext rs={businessData} id={businessId} /> </>;
        case "inactive":
          return (
            <div>
              <div style={{
                display:"flex",
                flexDirection:"column",
                justifyContent: "center",
                alignItems:"center",
                height:"100vh"
              }}>
                <div>
                  <img style={{ width: "200px", height: "auto" }} src={Icon} />
                </div>
                <div>
                  <div style={{fontFamily:"Jost, sans-serif", fontWeight:"700", color:"#111111", fontSize:"26px"}}>Business is not active</div>
                </div>
                <div style={{display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                  <div style={{fontFamily:"Jost, sans-serif", fontWeight:"400", color:"#666666", fontSize:"16px", width:"80%"}}>
                    Kindly reach out to us at <span style={{color:"#0059A7", fontWeight:"500"}}>info@bastionex.net</span>  when your
                    business shows as inactive.
                  </div>
                </div>
              </div>
            </div>
          );
        case "Bad Request":
          return (
            <div
              className="bad-request"
              style={{
                textAlign: "center",
                marginBottom: "80px",
                backgroundColor: "white",
                padding: "20px",
              }}
            >
              <div>
                <h1>Bad Request</h1>
              </div>
            </div>
          );
          case "redirecting":
            return (
              <div
                className="bad-request"
                style={{
                  textAlign: "center",
                  marginBottom: "80px",
                  backgroundColor: "white",
                  padding: "20px",
                }}
              >
                <div>
                  <h3>Redirecting to home page...</h3>
                </div>
              </div>
            );

          case "planexpired":
            return (
              <div>
                <div style={{
                  display:"flex",
                  flexDirection:"column",
                  justifyContent: "center",
                  alignItems:"center",
                  height:"100vh"
                }}>
                  <div>
                    <img style={{ width: "200px", height: "auto" }} src={Icon} />
                  </div>
                  {/* <div>
                    <div style={{fontFamily:"Jost, sans-serif", fontWeight:"700", color:"#111111", fontSize:"26px"}}>Business Plan Expired</div>
                  </div> */}
                  <div style={{display:"flex", justifyContent:"center", alignItems:"center", textAlign:"center"}}>
                    <div style={{fontFamily:"Jost, sans-serif", fontWeight:"400", color:"#666666", fontSize:"16px", width:"80%"}}>
                      Current business plan has been expired.
                    </div>
                  </div>
                </div>
              </div>
            );
        default:
          return <div>Unknown theme</div>;
      }
    } else if(themeName == 'home'){
      return <LandingPage />
    } else {
      return (
        <div
          className="bad-request"
          style={{
            textAlign: "center",
            marginBottom: "80px",
            backgroundColor: "white",
            padding: "20px",
          }}
        >
          <div>
            <h1>Your internet connection is weak.</h1>
            <a onClick={handleReload}>Click here to reload.</a>
          </div>
        </div>
      );
    }
  };
  return renderTheme(); 
};

export default Template2;