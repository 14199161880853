import React, { useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useEffect } from "react";
import "./Theme27.css";
import axios from "axios";
import bannerImage from "../../../../Images/theme-27-banner.png";
import topright from "../../../../Images/theme18-top-right.png";
import logoImage from "../../../../Images/theme-27-logo.png";
import theme6strip from "../../../../Images/theme6strip.png";
import TestimonialCarousel from "./TestimonialCarousel";
import GalleryCarousel from "./GalleryCarousel";
import CalanderIconn from "../../../../Images/theme-27-calender.png";
import { getServicesArray } from "../../../../utils/utility";

const Theme27 = (props) => {
  const { userData } = useContext(userContext);
  const [selectedDate, setSelectedDate] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [appointmentData, setAppointmentData] = useState([]);
  const touchStartX = useRef(0);
  const calendarInputRef = useRef(null);
  const handleChangeAppointmentDate = (dateVal) => {
    setSelectedDate(dateVal);

    const dateObject = new Date(dateVal);

    const dayIndex = dateObject.getDay();

    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    if (props?.appointmentArray && props?.appointmentArray?.length != 0) {
      setAppointmentData(
        props?.appointmentArray[`${daysOfWeek[dayIndex].toLowerCase()}`]
      );
    } else {
      // const parsedContent = JSON.parse(
      //   props?.businessObj?.appointmentObj?.content
      // );
      // setAppointmentData(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(props?.businessObj?.appointmentObj?.content)
        );
        setAppointmentData(
          parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
        );
      } catch (err) {
        console.log(err);
      }
    }
  };
  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };

  const handleTouchMove = (e) => {
    const touchEndX = e.touches[0].clientX;
    const deltaX = touchEndX - touchStartX.current;

    if (Math.abs(deltaX) > 50) {
      // You can adjust this threshold to control how much swipe is needed to trigger the slide
      setCurrentIndex((prev) =>
        deltaX > 0
          ? Math.max(prev - 1, 0)
          : Math.min(prev + 1, testimonials.length - 1)
      );
    }
  };

  const handleTouchEnd = () => {
    touchStartX.current = 0;
  };
  // console.log(props.orderOfBlocks, "appointment inside iframe");

  useEffect(() => {
    getBlockOrder();
  }, [props?.businessObj, props.businessId, props.orderOfBlocks]);
  const getBrandNameForSocial = (brand) => {
    // console.log(brand);
    if (brand == "gmail") {
      return "google";
    } else return `${brand}`;
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    if (props.orderOfBlocks) {
      let orders = {};
      props.orderOfBlocks.map((val) => {
        if (val.content == "Description") {
          orders["description"] = val?.position;
        } else if (val.content == "Contact Info") {
          orders["contact_info"] = val?.position;
        } else if (val.content == "Appointment") {
          orders["appointment"] = val?.position;
        } else if (val.content == "Service") {
          orders["service"] = val?.position;
        } else if (val.content == "Gallery") {
          orders["gallery"] = val.position;
        } else if (val.content == "Contact Form") {
          orders["contact_form"] = val.position;
        } else if (val.content == "Testimonials") {
          orders["testimonials"] = val.position;
        } else if (val.content == "Social") {
          orders["social"] = val.position;
        } else if (val.content == "Business Hour") {
          orders["business_hour"] = val.position;
        } else if (val.content == "Google Review") {
          orders["google_review"] = val.position;
        }
      });
      props.setOrder(orders);
    } else {
      try {
        let id = props?.businessObj?.id || props.businessId;
        if (props?.businessObj?.id) {
          let { data: get } = await axios.get(
            `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
              // withCredentials: true,
            }
          );
          // console.log(get?.data?.order, "order data");

          props.setOrder(get?.data?.order);
        }

        // console.log(get.data.order,"Getetgwef")
      } catch (err) {
        console.log(err, "error in template");
      }
    }
    // };
  };
  const getJSXElement = (elemName) => {
    if (elemName == "appointment") {
      return (
        props?.onState?.appointment && (
          // <article class="contact sec">
          //   <h3 class="sectitle">Appoinment</h3>
          //   <select class="form-select" aria-label="Default select example">
          //     <option selected>Select time</option>
          //     {props?.appointmentArray?.map((val) => (
          //       <>
          //         <option value="1">
          //           {props.changeTimeFormat(val?.startDate)} -{" "}
          //           {props.changeTimeFormat(val?.endDate)}{" "}
          //         </option>
          //       </>
          //     ))}
          //   </select>
          // </article>
          <article className="makeappitmneets sec">
            <h3 class="sectitle">Make Appointment</h3>

            <div className="contact-form">
             <div className="appointmentdiv">
             <div class="col-12 mb-3" style={{ position: "relative"}}>
                <input
                  ref={calendarInputRef}
                  type="date"
                  class="form-control clear_string appoint"
                  placeholder="Date"
                  name="currentDate"
                  value={selectedDate}
                  onChange={(e) => handleChangeAppointmentDate(e.target.value)}
                />
                <div className="calanderIcon" onClick={openCalendar}>
                  <img src={CalanderIconn} alt="Calendar Icon" />
                </div>
              </div>
              <div class="col-12 mb-3">
                <select
                  class="form-select appoint"
                  aria-label="Default select example"
                  // onChange={(e) => setSelectedTime(e.target.value)}
                >
                  <option value="Select time">Select time</option>
                  {appointmentData?.map((val) => (
                    <option
                      key={val?.startDate}
                      value={`${val?.startDate} - ${val?.endDate}`}
                    >
                      {`${val?.startDate} - ${val?.endDate}`}
                    </option>
                  ))}
                </select>
              </div>
             </div>
              <div class="col-12 mb-3">
                <button className="btn btnmakeappitmnets">
                  Make An Appointment
                </button>
              </div>
            </div>
          </article>
        )
      );
    }
    if (elemName == "business_hour") {
      return (''
        // props?.onState?.businessHours && (
        //   <article className="businessHours sec">
        //     <h3 class="sectitle">Business Hours</h3>

        //     <ul class="timelist">
        //       {props?.businessHourObject?.isMonday &&
        //         props?.businessHourObject?.modayStartDate &&
        //         props?.businessHourObject?.modayEndDate && (
        //           <li>
        //             <div className="day">Mon :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.modayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.modayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //       {props?.businessHourObject?.isTuesday &&
        //         props?.businessHourObject?.tuesdayStartDate &&
        //         props?.businessHourObject?.tuesdayEndDate && (
        //           <li>
        //             <div className="day">Tue :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.tuesdayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.tuesdayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //       {props?.businessHourObject?.isWednesDay &&
        //         props?.businessHourObject?.wednesdayStartDate &&
        //         props?.businessHourObject?.wednesdayEndDate && (
        //           <li>
        //             <div className="day">Wed :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.wednesdayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.wednesdayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //       {props?.businessHourObject?.isthrusdayDay &&
        //         props?.businessHourObject?.thrusdayStartDate &&
        //         props?.businessHourObject?.thrusdayEndDate && (
        //           <li>
        //             <div className="day">Thu :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.thrusdayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.thrusdayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //       {props?.businessHourObject?.isfridayDay &&
        //         props?.businessHourObject?.fridayStartDate &&
        //         props?.businessHourObject?.fridayEndDate && (
        //           <li>
        //             <div className="day">Fri :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.fridayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.fridayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //       {props?.businessHourObject?.issaturdayDay &&
        //         props?.businessHourObject?.SaturdayStartDate &&
        //         props?.businessHourObject?.SaturdayEndDate && (
        //           <li>
        //             <div className="day">Sat :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.SaturdayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.SaturdayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //       {props?.businessHourObject?.issundayDay &&
        //         props?.businessHourObject?.SundayStartDate &&
        //         props?.businessHourObject?.SundayEndDate && (
        //           <li>
        //             <div className="day">Sun :</div>
        //             <div className="time">
        //               {" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.SundayStartDate
        //                 )}
        //               </span>{" "}
        //               -{" "}
        //               <span>
        //                 {props.changeTimeFormat(
        //                   props?.businessHourObject?.SundayEndDate
        //                 )}
        //               </span>
        //             </div>
        //           </li>
        //         )}
        //     </ul>
        //   </article>
        // )
      );
    }
    if (elemName == "social") {
      return (
        props?.onState?.socials && (
          <article className="social-links-sec sec">
            {/* <h3 class="sectitle">Social Media</h3> */}
            <ul class="socil-link socil-link-inner">
              {props?.socialMediaData?.map((val) => (
                <li>
                  <a>
                    {/* <i
                      class={`fa-brands fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i> */}
                    <i
                      className={`fa-${
                        val?.nameOfSocial.toLowerCase() === "star"
                          ? "solid"
                          : "brands"
                      } fa-${getBrandNameForSocial(
                        val?.nameOfSocial.toLowerCase()
                      )}`}
                    ></i>
                  </a>
                </li>
              ))}
            </ul>
          </article>
        )
      );
    } else if (elemName == "description") {
      return (
        <article className="abouttemplate sec">
          <h3 class="sectitle">About us</h3>

          <p className="para">
            {props.description ||
              ` Experienced and energetic marketing professional with over 8 years of experience in effectively managing marketing projects from conception to completion. Adept in using digital platforms to increase sales and brand awareness. Experienced in conducting and analyzing marketing campaigns, resulting in an increase in partner relations for the company..`}
          </p>
        </article>
      );
    } else if (elemName == "contact_info") {
      return (
        props?.onState?.contactInfo && (
          <article class="contact sec">
            <h3 class="sectitle">Contact info </h3>
            <ul class="conlink">
              <li>
                <a>
                  <i class="fa-solid fa-phone"></i>
                  <span>{props?.contactInfo?.contactInfoPhone}</span>
                </a>
              </li>

              <li>
                <a>
                  <i class="fa-solid fa-envelope"></i>
                  <span>{props?.contactInfo?.contactInfoEmail}</span>
                </a>
              </li>
              {/* <li>
                <a href="">
                  <i class="fa-solid fa-globe"></i>
                  <span>
                    {props?.contactInfo?.contactInfoWebUrl ||
                      `www.metaspacechain.com.com`}
                  </span>
                </a>
              </li> */}
              <li>
                <a>
                  <i class="fa-solid fa-location-dot"></i>
                  <span className="breakText">
                    {props?.contactInfo?.contactInfoAddress}
                  </span>
                </a>
              </li>
            </ul>
          </article>
        )
      );
    } else if (elemName == "custom_html") {
      return <></>;
    }
    // else if (elemName == "google_review") {
    //   return (
    //     props?.onState?.googlereview == true && (
    //       <article class="contact sec">
    //         <h3 class="sectitle">Google review</h3>
    //         <ul class="conlink">
    //           <li>
    //             <p style={{ wordBreak: "break-all", maxHeight: "77px", overflow: "hidden", fontSize: "16px", color: "#fff" }}>
    //               {props?.googlereview !== undefined && props?.googlereview}
    //             </p>
    //           </li>
    //         </ul>
    //       </article>
    //     )
    //   );
    // }
    else if (elemName == "contact_form") {
      return (
        props?.onState?.contactForm && (
          <article className="conatctform sec">
            <h3 class="sectitle">Request a consultation</h3>

            <div class="contact-form">
              <form>
                <div class="col-12 mb-3">
                {/* <label className="labelTxt">Name</label> */}
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Name"
                    name="last_name"
                    // value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                {/* <label className="labelTxt">Email</label> */}
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Email"
                    name="email"
                    // value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                {/* <label className="labelTxt">Mobile</label> */}
                  <input
                    type="text"
                    class="form-control clear_string"
                    maxlength="10"
                    minlength="10"
                    placeholder="Phone"
                    name="mobile_number"
                    // value=""
                    required=""
                  />
                </div>
                <div class="col-12 mb-3">
                {/* <label className="labelTxt">Messege</label> */}
                  <textarea
                    class="form-control clear_string"
                    rows="3"
                    placeholder="Messege"
                    required=""
                    name="message"
                  ></textarea>
                </div>

                <div class="col-12 mb-3">
                  <button type="button" class="btn btn-submitform">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </article>
        )
      );
    } else if (elemName == "gallery") {
      return (
        props?.onState?.gallery && (''
          // <article class="gallery sec sec-common">
          //   <h3 class="sectitle">gallery </h3>
          //   {props?.galleryData && (
          //     <div className="gallerysec">
          //       <GalleryCarousel slides={props?.galleryData} />
             
          //     </div>
          //   )}
          //   {props.existingGalleryImages ? (
          //     <GalleryCarousel slides={props?.existingGalleryImages} />
          //   ) : (
          //     props?.gallaryObject?.gallaryArray && (
          //       <GalleryCarousel slides={props?.gallaryObject?.gallaryArray} />
          //     )
          //   )}
          //   {props.galleryView && (
          //     <GalleryCarousel slides={props?.galleryView} staticImage={true} />
          //   )}
          //   {props?.gallaryObject?.gallary_video && (
          //     <video className="mx-auto d-block img-fluid" controls>
          //       <source
          //         src={
          //           props?.gallaryObject?.gallary_video &&
          //           `${environmentVariables?.apiUrl}uploads/${props?.gallaryObject?.gallary_video}`
          //         }
          //         type="video/mp4"
          //       />
          //       Your browser does not support the video tag.
          //     </video>
          //   )}
          // </article>
        )
      );
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName == "service") {
      return (
        props?.onState?.services && (
          <article className="servicesName sec">
            <h3 class="sectitle">Services </h3>

            <div className="mainservicebox">
              {/* <ul className="servesbox">
                {props?.servicesArray?.map((val) => (
                  <li> {val?.name}</li>
                ))}
              </ul> */}
              {props?.servicesArray && (
                <ul className="servesbox">
                  {getServicesArray(props?.servicesArray).map(
                    (val) =>
                      val?.name != null && <li key={val?.name}> {val?.name}</li>
                  )}
                </ul>
              )}
            </div>
          </article>
        )
      );
    } else if (elemName == "testimonials") {
      return (
        props?.onState?.testimonials && (
          <article class="testimoniyalbox sec">
            <h3 class="sectitle testimonial">Testimonials </h3>

            <div className="testimonials-wrapper">
              {/* <div className="carousel-container">
                <button
                  onClick={() =>
                    currentIndex == 0
                      ? setCurrentIndex(props.testimonialArray.length - 1)
                      : setCurrentIndex((prev) => prev - 1)
                  }
                  className="carousel-btn"
                >
                  <i class="fa fa-angle-left"></i>
                </button>
                <button
                  onClick={() => {
                    currentIndex == props.testimonialArray.length - 1
                      ? setCurrentIndex(0)
                      : setCurrentIndex((prev) => prev + 1);
                  }}
                  className="carousel-btn"
                >
                  <i class="fa fa-angle-right"></i>
                </button>
              </div> */}
              <TestimonialCarousel
                testimonials={props?.testimonialArray}
                currentElem={currentIndex}
                handleTouchStart={handleTouchStart}
                handleTouchMove={handleTouchMove}
                handleTouchEnd={handleTouchEnd}
              />
            </div>
          </article>
        )
      );
    }else if (elemName == "alternateUrls") {
          return (
           props?.onState?.alternateurl && (
            <>
            <article className="servicesName sec">
              <h3 className="sectitle service">Useful Links</h3>
              <div className="mainservicebox">
                {props?.alternateUrlArray && (
                  <ul className="servesbox">
                    {getServicesArray(props?.alternateUrlArray).map((val) => {
                      const url = val?.description?.startsWith("http")
                        ? val?.description
                        : `https://${val?.description}`;
                      return (
                        val?.name &&
                        val?.description && (
                          <li key={val?.name}>
                            <a href={url} target="_blank" rel="noopener noreferrer">
                              {val?.name}
                            </a>
                          </li>
                        )
                      );
                    })}
                  </ul>
                )}
              </div>
            </article>
            </>
           )
          )
        }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in props.order) {
      BlocksArray[props.order[key]] = getJSXElement(key);
    }
    return BlocksArray;
  };

  return (
    <div>
      <div class="main-temaplt" id="temp27">
        <div className="container">
          <div className="row justify-content-center">
            <div  style={{ width: "420px" }}  className="col-12 wtemplate">
              <article className="imgsechere sec">
                {/* <div className="imgsechere-top">
                  <div className="imgsechere-top-left">  9:41  </div>
                  <div className="imgsechere-top-right"> <img src={topright} alt="top right" />  </div>
                </div> */}
                <div className="imgsechere-main-banner">
                  <img
                    src={
                      props?.bannerImageUrl
                        ? props.getImage(props.bannerImageUrl)
                        : bannerImage
                    }
                    className="mx-auto d-block img-fluid coverimghere "
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                       
                </div>
                <div>
                  <img
                    src={
                      props?.logoImageUrl
                        ? props.getImage(props?.logoImageUrl)
                        : logoImage
                    }
                    className="mx-auto d-block img-fluid logo-profile"
                  />
                </div>
              
              </article>
              <article className="personalinfo ">
                  <h3 className="username">{props.subTitle || "Name"}</h3>
                  <p className="titleuser">
                    {props.designation || "Designation"}
                  </p>
                  {/* <p className="companyName">
                  {props.subTitle || "Company Name"}
                </p> */}
                </article>

              {getBlocksOrder().map((val) => (val == undefined ? <></> : val))}

              {/* <div class="fixedd">
                <article class="fixedfooter">
                  <div class="footerleft">
                    <div
                      class="qrcodeshaere"
                      data-bs-toggle="modal"
                      data-bs-target="#appotiments"
                    >
                      <i class="fa-solid fa-qrcode"></i>
                    </div>
                  </div>
                  <div class="addtocinatct">+Add To Contact</div>
                </article>
              </div> */}
              <div
                class="modal fade"
                id="appotiments"
                tabindex="-1"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="exampleModalLabel">
                        Modal title
                      </h5>
                      <button
                        type="button"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <article class="personalinfo modal-info ">
                        <canvas
                          height="160"
                          width="160"
                          style={{ height: "128px", width: "128px" }}
                        ></canvas>
                        <h3 class="username">Subhan</h3>
                        <p class="titleuser">Software Developer</p>
                        <p class="companeyname">Xenophobe Tech</p>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        //   ref={createUserRef}
        id="appotimnets"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Book Appointment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form className="row">
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Full Name"
                    //   value={appointmentName}
                    //   onChange={(e) =>
                    //     setAppointmentName(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    eror
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Email"
                    //   value={appointmentEmail}
                    //   onChange={(e) =>
                    //     setAppointmentEmail(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",
                      bottom: "70px",
                    }}
                  >
                    'errormsg'
                  </div>
                </div>
                <div class="col-12 mb-3">
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Mobile No."
                    //   value={appointmentContactNumber}
                    //   onChange={(e) =>
                    //     setAppointmentContactNumber(e.target.value)
                    //   }
                  />
                  <div
                    style={{
                      fontSize: "14px",
                      color: "red",

                      bottom: "70px",
                    }}
                  >
                    'errormsgni'
                  </div>
                </div>
                <div class="mb-2 mt-4 col-md-12">
                  <button
                    //   onClick={handleSubmits}
                    class="btn btn-primary me-3"
                    type="button"
                  >
                    <i class="bi bi-check-circle-fill ms-2 "></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* <ToastContainer /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme27;
