import React, { useEffect, useState } from 'react';
import { OrderHistoryPage } from './OrderHistoryPage';
import Swal from 'sweetalert2';

export const OrderDeatilshhistory = ({ data, active, toggleProduct, currencySymbol, updatedState, setUpdatedState, setCurrentUser, showOrderList }) => {

  const [closing, setClosing] = useState(false);
  const [isOrderHistory, setIsOrderHistory] = useState(false)
  let customer_data = localStorage.getItem("Customer");
  let cusData = (!!customer_data)?JSON.parse(customer_data):"";

  const handleBackToOrderDetails = () => {
    setIsOrderHistory(!isOrderHistory)
  }

  const [isRightArrow, setIsRightArrow] = useState(false);
  const handleClickOnArrow = () => {
    setIsRightArrow(!isRightArrow)
    setIsOrderHistory(true)
  }

  // const link = `${environmentVariables.apiUrl}uploads/gallery/${item.image}`;
  const handleClose = () => {
    setClosing(true);
    setTimeout(() => {
      toggleProduct();
      setClosing(false);
    }, 600);
  };

  const logout = async () => {
    try {
      // Display confirmation dialog using Swal.fire
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You wont to logout`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout!",
      });

      // Check if the user confirmed the deletion
      if (result.isConfirmed) {
        localStorage.removeItem("Customer");
        setUpdatedState(!updatedState);
        setCurrentUser();
        handleClose()
      }
    } catch (err) {
      console.log(err, "error");
    }
  };
  useEffect(()=>{
    if(!!showOrderList){
      setClosing(true);
      setIsRightArrow(!isRightArrow)
      setIsOrderHistory(true)
    }
  },[showOrderList])

  console.log("active===>>>", active);
  
  return (
    <section
      style={{ bottom: closing ? "-100%" : active ? "0" : "-100%" }}
      className={`${active ? "product-popup" : ""}`} id='theme20-orderDeatilshhistory'>
      <div className='product-popup-inner'>
        <article className="products sec">
          {/* <h3 className="sectitle">Order</h3> */}
          <p className="cross">
            {active && (
              <i onClick={handleClose} className="fa-solid fa-x"></i>
            )}
          </p>

          <div className='ordr-detail-page'>
            <div>
              <h3 className='ordrdtlname'>{cusData?.name}</h3>
              {/* <p className='ordrdtlemail' style={{fontSize:"14px", color:"#c1bfbf", paddingTop:"5px"}}>{cusData?.email}</p> */}
              <p className='ordrdtlemail' style={{fontSize:"14px", paddingTop:"5px"}}>{cusData?.email}</p>
            </div>


            <div className='order-detail-optionContainer'>
              <div className='order-detail-options' onClick={handleClickOnArrow}>
                <h3 className='order-detail-options-h3' style={{margin:"0 auto"}}>Order History</h3>
                {/* <div className='option-arrow'><i className={`fa-light fa-angle-right ${isRightArrow ? 'rotateRightArrow' : ''}`}></i></div> */}
              </div>
              {/* <div className='order-detail-options'>
                <h3 className='order-detail-options-h3'>Settings</h3>
                <div className='option-arrow'><i className="fa-light fa-angle-right"></i></div>
              </div> */}
              <div className='order-detail-options' style={{backgroundColor:"transparent", border:"none"}} onClick={() => logout()}>
                <h3 className='order-detail-options-h3' style={{margin:"0 auto", color:"#936F5E"}}>Logout</h3>
              </div>
            </div>
          </div>


        </article>

      </div>



      {/* {
      isOrderHistory && <OrderHistoryPage onBack={handleBackToOrderDetails}></OrderHistoryPage>
    }
      */}
      {
        isOrderHistory && (
          <OrderHistoryPage
            onBack={handleBackToOrderDetails}
            data={data}
            style={{
              transition: 'transform 2s ease-out',
              transform: isOrderHistory ? 'translateX(0)' : 'translateX(100%)',
            }}
          ></OrderHistoryPage>
        )
      }

    </section>

  );
};
