import React, { useContext, useEffect, useState } from "react";
import "./Infpopupdesign.css";
import './Additional.css';
import DropdownInf from "./DropdownInf";
import bannerimage from "../../../Images/bannerimage.jpg";
import axios from "axios";
import { userContext } from "../../../context/userContext";
import { environmentVariables } from "../../../config/env.config";
import TooltipImg from '../../../../src/Images/info.png';
const Producttableinf = ({
  selectedBusiness,
  searchQuery,
  options,
  optionsId,
  allmktbusiness,
  onSelectedProductsChange,
  selectedPercentage,
  formikData
}) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  // const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState('₹');

  const [products, setProducts] = useState([]);
  const { userData } = useContext(userContext);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        if (!!selectedBusiness) {
          const response = await axios.post(
            `${environmentVariables?.apiUrl}api/v1/influencer/getAllProductsByBusinessId`,
            {
              business_id: selectedBusiness,
            },
            {
              headers: {
                _token: userData?.token,
                "Content-Type": "application/json",
              },
            }
          );
  
          const updatedProducts = response?.data?.data?.map((product) => {
            const productPrice = parseFloat(product?.product_price) || 0;
            const percentageIncrease = (productPrice * selectedPercentage) / 100;
            const listedPrice = (productPrice + percentageIncrease).toFixed(2);
            return {
              ...product,
              listed_price: listedPrice,
            };
          });
  
          setProducts(updatedProducts);
        } else {
          setProducts([]);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        setProducts([]);
      }
    };
  
    fetchProducts();
  }, [selectedBusiness, selectedPercentage]);
  
useEffect(() => {
  if (formikData?.shipCountry) {
    const countryCode = formikData?.shipCountry;

    switch (countryCode) {
      case "IN":
        setCurrencySymbol("₹");
        break;
      case "US":
        setCurrencySymbol("$");
        break;
      case "AE":
        setCurrencySymbol("د.");
        break;
      default:
        setCurrencySymbol("₹");
        break;
    }
  }
}, [formikData?.shipCountry]);

  useEffect(() => {
    onSelectedProductsChange(selectedProducts);
  }, [selectedProducts, onSelectedProductsChange]);

  const handleCheckboxChangeOld = (product) => {
    
    setSelectedProducts((prevSelected) => {
      const updatedSelectedProducts = prevSelected.includes(product.id)
        ? prevSelected.filter((productId) => productId !== product.id)
        : [...prevSelected, product.id];
      return updatedSelectedProducts;
    });
  };

  const handleCheckboxChange = (product) => {
  
    setSelectedProducts((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (selectedProduct) => selectedProduct.id === product.id
      );
  
      const updatedSelectedProducts = isAlreadySelected
        ? prevSelected.filter(
            (selectedProduct) => selectedProduct.id !== product.id
          )
        : [...prevSelected, { id: product.id, product_price: product.product_price, listed_price: product.listed_price }];
  
      return updatedSelectedProducts;
    });
  };


  const handleSelectAll = () => {
    if (selectedProducts.length === products.length) {
      // Deselect all
      setSelectedProducts([]);
    } else {
      // Select all products
      const allProducts = products.map((product) => ({
        id: product.id,
        product_price: product.product_price,
        listed_price: product.listed_price,
      }));
      setSelectedProducts(allProducts);
    }
  };
  

  
  const filteredProducts = searchQuery
  ? products?.filter(
      (product) =>
        product.product_price?.toString().match(/^\d+(\.\d+)?$/) &&
        (product.product_name
          ?.toLowerCase()
          .includes(searchQuery?.toLowerCase()) ||
          product.stock?.toLowerCase().includes(searchQuery?.toLowerCase()))
    )
  : products?.filter(
      (product) => product.product_price?.toString().match(/^\d+(\.\d+)?$/)
    );




  return (
    <div className="table-container">
      <div className="table">
        <div className="table-row table-head">
          <div className="table-cell">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={selectedProducts?.length === filteredProducts?.length}
            />
          </div>
          <div className="table-cell prodname">Product Name</div>
          <div className="table-cell">Product Img</div>
          <div className="table-cell">Product Category</div>
          <div className="table-cell">Product Price<div className="tooltip-wrapperBp" style={{padding:0}}>
  <h3 className="tooltipcontainer_detail">
    <span className="tooltip-target-detail-product"> <img className="tooltipimgDetail" src={TooltipImg}  style={{width:'18px'}}/>  </span> 
    <span className="tooltip-detail-table" style={{top:'-100%'}}>This represents the product's base price.</span>
  </h3>
                </div></div>
          <div className="table-cell">Listing Price <div className="tooltip-wrapperBp" style={{padding:0}}>
  <h3 className="tooltipcontainer_detail">
    <span className="tooltip-target-detail-product"><img className="tooltipimgDetail" src={TooltipImg}  style={{width:'18px'}}/>  </span> 
    <span className="tooltip-detail-table">This represents the listing price.</span>
  </h3>
                </div></div>
          <div className="table-cell">Product Quantity</div>
        </div>
        {/* {console.log(filteredProducts,"productproduct")} */}
        {!!filteredProducts && filteredProducts.length>0?
        filteredProducts?.map((product) => (
          <div className="table-row" key={product.id}>
            <div className="table-cell">
              <input
                type="checkbox"
                checked={selectedProducts.some(
                  (selectedProduct) => selectedProduct.id === product.id
                )}
                onChange={() => handleCheckboxChange(product)}
              />
            </div>
            <div className="table-cell prodname">
              {/* <img src={product.image} alt={product.name} className="product-image" /> */}
              {product?.product_name}
            </div>
            <div className="table-cell">
              {/* <span className={`status-badge ${product.status?.toLowerCase()?.replace(" ", "-")}`}>
                {product.status}
              </span> */}
              <img
                src={`${environmentVariables?.apiUrl}uploads/${product?.image}`}
                alt={product.name}
                className="product-image"
              />
            </div>

            <div className="table-cell">{product?.productCat?.category_Name || ""}</div>
            <div className="table-cell">{currencySymbol}{product.product_price}</div>
            <div className="table-cell">{currencySymbol}{product.listed_price}</div>
            <div className="table-cell">{product.product_quantity}</div>
          </div>
        )):
          <div>
            {!!selectedBusiness ? 
            <div className="selectbusinessoncondition">Product<span>Not Available</span>in this Business</div>
            : 
            <div className="selectbusinessoncondition">Please<span>Select a Business</span>from dropdown to choose products</div>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default Producttableinf;
