import React, { useEffect, useContext, useState, useRef } from "react";
import { userContext } from "../../../../context/userContext";
import { environmentVariables } from "../../../../config/env.config";
import { useParams, useLocation } from "react-router-dom";
import { getTimeZone } from "../../getTimeZone";
import { businessObjContext } from "../../../../context/businessObjContext";
import SubContactInfo from "./SubContactInfo";
import SubSocialMedia from "./SubSocialMedia";
import SubGoogleReview from "./SubGoogleReview";
import SubAlternateUrls from "./SubAlternateUrls";
import SubService from "./SubService";
import SubTestimonial from "./SubTestimonial";
import SubBusinessHoursData from "./SubBusinessHoursData";
import axios from "axios";
import QRCode from "qrcode.react";
import "./Theme53.css";
import { ToastContainer, toast } from "react-toastify";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";

import banner from "../../../../Images/theme35banner.png";
import logo from "../../../../Images/theme-17-logo.png";
import GalleryCarousel from "./GalleryCarousel";
import QrCodeGenerator from "../theme6/QRCode";
import CalanderIconn from "../../../../Images/calendarIcondays-new.png";
import whiteCircle from "../../../../../src/Images/white-circle.png";
import download from "../../../../Images/theme-17-download.png";
import addcontact from "../../../../Images/theme17-add-contact.png";
import logo1 from "../../../../Images/theme-53-l1.png";
import logo2 from "../../../../Images/theme-53-l2.png";
import logo3 from "../../../../Images/theme-53-l3.png";
import logo4 from "../../../../Images/theme-53-l4.png";
import product1 from "../../../../Images/theme-53-p1.png";
import product2 from "../../../../Images/theme-53-p2.png";
import insta from "../../../../Images/theme-53-insta.png";
import youtube from "../../../../Images/Theme-53-youtube.png";
import Star1 from "../../../../Images/Img1FullStar.png";
import Star2 from "../../../../Images/Img3blurStar.png";
import Star3 from "../../../../Images/Img2HalfStar.png";
import { Products } from "./Products";
import LoginSignup from "./LoginSignup";
import OtpPopup from "./OTPPopup";
import Swal from "sweetalert2";
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { contactFormSchema } from "../../../../common/contacFormSchema";
import { OrderDeatilshhistory } from "./OrderDeatilshhistory";
import { HireForm } from "./HireForm";
import { initializeFacebookPixel } from "../../../Components/FacebookPixel";
import { formatSubscribers, getcoutryfromlocation } from "../../../../utils/utility";

// const socket = io.connect(`${environmentVariables?.apiUrl}`);
const Theme53Ext = (props) => {
  const rs = props.rs;

  const [businessLink, setBusinessLink] = useState("");
  const [storedData, setStoredData] = useState(
    localStorage.getItem("businessObj")
  );
  const baseUrl = environmentVariables?.apiUrl;
  const fronturl = environmentVariables?.frntUrl;
  const { userData } = useContext(userContext);
  let { param } = useParams();
  const [pparam, setPparam] = useState([]);
  const [isSubmitContact, setIsSubmitContact] = useState(false);
  const [isSubmitAppointment, setIsSubmitAppointment] = useState(false);
  const [showTimeSlots, setShowTimeSlots] = useState(false);
  const [theme, setTheme] = useState();
  const searchParams = new URLSearchParams(window.location.search);
  const appParams = searchParams.get("isApp");
  const paramValue = searchParams.get("param");
  const location = useLocation();
  // const [rs, setRs] = useState({});
  const [appointmentdata, setAppointmentdata] = useState();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [loadingAppointment, setLoadingAppointment] = useState(false);
  const [galleryObject, setGalleryObject] = useState(null);
  const [textareaValue, setTextareaValue] = useState("");
  const [backColor, setBackColor] = useState();
  const [currentuser, setCurrentUser] = useState();
  const [updatedState, setUpdatedState] = useState(false);
  const createUserRef = useRef(null);
  const [youtubeSubscribe, setYoutubeSubscribe] = useState("")

  const currentUrl = window.location.href;
  localStorage.setItem("currentBusinessUrl", currentUrl);
  let currentUsrDatafromstorage = localStorage.getItem("Customer");

  // for redirecting on orders page
  let getRedirectOnOrders = localStorage.getItem('needToRedirectOnOrders');


  useEffect(() => {
    let currentUserData =
      !!currentUsrDatafromstorage && currentUsrDatafromstorage != null
        ? JSON.parse(currentUsrDatafromstorage)
        : "";
    setCurrentUser(currentUserData);
  }, [updatedState, localStorage]);

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const { businessObjData } = useContext(businessObjContext);
  const [order, setOrder] = useState(null);

  const [appointmentName, setAppointmentName] = useState("");
  const [appointmentEmail, setAppointmentEmail] = useState("");
  const [appointmentContactNumber, setAppointmentContactNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [minDate, setMinDate] = useState("");
  const [showGallery, setShowGallery] = useState(false);
  const [showProduct, setShowProduct] = useState(false);
  const [showOrderHistory, setShowOrderHistory] = useState(false);
  const [showOrderList, setShowOrderList] = useState(false);

  const [errorMsgName, setErrorMsgName] = useState("");
  const [errorMsgEmail, setErrorMsgEmail] = useState("");
  const [errorMsgNo, setErrorMsgNo] = useState("");
  const [errorMsgText, setErrorMsgText] = useState("");
  const calendarInputRef = useRef(null);
  const [forwardedemail, setFormwadedEmail] = useState("");

  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [returnedFromPayment, setReturnedFromPayment] = useState(false);
  const [hire , setHire] = useState(false)
  const [countryCode, setCountryCode] = useState("uae");
  
    const fetchCountryfromlocation = async () => {
      try {
        const countryData = await getcoutryfromlocation();
        console.log("countryData-==>",countryData);
        setCountryCode(countryData.toLowerCase());
      } catch (error) {
        console.error("Error fetching country data:", error);
      }
    };


  useEffect(() => {    
    if (!!getRedirectOnOrders && getRedirectOnOrders == 'Yes') {
      setShowOrderHistory(true);
      setShowOrderList(true);
      localStorage.removeItem('needToRedirectOnOrders')
    }
  }, [getRedirectOnOrders]);


   useEffect(() => {
        const pixelId = rs?.pixel_id; 
        initializeFacebookPixel(pixelId);
        fetchCountryfromlocation()
      }, []);

  useEffect(() => {
    // Set the minimum date to the current date
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setMinDate(formattedDate);
  }, []);
  // useEffect(() => {
  //   socket.emit("join_room", rs?.created_by);
  // }, [socket]);
  const [currencySymbol, setCurrencySymbol] = useState("₹");
  useEffect(() => {
    if (props.rs?.contactInfo) {
      const countryCode = props?.rs?.shipCountry;

      switch (countryCode) {
        case "IN":
          setCurrencySymbol("₹");
          break;
        case "US":
          setCurrencySymbol("$");
          break;
        case "AE":
          setCurrencySymbol("د.");
          break;
        // Add other country codes and their currency symbols as needed
        default:
          setCurrencySymbol("₹"); // Default to INR
          break;
      }
    }
  }, [props.rs?.contactInfo]);

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };
  const toggleGallery = () => {
    setShowGallery(!showGallery);
  };
  const toggleProduct = () => {
    setShowProduct(!showProduct);
  };
  const toggleHire = () =>{
    setHire(!hire);
  }
  const toggleOrderHistryToggle = () => {
    setShowOrderHistory(!showOrderHistory);
  };

  const getAppointmentData = async (date) => {
    setLoadingAppointment(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/appointment/fetchAppointmentSlots?userId=${userData?.id}&businessId=${rs?.id}&date="${date}"`,
      headers: {
        _token: userData?.token,
      },
    };

    try {
      const response = await axios.request(config);
      // console.log(
      //   response?.data?.response[0]?.slot,
      //   "responseofgetappointmentdata"
      // );
      setLoadingAppointment(false);
      return response?.data?.response[0]?.slot;
    } catch (error) {
      console.log(error);
      setLoadingAppointment(false);

      return error?.message;
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);

  // const openModal = () => setIsModalOpen(true);
  // const openModalRegister = () => setIsModalOpenRegister(true);
  // const closeModal = () => setIsModalOpen(false);
  // const closeModalRegister = () => setIsModalOpenRegister(false);

  const handleChangeAppointmentDate = async (dateVal) => {
    setShowTimeSlots(true);
    setSelectedDate(dateVal);
    // const dateObject = new Date(dateVal);

    // const dayIndex = dateObject.getDay();

    // const daysOfWeek = [
    //   "Sunday",
    //   "Monday",
    //   "Tuesday",
    //   "Wednesday",
    //   "Thursday",
    //   "Friday",
    //   "Saturday",
    // ];
    // console.log(rs?.appointmentObj?.content, "appointmentinsidetheme1date");
    try {
      // const parsedContent = JSON.parse(JSON.parse(rs?.appointmentObj?.content));
      // setAppointmentdata(
      //   parsedContent[`${daysOfWeek[dayIndex].toLowerCase()}`]
      // );
      const appointments = await getAppointmentData(dateVal);
      setAppointmentdata(appointments);
    } catch (err) {
      console.log(err);
    }
  };
  const handleButtonClick = () => {
    setIsButtonClicked(true);
  };

  let ff = rs?.contactInfo?.content;

  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  if (ff?.length) {
    ff = JSON?.parse(ff);
  }
  const getThemeNumber = (themeNum) => {
    return themeNum.split("-").pop();
  };
  const getBackColor = (backColor) => {
    const color = backColor.split("-")[0];
    if (color == "color1") {
      return "#F9D254";
    } else if (color == "color2") {
      return "#8ECAE6";
    } else if (color == "color3") {
      return "#F9D254";
    } else if (color == "color4") {
      return "#FDF0D5";
    } else {
      return "#F9D254";
    }
  };

  useEffect(() => {
    const path = location?.pathname;
    const pathParts = path.split("/");
    setPparam(pathParts);
  }, [location]);

  const getImage = (image) => {
    if (image.includes("base64")) {
      return image;
    } else {
      return `${environmentVariables?.apiUrl}uploads/${image}`;
    }
  };

  function isValidPhoneNumber(phoneNumber) {
    const phonePattern = /^\d{10,12}$/; // Assuming a 10-digit phone number

    return phonePattern.test(phoneNumber);
  }

  const isValidName = (name) => {
    // This regex pattern allows letters (both uppercase and lowercase), hyphens, and spaces.
    const nameRegex = /^[A-Za-z -]+$/;
    return nameRegex.test(name);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
    return emailRegex.test(email);
  };

  // const getPlanName = () => {
  //   const path = location.pathname;
  //   const pp = path.split("/");
  //   axios
  //     .get(`${baseUrl}api/v1/business/fetchBusinessBySlug`, {
  //       params: { slug: props?.Slug },
  //       headers: {
  //         //   _token: userData?.token,
  //       },
  //       // withCredentials: true,
  //     })
  //     .then((res) => {
  //       console.log(res?.data?.data?.theme_color, "ERERER");
  //       setRs(res?.data?.data);
  //       let appointmentData2 = [];
  //       if (
  //         res?.data?.data &&
  //         res?.data?.data?.appointmentObj &&
  //         res?.data?.data?.appointmentObj?.content
  //       ) {
  //         appointmentData2 = JSON.parse(
  //           res?.data?.data?.appointmentObj?.content
  //         );

  //         if (Array.isArray(appointmentData2)) {
  //           setAppointmentdata(appointmentData2);
  //         }
  //       }

  //       console.log(appointmentData2, "appointmentdata 22324 rs rs");
  //       setGalleryObject(JSON.parse(res?.data?.data?.gallaryObj?.content));
  //       setBackColor(getBackColor(res?.data?.data?.theme_color));
  //       console.log(
  //         getTemplateId(res?.data?.data?.theme_color.split("-").pop()),
  //         "template id number"
  //       );
  //       setTheme(getTemplateId(res?.data?.data?.theme_color.split("-").pop()));
  //     })
  //     .catch((err) => {
  //       console.log({ msg: "err", err: err });
  //     });
  // };

  // console.log("Paddddddrts:", pparam);

  useEffect(() => {
    if (storedData != "undefined") {
      const businessData = JSON.parse(storedData);
      const slugb = businessData?.slug;
      const originUrl = window.location.origin;
      setBusinessLink(`${originUrl}/${slugb}`);
    }
  }, [storedData]);

  // useEffect(() => {
  //   getPlanName();
  // }, []);

  const changeTimeFormat = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };
  let contactInfo = props?.rs?.contactInfo?.content;
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  if (contactInfo?.length) {
    contactInfo = JSON.parse(contactInfo);
  }
  const VCardContent = `BEGIN:VCARD
VERSION:3.0
N:${props?.rs?.sub_title}
FN:${props?.rs?.sub_title}
ORG:${props?.rs?.title}
TITLE:${props?.rs?.designation}
URL:https://businessbay.io/${props?.rs?.slug}
TEL;TYPE=CELL:${contactInfo?.contactInfoPhone}
EMAIL;TYPE=INTERNET:${contactInfo?.contactInfoEmail}
ADR;TYPE=HOME:;${contactInfo?.contactInfoAddress}
URL:${contactInfo?.contactInfoWebUrl}
X-ABADR:country
X-ABLabel:Title
X-ABLabel:eBusiness Card
NOTE;CHARSET=UTF-8:${props?.rs?.description?.replace(/\s+/g, " ").trim()}
END:VCARD`;

  const downloadVCard = () => {
    const blob = new Blob([VCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "contact.vcf"; // Set the desired filename here

    // Create a click event
    const clickEvent = new MouseEvent("click", {
      bubbles: true,
      cancelable: false,
      view: window,
    });

    // Dispatch the click event
    a.dispatchEvent(clickEvent);

    // Revoke the object URL to free up resources
    window.URL.revokeObjectURL(url);
  };

  // const businessHoursData = JSON.parse(rs.businessHourObj.content);
  const businessHoursData = rs.businessHourObj
    ? JSON.parse(rs.businessHourObj.content)
    : null;

  const changeTimeFormatt = (time24) => {
    // Split the time string into hours and minutes
    const [hours, minutes] = time24.split(":").map(Number);

    // Create a Date object with today's date
    const date = new Date();

    // Set the hours and minutes to the Date object
    date.setHours(hours);
    date.setMinutes(minutes);

    // Format the time in AM/PM format
    const time12 = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return time12;
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
  
    const alphanumericValue = value.replace(/[^A-Za-z -]/g, "");
  
    formik.setFieldValue("name", alphanumericValue);
  };
  
  const initialValues = {
    name: "",
    email: "",
    contactNumber: "",
    textareaValue: "",
    countryCode: countryCode,
  };
  
  let formik = useFormik({
    initialValues: initialValues,
    validationSchema: contactFormSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("values", values)
  
      let number_last = values.contactNumber?.split("-")?.[1];
      let number_first = values.contactNumber?.split("-")?.[0];
  
      let config = {
        method: "post",
        url: `${environmentVariables?.apiUrl}api/v1/contact/MakeContact`,
        withCredentials: true,
        data: {
          business_id: props.id,
          name: values.name,
          email: values.email,
          dialcode: number_first,
          phone: number_last,
          message: values.textareaValue,
          createdBy: props?.rs?.created_by,
        },
      };
  
      axios
        .request(config)
        .then((response) => {
          toast.success("Thank you for contacting us");
          resetForm();
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message || error?.message);
        });
    },
  });
  
  const { values, errors, handleSubmit } = formik;

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   setErrorMsgName("");
  //   setErrorMsgEmail("");
  //   setErrorMsgNo("");
  //   setErrorMsgText("");

  //   if (!name) {
  //     setErrorMsgName("Name is required.");
  //     return;
  //   } else if (!isValidName(name)) {
  //     setErrorMsgName("Only alphabets allowed.");
  //     return;
  //   }

  //   if (!email) {
  //     setErrorMsgEmail("Email is required.");
  //     return;
  //   } else if (!isValidEmail(email)) {
  //     setErrorMsgEmail("Invalid email.");
  //     return;
  //   }

  //   if (!isValidPhoneNumber(contactNumber)) {
  //     setErrorMsgNo("Invalid mobile number.");
  //     return;
  //   }

  //   if (!textareaValue) {
  //     setErrorMsgText("Message is required.");
  //     return;
  //   }
  //   if (props?.rs?.influencer_id == null) {
  //     setErrorMsgText("Business is not associated with influencer.");
  //     return;
  //   }

  //   setIsSubmitContact(true);

  //   try {
  //     const response = await axios.post(
  //       `${baseUrl}api/v1/contact/MakeContact`,
  //       {
  //         business_id: props.id,
  //         name,
  //         email,
  //         phone: contactNumber,
  //         message: textareaValue,
  //         createdBy: props?.rs?.influencer_id,
  //       },
  //       {
  //         headers: {
  //           _token: userData?.token,
  //         },
  //       }
  //     );
  //     // socket.emit("contact", rs?.created_by);
  //     toast.success("Thank you for contacting us", {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     setIsSubmitContact(false);
  //   } catch (error) {
  //     toast.error(error?.response?.data?.message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //       autoClose: 1000,
  //     });
  //     setIsSubmitContact(false);
  //     console.error("Error:", error);
  //   }
  // };

  useEffect(() => {
    getBlockOrder();
    getYoutubeSubscribe()
    if (rs?.newGalleryImages) {
      setGalleryObject(rs?.newGalleryImages);
    }
    if (rs?.appointmentObj && rs?.appointmentObj?.content) {
      try {
        // const parsedContent = JSON.parse(
        //   JSON.parse(rs?.appointmentObj?.content)
        // );
        // console.log(parsedContent, "...............//////////////");
        // if (Array.isArray(parsedContent) && parsedContent.length !== 0) {
        // setAppointmentdata(parsedContent);
        // }
        // if (JSON.parse(rs?.appointmentObj?.content)?.length != 0) {
        //   if (JSON.parse(JSON.parse(rs?.appointmentObj?.content))) {
        //     setAppointmentdata(
        //       JSON.parse(JSON.parse(rs?.appointmentObj?.content))
        //     );
        //   }
        // }
      } catch (err) {
        console.log(err);
      }
    }
  }, [rs]);
  const getTemplateId = (theme) => {
    if (theme == "theme1") {
      return "template3";
    } else if (theme == "theme2") {
      return "template4";
    } else if (theme == "theme3") {
      return "template5";
    } else if (theme == "theme4") {
      return "template6";
    } else if (theme == "theme5") {
      return "template7";
    } else if (theme == "theme6") {
      return "template8";
    }
  };
  const getBlockOrder = async () => {
    // const handleGetBlock = async () => {
    try {
      let id = props?.id;

      if (id) {
        let { data: get } = await axios.get(
          `${environmentVariables?.apiUrl}api/v1/business/FetchBlock?business_id=${id}`,
          {
            headers: {
              _token: userData?.token,
            },
            // withCredentials: true,
          }
        );
        // console.log(get?.data?.order, "order data template2");
        setOrder(get?.data?.order);
      }

      // console.log(get.data.order,"Getetgwef")
    } catch (err) {
      console.log(err);
    }
    // };
  };

  const getYoutubeSubscribe = async () => {

    try {
      let name = rs?.youtubeName;

      if (name) {
        let { data: get } = await axios.get(
          `${environmentVariables?.apiUrl}api/v1/influencer/getYoutubeSubscribers?youtubeHandle=${name}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
        console.log(get?.subscribers, "youtubedataa")
        setYoutubeSubscribe(get?.subscribers)
        // setOrder(get?.data?.order);
      }

    } catch (err) {
      console.log(err);
    }
  };

  const getBlocksOrder = () => {
    let BlocksArray = [];
    for (const key in order) {
      BlocksArray[order[key]] = getJSXElement(key);
    }
    // console.log(order, "AAAA");
    return BlocksArray;
  };
  const getJSXElement = (elemName) => {
    // console.log(elemName, "elemName");
    if (elemName == "appointment") {
      if (rs?.appointmentObj?.is_enabled === 1) {
        return "";
        // <>
        //   <article className="makeappitmneets sec">
        //     <h3 className="sectitle">Make Appointment</h3>
        //     <div className="contact-form">
        //       {rs?.contactInfo?.country && (
        //         <h5 style={{ marginBottom: "18px" }}>{`${
        //           rs?.contactInfo?.country
        //         } (GMT${getTimeZone(rs?.contactInfo?.country)})`}</h5>
        //       )}
        //       <div className="row">
        //         <div class="col-6 mb-3" style={{ position: "relative" }}>
        //           <label> Select Date </label>
        //           <div style={{ position: "relative" }}>
        //             <input
        //               type="date"
        //               ref={calendarInputRef}
        //               class="form-control clear_string clor-blk"
        //               placeholder="Date"
        //               name="currentDate"
        //               value={selectedDate}
        //               min={minDate}
        //               onChange={(e) =>
        //                 handleChangeAppointmentDate(e.target.value)
        //               }
        //             />
        //             <div className="calanderIcon" onClick={openCalendar}>
        //               <img src={CalanderIconn} alt="Calendar Icon" />
        //             </div>
        //           </div>
        //         </div>
        //         {showTimeSlots && (
        //           <>
        //             {loadingAppointment ? (
        //               <div className="skeleton-blog">
        //                 <div className="skeleton-footer"></div>
        //               </div>
        //             ) : (
        //               <div class="col-6 mb-3">
        //                 <label> Select Time </label>
        //                 <select
        //                   disabled={appointmentdata?.length == 0}
        //                   class="form-select"
        //                   aria-label="Default select example"
        //                   onChange={(e) => setSelectedTime(e.target.value)}
        //                 >
        //                   <option value="Select time">
        //                     {appointmentdata?.length == 0
        //                       ? "No Slots Available for Meeting"
        //                       : "Select time"}
        //                   </option>
        //                   {appointmentdata &&
        //                     appointmentdata?.map((val) => (
        //                       <option
        //                         disabled={val.status == "confirm"}
        //                         key={val?.startDate}
        //                         value={`${val?.startDate} - ${val?.endDate}`}
        //                       >
        //                         {`${val?.startDate} - ${val?.endDate}`}
        //                       </option>
        //                     ))}
        //                 </select>
        //               </div>
        //             )}
        //           </>
        //         )}
        //       </div>
        //       <div class="col-12 mb-3">
        //         <button
        //           className="btn btnmakeappitmnets"
        //           data-bs-toggle="modal"
        //           data-bs-target="#appotimnets"
        //           disabled={!selectedDate || !selectedTime}
        //         >
        //           Create Appointment <img src={arrow} alt="arrow" />
        //         </button>
        //       </div>
        //     </div>
        //   </article>
        // </>
      }
    } else if (elemName == "contact_form") {
      if (rs?.is_contact_form_enabled) {
        return (
          <article className="conatctform sec">
            <h3 class="sectitle">Contact Form </h3>
            <div class="contact-form">
              <form onSubmit={handleSubmit}>
                <div class="col-12 mb-3">
                  {/* <label class="form-label">Name*</label> */}
                  <input
                    type="text"
                    class="form-control clear_string"
                    placeholder="Full Name"
                    value={formik.values.name}
                    onChange={handleNameChange}
                    onBlur={formik.handleBlur}
                    required=""
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",

                        bottom: "70px",
                      }}
                    >{formik.errors.name}</div>
                  ) : null}
                </div>
                <div class="col-12 mb-3">
                  {/* <label class="form-label">Email*</label> */}
                  <input
                    type="email"
                    class="form-control"
                    placeholder="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required=""
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",

                        bottom: "70px",
                      }}
                    >{formik.errors.email}</div>
                  ) : null}
                </div>
                <div class="col-12 mb-3">
                  {/* <label class="form-label">Phone*</label> */}
                  <PhoneInput
                    name="contactNumber"
                    value={values.contactNumber}
                    country={countryCode}
                    countryCodeEditable={false}
                    onChange={(value, country) => {
                      console.log("value, country", value, country)
                      formik.setFieldValue("contactNumber", `+${country.dialCode}-${value.substring(
                        country.dialCode.length
                      )}`);
                      formik.setFieldValue("countryCode", country.dialCode);
                    }}
                    className="DetailsInfoPhoneInput"
                  />
                  {formik.touched.contactNumber && formik.errors.contactNumber ? (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",

                        bottom: "70px",
                      }}
                    >{formik.errors.contactNumber}</div>
                  ) : null}
                </div>
                <div class="col-12 mb-3">
                  {/* <label class="form-label">Case Description* </label> */}
                  <textarea
                    class="form-control clear_string"
                    rows="3"
                    placeholder="Your message goes here..."
                    required=""
                    name="textareaValue"
                    value={formik.values.textareaValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  ></textarea>
                  {formik.touched.textareaValue && formik.errors.textareaValue ? (
                    <div
                      style={{
                        fontSize: "14px",
                        color: "red",

                        bottom: "70px",
                      }}
                    >{formik.errors.textareaValue}</div>
                  ) : null}
                </div>

                <div class="col-12 mb-3">
                  {isSubmitContact ? (
                    <div>
                      <CircularLoader size={20} />
                    </div>
                  ) : (
                    <button type="submit" class="btn btn-submitform">
                      Submit
                      {/* <img src={arrow} alt="arrow" /> */}
                    </button>
                  )}
                </div>
              </form>
            </div>
            <ToastContainer />
          </article>
        );
      }
    } else if (elemName === "business_hour") {
      if (rs?.businessHourObj?.is_enabled === 1) {
        // return <SubBusinessHoursData rs={rs} />;
      }
    } else if (elemName === "contact_info") {
      if (rs?.contactInfo?.is_enabled === 1) {
        return <SubContactInfo rs={rs} />;
      }
    } else if (elemName == "custom_html") {
      return <></>;
    } else if (elemName == "description") {
      return (
        <>
          <article className="abouttemplate sec">
            {/* <h3 class="sectitle">About me</h3> */}
            <p className="para">
              {rs.description}
            </p>
            {rs?.is_enabledHireMe == '1' &&(<button onClick={()=>toggleHire()}>HIRE ME</button>)}
           
          </article>
         
          <HireForm active={hire} handleClose={toggleHire}  props={props}/>
   
        </>
      );
    } else if (elemName == "gallery") {
      if (rs?.gallaryObj?.is_enabled === 1) {
        return (
          galleryObject && (
            <article className="brand-deals sec">
              <h3
                className="sectitle"
              // style={{ cursor: "pointer" }}
              // onClick={toggleGallery}
              >
                Brand Deals
              </h3>

              {showGallery ? (
                <GalleryCarousel
                  slides={galleryObject}
                  showGallery={showGallery}
                  toggleGallery={toggleGallery}
                />
              ) : (
                <div
                  className="brand-inner"
                  style={{ justifyContent: "center" }}
                >
                  {galleryObject?.map((image, index) => (
                    <div className="item" key={index}>
                      <img
                        src={`${environmentVariables?.apiUrl}uploads/gallery/${image?.image}`}
                        alt={`Brand Logo ${index + 1}`}
                        style={{ borderRadius: "50%" }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </article>
          )
          // galleryObject && (
          //   <article class="gallery sec  sec-common">
          //     {/* <h3 class="sectitle">Portfolio </h3> */}
          //     {galleryObject && (
          //       <GalleryCarousel
          //         slides={galleryObject}
          //         showGallery={showGallery}
          //         toggleGallery={toggleGallery}
          //       />
          //     )}
          //     <h3
          //       className="sectitle"
          //       style={{
          //         marginTop: "5px",
          //         fontSize: "14px",
          //         cursor: "pointer",
          //       }}
          //       onClick={toggleGallery}
          //     >
          //       View All
          //     </h3>
          //   </article>
          // )
        );
      }
    } else if (elemName == "more") {
      return <></>;
    } else if (elemName === "service") {
      if (rs?.serviceObj?.is_enabled === 1) {
        // return <SubService rs={rs} />;
      }
    } else if (elemName == "social") {
    } else if (elemName == "testimonials") {
      if (rs?.testimonialObj?.is_enabled === 1) {
        // return <SubTestimonial rs={rs} />;
      }
    } else if (elemName == "google_review") {
      if (rs?.is_enabledGooglereview == 1) {
        return <SubGoogleReview rs={rs} />;
      }
    } else if (elemName == "alternateUrls") {
      if (rs?.alternatUrlObj?.is_enabled == 1) {
        return  <SubAlternateUrls rs= {rs}/>
      }
    }
  };

  const logout = async () => {
    try {
      // Display confirmation dialog using Swal.fire
      const result = await Swal.fire({
        title: "Are you sure?",
        text: `You wont to logout`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Logout!",
      });

      // Check if the user confirmed the deletion
      if (result.isConfirmed) {
        localStorage.removeItem("Customer");
        setUpdatedState(!updatedState);
        setCurrentUser();
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  // const downloadInvoice=async(orderid)=>{

  //   try {
  //     // setIsDownloading(true);


  //     const response = await axios.get(
  //       `${baseUrl}api/v1/influencer/downloadInvoice?orderid=${orderid}`,
  //       {
  //         responseType: "blob", // Important
  //         // headers: {
  //         //   _token: authData?.token,
  //         // },
  //       }
  //     );
  //     console.log("response", response?.data);
  //     const blob = new Blob([response.data], { type: "application/pdf" });
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement("a");
  //     a.href = url;
  //     a.download = `Invoice_${orderid}.pdf`;
  //     a.click();
  //     // setIsDownloading(false);
  //   } catch (error) {
  //     console.log(error.message);
  //     Swal.fire({
  //       icon: "error",
  //       text: error.message || error?.response?.data?.message,
  //     });
  //     // setIsDownloading(false);
  //   }
  // }

  const handleSubmits = async (e) => {
    e.preventDefault();
    setErrorMsgName("");
    setErrorMsgEmail("");
    setErrorMsgNo("");
    setErrorMsgText("");

    if (!appointmentName) {
      setErrorMsgName("Name is required.");
      return;
    } else if (!isValidName(appointmentName)) {
      setErrorMsgName("Only alphabets allowed.");
      return;
    }

    if (!appointmentEmail) {
      setErrorMsgEmail("Email is required.");
      return;
    } else if (!isValidEmail(appointmentEmail)) {
      setErrorMsgEmail("Invalid email.");
      return;
    }

    if (!isValidPhoneNumber(appointmentContactNumber)) {
      setErrorMsgNo("Invalid mobile number.");
      return;
    }
    setIsSubmitAppointment(true);

    try {
      const response = await axios.post(
        `${baseUrl}api/v1/appointment/MakeAppoinments`,
        {
          business_id: props.id,
          name: appointmentName,
          email: appointmentEmail,
          phone: appointmentContactNumber,
          date: selectedDate,
          time: selectedTime,
          status: "pending",
          createdBy: props?.rs?.created_by,
          // note: textareaValue,
        },
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      if (response?.data?.success) {
        // socket.emit("appointment", rs?.created_by);
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsSubmitAppointment(false);
        setAppointmentName("");
        setAppointmentEmail("");
        setAppointmentContactNumber("");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }

    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmitAppointment(false);
    }
  };
  const StarRating = ({ value, totalStars, starSize }) => {
    const starElements = [];
    const fullStars = Math.floor(value);
    const halfStar = value % 1 >= 0.5 && value % 1 < 1;

    for (let i = 1; i <= totalStars; i++) {
      let imgSrc;

      if (i <= fullStars) {
        imgSrc = Star1;
      } else if (i === fullStars + 1 && halfStar) {
        imgSrc = Star3;
      } else {
        imgSrc = Star2;
      }

      const imgStyle = {
        width: starSize,
        height: starSize,
        marginRight: "2px",
      };

      starElements.push(
        <img key={i} src={imgSrc} alt={`Star ${i}`} style={imgStyle} />
      );
    }

    return (
      <div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {starElements}
        </div>
      </div>
    );
  };

  const handleCustomerLogin = () => {
    window.location.href = fronturl + "customer_login";
  };

  

  // Usage in your component
  const handleShowHistory = () => {
    console.log("showhistry====>>>", showOrderHistory);
    setShowOrderHistory(true);
  }

  useEffect(()=>{
    console.log("showhistry1212====>>>", showOrderHistory);
  },[showOrderHistory])
  
  return (
    <div id="temp53">
      <div className="main-temaplt" id="temp53">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 wtemplate p-0">
              <article className="imgsechere sec">
                <img
                  src={rs?.banner ? getImage(rs.banner) : banner}
                  className="mx-auto d-block img-fluid coverimghere "
                />
                <div className="imageOverlay"></div>

                {rs?.SocialMediaObj?.is_enabled === 1 && (
                  <SubSocialMedia rs={rs} />
                )}

                <div className="followers">
                  {/* <div className="item">
                    <img src={insta} alt="" />
                    <span>1.8M Followers</span>
                  </div>
                  <p className="middle">|</p> */}
                  {!!youtubeSubscribe &&
                    <div className="item">
                      <img src={youtube} alt="" />
                      <span>{formatSubscribers(youtubeSubscribe)} Subscribers</span>
                    </div>
                  }
                </div>
              </article>




              <section className="mainsection">
                {/* <div className="chevron">
                  {" "}
                  <i class="fa-solid fa-chevron-up"></i>
                </div> */}
                {/* <div className="logoTextContainer"> */}
                <article className="personalinfo">
                  {rs?.is_enabledGooglereview && rs?.is_enabledGooglereview ? (
                    <div className="reviewratingcontainer">
                      <p className="reviewrating">
                        <StarRating
                          value={rs?.rating}
                          totalStars={5}
                          starSize="16px"
                        />
                        {/* <img src={Star1} style={{width:"20px", paddingRight:"5px"}}/> */}
                        <span className="ratingspan"> {rs.rating}</span> (
                        {rs.review} Reviews)
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <img
                      src={rs?.logo ? getImage(rs?.logo) : logo}
                      className="mx-auto d-block img-fluid logo-profile"
                    /> */}
                  <h3 className="username">{rs.sub_title || "Name"}</h3>
                  <p className="titleuser">{rs.designation || "Designation"}</p>

                  {/* <p className="companeyname">
                    {rs.sub_title || "Company Name"}
                  </p> */}
                </article>
                {/* </div> */}

                {getBlocksOrder().map((val) =>
                  val == undefined ? <></> : val
                )}
                {/* 
                <article className="brand-deals sec">
                  <h3 class="sectitle">Brand Deals </h3>

                  <div className="brand-inner">
                    <div className="item">
                      {" "}
                      <img src={logo1} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo2} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo3} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo4} alt="" />
                    </div>
                    <div className="item">
                      {" "}
                      <img src={logo1} alt="" />
                    </div>
                  </div>
                </article> */}

                {/* ///////////////////////Product Popup????? */}
                <Products
                  data={rs}
                  active={showProduct}
                  toggleProduct={toggleProduct}
                  currencySymbol={currencySymbol}
                  updatedState={updatedState}
                  setUpdatedState={setUpdatedState}
                />
                
                {showOrderHistory && (
                  <OrderDeatilshhistory
                    data={rs}
                    active={showOrderHistory}
                    toggleProduct={toggleOrderHistryToggle}
                    currencySymbol={currencySymbol}
                    updatedState={updatedState}
                    setUpdatedState={setUpdatedState}
                    setCurrentUser={setCurrentUser}
                    showOrderList={showOrderList}
                  />
                )}

              </section>
              {/* <article className="sec" >
                <div  onClick={() => setShowOrderHistory(true)}>
                 <h4 style={{color:"white", marginBottom:"10px"}}>Order Details</h4>
                 </div> 
                              </article> */}

              {appParams == null && (
                <>
                  <div className="fixedd fixed-ext">
                    <article className="fixedfooter sec">
                      <div
                        onClick={() => { setShowProduct(true); setShowOrderHistory(false) }}
                        class="addtocinatct"
                      >
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 28 28"
                          version="1.1"
                          fill="#fff"
                          stroke="#fff"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <title>grid</title>{" "}
                            <desc>Created with Sketch Beta.</desc>{" "}
                            <defs> </defs>{" "}
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g
                                id="Icon-Set-Filled"
                                transform="translate(-104.000000, -935.000000)"
                                fill="#fff"
                              >
                                <path
                                  d="M128,935 L124,935 C121.791,935 120,936.791 120,939 L120,943 C120,945.209 121.791,947 124,947 L128,947 C130.209,947 132,945.209 132,943 L132,939 C132,936.791 130.209,935 128,935 L128,935 Z M128,951 L124,951 C121.791,951 120,952.791 120,955 L120,959 C120,961.209 121.791,963 124,963 L128,963 C130.209,963 132,961.209 132,959 L132,955 C132,952.791 130.209,951 128,951 L128,951 Z M112,951 L108,951 C105.791,951 104,952.791 104,955 L104,959 C104,961.209 105.791,963 108,963 L112,963 C114.209,963 116,961.209 116,959 L116,955 C116,952.791 114.209,951 112,951 L112,951 Z M112,935 L108,935 C105.791,935 104,936.791 104,939 L104,943 C104,945.209 105.791,947 108,947 L112,947 C114.209,947 116,945.209 116,943 L116,939 C116,936.791 114.209,935 112,935 L112,935 Z"
                                  id="grid"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                        <p>Products</p>
                      </div>
                      {!!currentuser ? (
                        <div className="addtocinatct"
                          onClick={() => handleShowHistory()}
                        >
                          <i class="fa-solid fa-user"></i>
                          <p>{currentuser?.name}</p>
                        </div>
                      ) : (
                        <div
                          className="addtocinatct"
                          // onClick={()=>handleCustomerLogin()}
                          onClick={() => {
                            setIsModalOpen(true); // close modal    
                            setIsModalOpenRegister(false);
                            setIsOtpModalOpen(false);
                          }}
                        >
                          <i class="fa-solid fa-user-plus"></i>
                          <p>Login</p>
                        </div>
                      )}
                      {/* <div onClick={()=>downloadInvoice("1738632752840")}>
                        download
                      </div> */}

                      <div className="footerleft">
                        <div
                          className="qrcodeshaere"
                          data-bs-toggle="modal"
                          data-bs-target="#qrscan"
                        >
                          <i class="fa-solid fa-qrcode"></i>
                          {/* <p>QR</p> */}
                        </div>
                        {/* <div className="share">
                      <i class="fa-solid fa-arrow-up"></i>
                    </div> */}
                      </div>
                    </article>
                  </div>
                </>
              )}

              <article className="fixedfooter sec">
                <div
                  onClick={() => setShowProduct(true)}
                  class="addtocinatct"
                >
                  <svg
                    width="20px"
                    height="20px"
                    viewBox="0 0 28 28"
                    version="1.1"
                    fill="#fff"
                    stroke="#fff"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>grid</title>{" "}
                      <desc>Created with Sketch Beta.</desc>{" "}
                      <defs> </defs>{" "}
                      <g
                        id="Page-1"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="Icon-Set-Filled"
                          transform="translate(-104.000000, -935.000000)"
                          fill="#fff"
                        >
                          <path
                            d="M128,935 L124,935 C121.791,935 120,936.791 120,939 L120,943 C120,945.209 121.791,947 124,947 L128,947 C130.209,947 132,945.209 132,943 L132,939 C132,936.791 130.209,935 128,935 L128,935 Z M128,951 L124,951 C121.791,951 120,952.791 120,955 L120,959 C120,961.209 121.791,963 124,963 L128,963 C130.209,963 132,961.209 132,959 L132,955 C132,952.791 130.209,951 128,951 L128,951 Z M112,951 L108,951 C105.791,951 104,952.791 104,955 L104,959 C104,961.209 105.791,963 108,963 L112,963 C114.209,963 116,961.209 116,959 L116,955 C116,952.791 114.209,951 112,951 L112,951 Z M112,935 L108,935 C105.791,935 104,936.791 104,939 L104,943 C104,945.209 105.791,947 108,947 L112,947 C114.209,947 116,945.209 116,943 L116,939 C116,936.791 114.209,935 112,935 L112,935 Z"
                            id="grid"
                          ></path>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <p>Products</p>
                </div>
                {!!currentuser ? (
                  <div className="addtocinatct" onClick={() => handleShowHistory()}>
                    <i class="fa-solid fa-user-plus"></i>
                    <p>{currentuser?.name}</p>
                  </div>
                ) : (
                  <div
                    className="addtocinatct"
                    // onClick={()=>handleCustomerLogin()}
                    onClick={() => {
                      setIsModalOpen(true);
                      setIsModalOpenRegister(false);
                      setIsOtpModalOpen(false);
                    }}
                  >
                    <i class="fa-solid fa-user-plus"></i>
                    <p>Login</p>
                  </div>
                )}

                <div className="footerleft">
                  <div
                    className="qrcodeshaere"
                    data-bs-toggle="modal"
                    data-bs-target="#qrscan"
                  >
                    <i class="fa-solid fa-qrcode"></i>
                    <p>QR</p>
                  </div>
                  {/* <div className="share">
                      <i class="fa-solid fa-arrow-up"></i>
                    </div> */}
                </div>
              </article>

              <>
                <div
                  class="modal fade"
                  ref={createUserRef}
                  id="appotimnets"
                  data-bs-backdrop="static"
                  data-bs-keyboard="false"
                  tabindex="-1"
                  aria-labelledby="staticBackdropLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                          {" "}
                          Make Appointment{" "}
                        </h5>
                        <button
                          type="button"
                          class="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div class="modal-body">
                        <form className="row">
                          <div class="col-12 mb-3 extmakeappointmentformplaceholder">
                            <input
                              type="text"
                              class="form-control clear_string extmakeappointmentform"
                              placeholder="Full Name"
                              value={appointmentName}
                              onChange={(e) =>
                                setAppointmentName(e.target.value)
                              }
                            />
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                bottom: "70px",
                              }}
                            >
                              {errorMsgName}
                            </div>
                          </div>
                          <div class="col-12 mb-3 extmakeappointmentformplaceholder">
                            <input
                              type="text"
                              class="form-control clear_string extmakeappointmentform"
                              placeholder="Email"
                              value={appointmentEmail}
                              onChange={(e) =>
                                setAppointmentEmail(e.target.value)
                              }
                            />
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",
                                bottom: "70px",
                              }}
                            >
                              {errorMsgEmail}
                            </div>
                          </div>
                          <div class="col-12 mb-3 extmakeappointmentformplaceholder">
                            <input
                              type="text"
                              class="form-control clear_string extmakeappointmentform"
                              placeholder="Mobile No."
                              value={appointmentContactNumber}
                              onChange={(e) =>
                                setAppointmentContactNumber(e.target.value)
                              }
                            />
                            <div
                              style={{
                                fontSize: "14px",
                                color: "red",

                                bottom: "70px",
                              }}
                            >
                              {errorMsgNo}
                            </div>
                          </div>
                          <div class="mb-2 mt-4 col-md-12">
                            {isSubmitAppointment ? (
                              <div>
                                <CircularLoader size={20} />
                              </div>
                            ) : (
                              <button
                                onClick={handleSubmits}
                                class="btn btn-primary me-3 extmakeappointmentsubmitbtn"
                                type="button"
                              >
                                <i class="bi bi-check-circle-fill ms-2 "></i>
                                Submit
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                      <ToastContainer />
                    </div>
                  </div>
                </div>
                {/* <article className="sec makeappitmneets">
                  <h3 class="sectitle">Make Appointment</h3>
                  <div className="contact-form">
                    <div class="col-12 mb-3"><input type="date"
                      class="form-control clear_string"
                      placeholder="Date"
                      name="currentDate"
                      value={selectedDate}
                      onChange={(e) => setSelectedDate(e.target.value)} /></div>
                    <div class="col-12 mb-3">
                      <select class="form-select" aria-label="Default select example" onChange={(e) => setSelectedTime(e.target.value)}>
                        <option value="Select time" >Select time</option>
                        {appointmentdata?.map((val) => (
                          <option key={val?.startDate} value={`${changeTimeFormat(val?.startDate)} - ${changeTimeFormat(val?.endDate)}`}>
                            {`${changeTimeFormat(val?.startDate)} - ${changeTimeFormat(val?.endDate)}`}
                          </option>
                        ))}
                      </select>

                    </div>
                    <div class="col-12 mb-3">
                      <button className="btn btnmakeappitmnets" data-bs-toggle="modal" data-bs-target="#appotimnets">Make An Appointment</button>
                    </div>
                  </div>
                </article> */}
              </>
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade"
        id="qrscan"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          {/* <div class="modal-content"> */}
          <div class="modal-body" style={{ position: "relative" }}>
            {/* <article className="personalinfo modal-info "> */}
            {/* {rs && rs?.logo && (
              <img
                className="qr-logo-image"
                // src={`${environmentVariables?.apiUrl}uploads/${rs?.logo}`}
                src={
                  rs?.logo
                    ? `${environmentVariables?.apiUrl}uploads/${rs?.logo}`
                    : whitecircle
                }
              />
            )} */}
            {rs && rs.logo ? (
              <img
                className="qr-logo-image"
                src={`${environmentVariables?.apiUrl}uploads/${rs.logo}`}
              />
            ) : (
              <img
                className="qr-logo-image"
                src={whiteCircle}
                alt="White Circle"
              />
            )}
            {/* <QRCode value={`${window.location.origin}/${rs?.slug}`} /> */}
            <QrCodeGenerator />
            {/* <QRCodes businessObj={businessObjData} /> */}

            {/* <p className="companeyname">{rs.sub_title || "Company Name"}</p> */}
            {/* </article> */}
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* {isModalOpen && (
        <LoginSignup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          // isModalOpenRegister={isModalOpenRegister}
        ></LoginSignup>
      )} */}
      {(isModalOpen || isModalOpenRegister) && (
        <LoginSignup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          forwardedemail={forwardedemail}
          setFormwadedEmail={setFormwadedEmail}
          updatedState={updatedState}
          setUpdatedState={setUpdatedState}
        />
      )}
      {isOtpModalOpen && (
        <OtpPopup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          forwardedemail={forwardedemail}
          setFormwadedEmail={setFormwadedEmail}
        />
      )}
    </div>
  );
};

export default Theme53Ext;
