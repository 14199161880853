import React, { useEffect, useState } from "react";
import { environmentVariables } from "../../../../config/env.config";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, useFormik } from "formik";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import { registerValidationCustomer } from "../../../../common/registerValidationCustomer";
import { getcoutryfromlocation, isValidPhoneNumber } from "../../../../utils/utility";
import LoginSignup from "./LoginSignup";
import CircularLoader from "../../../Components/CircularLoader/CircularLoader";
import OtpPopup from "./OTPPopup";
import CaptchaService from "../../../../service/CaptchaService";

const SignupPopup = ({
  isModalOpen,
  setIsModalOpen,
  isModalOpenRegister,
  setIsModalOpenRegister,
  isOtpModalOpen,
  setIsOtpModalOpen,
  forwardedemail,
  setFormwadedEmail,
}) => {
  //   if (!isModalOpenRegister) return null;
  const baseUrl = environmentVariables?.apiUrl;
  const homelocation = environmentVariables?.homeLoc;
  const [selectedOption, setSelectedOption] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const [isCaptchaVerified, setCaptchaVerified] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconPassword] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false); // Track reset condition
  const [invalidPhoneErr, setInvalidPhoneErr] = useState("");
  const [isCaptchaSolved, setIsCaptchaSolved] = useState(false);
  const [invalidCaptchaErr, setInvalidCaptchaErr] = useState("");
  const [countryCode, setCountryCode] = useState("ae");
  
  const fetchCountryfromlocation = async () => {
    try {
      const countryData = await getcoutryfromlocation();
      setCountryCode(countryData?.countryCode?.toLowerCase());
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    fetchCountryfromlocation()
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConVisibility = () => {
    setShowconPassword(!showconPassword);
  };

  function onChangeRecaptcha(value) {
    setCaptchaVerified(value !== null);
    formik.setFieldValue("captchaValue", value);
  }
  function onChangeRecaptchSEtDefaultValue() {
    setCaptchaVerified(false);
    formik.setFieldValue("captchaValue", "");
  }
  useEffect(() => {
    const storedUserData = JSON.parse(localStorage.getItem("user"));
    if (storedUserData) {
      navigate("/dashboard");
      return;
    }
  }, []);


  const initialValuesUserFormik = {
    name: "",
    email: "",
    contact: "",
    password: "",
    confirmPassword: "",
    captchaValue: "",
  };
  // const handleSubmitForPopup = ()=>{
  //   setOTPPopup(true);
  // }
  let formik = useFormik({
    initialValues: initialValuesUserFormik,
    validationSchema: registerValidationCustomer,
    onSubmit: async (values, action) => {
      const storedCaptcha = JSON.parse(localStorage.getItem("captcha"));
      if (!storedCaptcha) {
        toast.error("Captcha expired. Please try again.");
        return;
      }


      setIsSubmit(true);
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isValid = emailPattern.test(values?.email);
      console.log("isCaptchaSolved====>>>", isCaptchaSolved);
      values = {
        ...values,
        email: values?.email?.toLowerCase()?.trim(),
        name: values?.name?.trim(),
        captchaValue:isCaptchaSolved,
        captchaId: storedCaptcha.id,
        captchaAns: isCaptchaSolved
      };

      if (isValid && values?.name != "" && values?.name?.trim().length >= 3) {
        // if (!isCaptchaVerified || Object.keys(formik.errors).length > 0) {
        //   toast.error("Captcha is required", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     autoClose: 1000,
        //   });
        //   setIsSubmit(false);
        //   return;
        // }
        if (!!isCaptchaSolved) {
          axios
            .post(`${baseUrl}api/v1/user/customer_register_withCaptcha`, values)
            .then((res) => {
              setIsSubmit(true);
              localStorage.setItem("getcUsrEmail", values?.email);
              localStorage.removeItem("captcha");
              toast.success("Verification email sent", {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: 1000,
              });
              setTimeout(() => {
                //   navigate("/customer_verify");
                // setIsModalOpen(true);
                // setIsModalOpenRegister(false);

                setIsOtpModalOpen(true);
                setIsModalOpenRegister(false);
                setIsModalOpen(false);
                setFormwadedEmail(values?.email);
              }, 1500);
            })
            .catch((err) => {
              const errorMessage =
                err?.response?.data?.message?.message ||
                err?.response?.data?.message;
              if (errorMessage) {
                toast.error(errorMessage, {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              } else {
                toast.error("An error occurred", {
                  position: toast.POSITION.TOP_RIGHT,
                  autoClose: 1000,
                });
              }
              setIsSubmit(false);
              setResetRecaptcha(true);
            })
            .finally(() => {
              onChangeRecaptchSEtDefaultValue();
            });
        }else{
          setInvalidCaptchaErr("Please enter correct captcha answer.");
          setIsSubmit(false);
        }
      } else {
        if (!isValid) {
          toast.error("Email is not valid", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        } else {
          toast.error("Name is not valid", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        setIsSubmit(false);
        setResetRecaptcha(true);
        onChangeRecaptchSEtDefaultValue();
      }
    },
  });

  const { values, errors, handleSubmit } = formik;
  useEffect(() => {
    setTimeout(() => {
      setIsSubmit(false);
    }, 1000);
  }, [formik]);

  useEffect(() => {
    if (resetRecaptcha) {
      setResetRecaptcha(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
  }, [resetRecaptcha]);
  const handleBlur = (value, phoneData) => {
    phoneData.iso2 = phoneData.countryCode;
    let contactNumber = formik.values.contact;
    if (!!contactNumber) {
      let val = contactNumber.split(" ");
      // let valArray = (val.length>0)?val[1]:"";
      let valArray = val.length > 0 ? val.slice(1).join("") : "";
      let valueData = valArray ? valArray.replace("-", "") : "";
      if (valueData == "") {
        setInvalidPhoneErr(`Please enter phone number.`);
      } else if (!isValidPhoneNumber(valueData, phoneData)) {
        setInvalidPhoneErr(`Invalid phone number for ${phoneData.name}`);
      } else {
        setInvalidPhoneErr("");
      }
    }
  };

  const handleCaptchaSolved = (solved) => {
    setIsCaptchaSolved(solved);
    setInvalidCaptchaErr("");
  };

  return (
    <>
      <div className="modal-overlay">
        <div className="modal-content innerlogin"  style={{ bottom: "5vh" }}>
          <div className="form-section">
            {/* <div className="logo"></div> */}
            {/* <h3 >
                  <Link to={`${homelocation}`} className="mainlogoalignloginreg">
                    <img src={Mainlogo} style={{ width: "50%" }} />
                  </Link>
                </h3> */}
            <div className="form-section">
              <h3 className="innercreatetitle">Create An Account</h3>
              <div className="login-inner-form">
                <form className="row g-3">
                  <>
                    <div className="col-md-12 mb-3-h">
                      <input
                        type="text"
                        className="form-control register_inputfield"
                        placeholder="Name*"
                        name="name"
                        value={values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required=""
                      />
                      {formik.touched.name && formik.errors.name ? (
                        <div id="error_msg">{errors.name}</div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mb-3-h">
                      <input
                        type="text"
                        className="form-control register_inputfield"
                        placeholder="Email*"
                        name="email"
                        value={values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required=""
                      />
                      {formik.touched.email && formik.errors.email ? (
                        <div id="error_msg">{errors.email}</div>
                      ) : null}
                    </div>
                    <div className="col-md-12 mb-3-h register_countryinput">
                      <PhoneInput
                        name="contact"
                        country={countryCode}
                        enableSearch={true}
                        countryCodeEditable={false}
                        value={values?.contact}
                        onChange={(phone, country, e, formattedValue) => {
                          formik.setFieldValue("contact", formattedValue);
                          setInvalidPhoneErr("");
                        }}
                        // onBlur={formik.handleBlur}
                        onBlur={(e, country) =>
                          handleBlur(e, country) && formik.handleBlur("contact")
                        }
                        isValid={(inputNumber, country) =>
                          isValidPhoneNumber(inputNumber, country)
                        }
                        required={true}
                        inputProps={{
                          required: "required",
                          className:
                            "form-control register_inputfield country_mobile registerCountarycodestyle",
                          placeholder: "Mobile Number*",
                        }}
                      />
                      {formik.touched.contact && formik.errors.contact ? (
                        <div id="error_msg">{errors.contact}</div>
                      ) : null}
                      {invalidPhoneErr && (
                        <div id="error_msg">{invalidPhoneErr}</div>
                      )}
                    </div>
                    <div
                      className="col-md-12 mb-3-h"
                      style={{
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control register_inputfield"
                        placeholder="Password*"
                        name="password"
                        value={values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required=""
                        style={{ flex: "1" }}
                      />
                      <div
                        onClick={togglePasswordVisibility}
                        style={{
                          cursor: "pointer",
                          alignSelf: "center",
                          position: "absolute",
                          top: "15px",
                          right: "30px",
                        }}
                      >
                        {showPassword ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </div>
                      {formik.touched.password && formik.errors.password ? (
                        <div id="error_msg">{errors.password}</div>
                      ) : null}
                    </div>
                    <div
                      className="col-md-12 mb-3-h"
                      style={{
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                      }}
                    >
                      <input
                        type={showconPassword ? "text" : "password"}
                        className="form-control register_inputfield"
                        name="confirmPassword"
                        placeholder="Confirm Password*"
                        value={values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        style={{ flex: 1 }}
                      />
                      <span
                        onClick={togglePasswordConVisibility}
                        style={{
                          cursor: "pointer",
                          alignSelf: "center",
                          position: "absolute",
                          top: "15px",
                          right: "30px",
                        }}
                      >
                        {showconPassword ? (
                          <i className="fas fa-eye"></i>
                        ) : (
                          <i className="fas fa-eye-slash"></i>
                        )}
                      </span>
                      {formik.touched.confirmPassword &&
                      formik.errors.confirmPassword ? (
                        <div id="error_msg">
                          {formik.errors.confirmPassword}
                        </div>
                      ) : null}
                    </div>
                  </>
                  <CaptchaService onCaptchaSolved={handleCaptchaSolved} />
                  {!!invalidCaptchaErr && <p className="text-danger">{invalidCaptchaErr}</p>}
                  {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                    onChange={onChangeRecaptcha}
                    className="recaptch_styled"
                  /> */}
                  <div className="col-md-12">
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      className="btn btn-primary btn-theme login_button"
                      style={{width:"100%"}}
                      // disabled={isSubmit}
                    >
                      {isSubmit ? <CircularLoader size={25} /> : "Register"}
                    </button>
                  </div>
                  {/* <ToastContainer /> */}
                </form>
              </div>

              <p className="text-center donthaveaccount">
                Already a member?
                {/* <Link to="/customer_login">
                  <b style={{ paddingLeft: "5px" }}> Login here </b>
                </Link> */}
                <button
                  onClick={() => {
                    setIsModalOpen(true);
                    setIsModalOpenRegister(false);
                  }}
                >
                  {" "}
                 <span> Login here </span>{" "}
                </button>
              </p>
            </div>
          </div>

          <button
            className="innermodal-close"
            onClick={() => setIsModalOpenRegister(false)}
          >
            Close
          </button>

          {isModalOpen && (
            <LoginSignup
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isModalOpenRegister={isModalOpenRegister}
              setIsModalOpenRegister={setIsModalOpenRegister}
              isOtpModalOpen={isOtpModalOpen}
              setIsOtpModalOpen={setIsOtpModalOpen}
              setFormwadedEmail={setFormwadedEmail}
            />
          )}
          {isOtpModalOpen && (
            <OtpPopup
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              isModalOpenRegister={isModalOpenRegister}
              setIsModalOpenRegister={setIsModalOpenRegister}
              isOtpModalOpen={isOtpModalOpen}
              setIsOtpModalOpen={setIsOtpModalOpen}
              forwardedemail={forwardedemail}
              setFormwadedEmail={setFormwadedEmail}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SignupPopup;
