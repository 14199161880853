import React, { useState, useContext, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { environmentVariables } from "../../../config/env.config";
import { userContext } from "../../../context/userContext";
import ViewThem from "./ViewThem";
import { businessObjContext } from "../../../context/businessObjContext";
import dragimg from "../../../Images/drap-icon.png";
import { useNavigate } from "react-router";
import CircularLoader from "../../Components/CircularLoader/CircularLoader";
import "./../Updatedcss/BusinessChangeBlockUpdatedStyle.css";

const ChangeBlock = ({
  businessObj,
  themeNumber,
  themeColor,
  activeTab,
  setActiveTab,
  mobilePreviewLoading,
  nextThirdStepData,
  handlenextThirdStep
}) => {
  const { userData, setUserData } = useContext(userContext);
  const baseUrl = environmentVariables?.apiUrl;
  const [items, setItems] = useState();
  const [loadingItem, setLoadingItem] = useState(false);
  //   [
  //   { id: "1", content: "Description", position: 1 },
  //   { id: "2", content: "Contact Info", position: 2 },
  //   { id: "3", content: "Appointment", position: 3 },
  //   { id: "4", content: "Business Hour", position: 4 },
  //   { id: "5", content: "Service", position: 5 },
  //   { id: "6", content: "Gallery", position: 6 },
  //   { id: "7", content: "Testimonials", position: 7 },
  //   { id: "8", content: "Social", position: 8 },
  // ]
  const navigate = useNavigate();
  const { businessObjData } = useContext(businessObjContext);
  const [businessId, setBusinessId] = useState(
    localStorage.getItem("business_id")
  );

  //state to get mobile preview of template
  const [title, setTitle] = useState("");
  // const [businessObj, setBusinessObj] = useState(null);
  const [designation, setDesignation] = useState("");
  const [googlereview, setGoogleReview] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [description, setDescription] = useState("");
  const [bannerImageUrl, setBannerImageUrl] = useState("");
  const [logoImageUrl, setLogoImageUrl] = useState("");
  const [slug, setSlug] = useState("");
  //contact info
  const [isEnabledContactInfo, setIsEnabledContactInfo] = useState(false);
  const [isEnabledContactForm, setIsEnabledContactForm] = useState(false);
  const [contactInfoPhone, setContactInfoPhone] = useState("");
  const [contactInfoEmail, setContactInfoEmail] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitbusiness, setIsSubmitbusiness] = useState(false);
  const [contactInfoWebUrl, setContactInfoWebUrl] = useState("");
  const [contactInfoAddress, setContactInfoAddress] = useState("");
  // const [businessId, setBusinessId] = useState("");

  const httpProtocol = environmentVariables?.httpProtocol;

  const [logoData, setLogoData] = useState("");
  const [bannerData, setBannerData] = useState("");
  const [onState, setOnState] = useState({
    contactInfo: false,
    services: false,
    testimonials: false,
    socials: false,
    businessHours: false,
    gallery: false,
    appointment: false,
    contactForm: false,
    googlereview:false,
    
  });
//google review

const [isEnabledGoogleReview, setIsEnabledGoogleReview] = useState(false)

  //services array
  const [servicesArray, setServicesArray] = useState([]);
  const [isEnabledServices, setIsEnabledServices] = useState(false);

  //testimonial arry
  const [testimonialArray, setTestimonialArray] = useState([]);
  const [isTestimonial, setIsTestimonial] = useState(false);

  //appointment array
  const [isAppointment, setIsAppointment] = useState(false);
  const [appointmentArray, setAppointmentArray] = useState([]);

  //social
  const [isSocial, setIsSocial] = useState(false);
  const [socialMediaData, setSocialMediaData] = useState([]);

  //business hours
  const [businessHourObject, setBusinessHourObject] = useState({});
  const [IsBusinessHourObject, setIsBusinessHourObject] = useState(false);

  //galary array
  const [gallaryObject, setGallaryObject] = useState({});
  const [isGallaryEnable, SetIsGallaryEnable] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  //useeffect to initialize all the values in mobile preview

  useEffect(() => {
    if (businessObj && businessObj?.id) {
      setBusinessId(businessObj?.id);
      setTitle(businessObj?.title || "");
      setDesignation(businessObj?.designation || "");
      setGoogleReview(businessObj?.Googlereview || businessObj?.googlereview || "");
      setSubTitle(businessObj?.sub_title || "");
      setDescription(businessObj?.description || "");
      setBannerImageUrl(businessObj?.banner || "");
      setLogoImageUrl(businessObj?.logo || "");
      setSlug(businessObj?.slug || "");
    } else {
      setBusinessId("");
      setTitle("");
      setDesignation("");
      setGoogleReview("");
      setSubTitle("");
      setDescription("");
      setBannerImageUrl("");
      setLogoImageUrl("");
      setSlug("");
    }
    let contactInfoData;
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.business_id &&
      businessObj?.contactInfo?.content
    ) {
      contactInfoData = JSON.parse(businessObj?.contactInfo?.content);
      if (contactInfoData.length > 0) {
        contactInfoData = JSON.parse(contactInfoData);
      }
    }
    if (
      contactInfoData?.contactInfoAddress ||
      contactInfoData?.contactInfoPhone ||
      contactInfoData?.contactInfoWebUrl ||
      contactInfoData?.contactInfoEmail
    ) {
      // setIsEnabledContactInfo(true);
      setContactInfoPhone(contactInfoData?.contactInfoPhone);
      setContactInfoEmail(contactInfoData?.contactInfoEmail);
      setContactInfoWebUrl(contactInfoData?.contactInfoWebUrl);
      setContactInfoAddress(contactInfoData?.contactInfoAddress);
      // console.log(contactInfoData, "contactInfoData12");
    } else {
      // setIsEnabledContactInfo(false);
      setContactInfoPhone("");
      setContactInfoEmail("");
      setContactInfoWebUrl("");
      setContactInfoAddress("");
    }
    if (
      businessObj &&
      businessObj?.gallaryObj &&
      businessObj?.gallaryObj?.is_enabled
    ) {
      SetIsGallaryEnable(true);
      setOnState({ ...onState, gallery: true });
      setGallaryObject(JSON.parse(businessObj?.gallaryObj?.content));
      setGalleryData(businessObj?.newGalleryImages);
    }
    if (
      businessObj && businessObj?.is_enabledGooglereview
    ) {
      setIsEnabledGoogleReview(true)
      setOnState({ ...onState, googlereview: true });
    }
    if (
      businessObj &&
      businessObj?.businessHourObj &&
      businessObj?.businessHourObj?.is_enabled
    ) {
      setIsBusinessHourObject(true);
      setOnState({ ...onState, businessHours: true });
      setBusinessHourObject(JSON.parse(businessObj?.businessHourObj?.content));
    }
    if (
      businessObj &&
      businessObj?.SocialMediaObj &&
      businessObj?.SocialMediaObj?.is_enabled
    ) {
      setIsSocial(true);
      setOnState({ ...onState, socials: true });
      // console.log(
      //   JSON.parse(JSON.parse(businessObj?.SocialMediaObj?.content)),
      //   "inside theme view"
      // );
      setSocialMediaData(
        JSON.parse(JSON.parse(businessObj?.SocialMediaObj?.content))
      );
    }
    if (
      businessObj &&
      businessObj?.appointmentObj &&
      businessObj?.appointmentObj?.is_enabled
    ) {
      setIsAppointment(true);
      setOnState({ ...onState, appointment: true });
      // setAppointmentArray(
      //   JSON.parse(JSON.parse(businessObj?.appointmentObj?.content))
      // );
      try {
        const parsedContent = JSON.parse(
          JSON.parse(businessObj?.appointmentObj?.content)
        );
        setAppointmentArray(parsedContent);
      } catch (error) {
        console.error("Error parsing appointment content:", error);
        // Handle the error appropriately, e.g., set a default value for `setAppointmentArray`
        setAppointmentArray([]);
      }
    } else {
      setIsAppointment(false);
    }
    if (
      businessObj &&
      businessObj?.testimonialObj &&
      businessObj?.testimonialObj?.is_enabled == 1
    ) {
      // console.log("testimonialObj2@@@@@@@@2",businessObj?.testimonialObj?.is_enabled)
      setIsTestimonial(true);
      setOnState({ ...onState, testimonials: true });
      setTestimonialArray(JSON.parse(businessObj?.testimonialObj?.content));
    } else {
      setIsTestimonial(false);
    }

    if (
      businessObj &&
      businessObj?.serviceObj &&
      businessObj?.serviceObj?.is_enabled
    ) {
      setIsEnabledServices(true);
      setOnState({
        ...onState,
        services: true,
      });
      setServicesArray((businessObj?.serviceObj?.content));
    } else {
      setIsEnabledServices(false);
    }
    if (
      businessObj &&
      businessObj?.contactInfo &&
      businessObj?.contactInfo?.is_enabled
    ) {
      setIsEnabledContactInfo(true);

      setOnState({
        ...onState,
        contactInfo: true,
      });
    } else {
      setIsEnabledContactInfo(false);
    }
    if (businessObj && businessObj?.is_contact_form_enabled) {
      setIsEnabledContactForm(true);
    } else {
      setIsEnabledContactForm(false);
    }
  }, [businessObj]);

  useEffect(() => {
    let stateObj = {};
    if (businessObj?.testimonialObj?.is_enabled) {
      stateObj["testimonials"] = true;
    }
    if (businessObj?.serviceObj?.is_enabled) {
      stateObj["services"] = true;
    }
    if (businessObj?.contactInfo?.is_enabled) {
      stateObj["contactInfo"] = true;
    }
    if (businessObj?.gallaryObj?.is_enabled) {
      stateObj["gallery"] = true;
    }
    if (businessObj?.businessHourObj?.is_enabled) {
      stateObj["businessHours"] = true;
    }
    if (businessObj?.SocialMediaObj?.is_enabled) {
      stateObj["socials"] = true;
    }
    if (businessObj?.appointmentObj?.is_enabled) {
      stateObj["appointment"] = true;
    }
    if (businessObj?.is_contact_form_enabled) {
      stateObj["contactForm"] = true;
    } 
    if (businessObj?.is_enabledGooglereview) {
      stateObj["googlereview"] = true;
    }
    setOnState(stateObj);
  }, [businessObj]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, removed);

    setItems(reorderedItems);
  };

  const [dragId, setDragId] = useState('1');
  const handleDrag = (e) => {
    setDragId(e.target.id);
  };
  const handleDrop = (e) => {
    const dropBox = items.find((val) => val?.id == e.target.id);
    const dragBox = items.find((val) => val?.id == dragId);
    // console.log(dropBox)
    const dropBoxPos = dropBox?.position;
    const dragBoxPos = dragBox?.position;
    // console.log(dropBoxPos,dragBoxPos)
    const newOrderOfItems = items.map((val) => {
      if (val?.id == dragId) {
        val.position = dropBoxPos;
      }
      if (val?.id == e.target.id) {
        val.position = dragBoxPos;
      }
      return val;
    });
    setItems(newOrderOfItems);
  };

  const handleGetBlock = async () => {
    try {
      let id = businessObjData?.id || businessId;
      let { data: get } = await axios.get(
        `${baseUrl}api/v1/business/FetchBlock?business_id=${id}`,
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );

      if (get) {
        const formattedObject = {};
        // console.log(get?.data?.theme, "getgetgetgegetg");
        for (const key in get?.data?.order) {
          const formattedKey = key
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          formattedObject[formattedKey] = {
            id: get.data.order[key],
            position: get.data.order[key],
          };
        }

        // Convert the object to an array
        const initialItems = Object.entries(formattedObject).map(([content, item]) => {
          // Check for theme53 and modify content
          const updatedContent = content === "Gallery" && get?.data?.theme === "theme53" ? "Brand Deals" : content;
          return {
            id: item.id.toString(),
            position: item.position,
            content: updatedContent,
          };
        });
        setItems(initialItems);
        setLoadingItem(false);
      }
      // console.log(get.data.order,"Getetgwef")
    } catch (err) {
      setLoadingItem(false);
    }
  };

  useEffect(() => {
    setLoadingItem(true);
    handleGetBlock();
  }, [businessObjData, businessId]);

  const handleSubmit = (e) => {
    try {
      setIsSubmitbusiness(true);
      e.preventDefault();
      let object = {};

      items.forEach((item) => {
      let contentKey = item.content === "Brand Deals" ? "gallery" : item.content;

      let key = contentKey.toLowerCase().replace(/ /g, "_");
      object[key] = item.position;
    });
      object.business_id = businessObj?.id;
      axios
        .put(`${baseUrl}api/v1/business/changeblock`, object, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          if (res?.data?.success) {
            let subdomain = res?.data?.data?.slug_subdomain;
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setIsSubmitbusiness(false);
            
            let redirectUrl = (subdomain != null)? httpProtocol + subdomain:(businessObj?.slug !=null)? "/"+ businessObj?.slug:"/";
            window.open(
              `${redirectUrl}`,
              "_blank"
            ); 
            navigate("/businesslist");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmitbusiness(false);
        });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmitbusiness(false);
      return;
    }
  };
  const handleSubmitWithoutNav = (e) => {
    try {
      setIsLoadingSubmit(true);
      e.preventDefault();
      let object = {};

      items.forEach((item) => {
        let contentKey = item.content === "Brand Deals" ? "gallery" : item.content;

      let key = contentKey.toLowerCase().replace(/ /g, "_");
      object[key] = item.position;
      });
      object.business_id = businessObj?.id;
      // console.log(object, ",,,,,,,,,,,,,,,,,,gybbgyhun");
      axios
        .put(`${baseUrl}api/v1/business/changeblock`, object, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          // console.log(res, "EEEEEEEEedit ");
          if (res?.data?.success) {
            // window.location.reload();
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            setIsLoadingSubmit(false);
            let responseData = res?.data?.data;
            let redirectUrl = (responseData?.slug_subdomain != null)? httpProtocol + responseData?.slug_subdomain:(businessObj?.slug)? "/"+ businessObj?.slug:"/";

            window.open(
              `${redirectUrl}`,
              "_blank"
            );
            // window.open(`/${businessObj?.slug}`, "_blank");
            // navigate("/dashboard");
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsLoadingSubmit(false);
        });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsLoadingSubmit(false);
      return;
    }
  };
  // console.log(
  //   JSON.parse(JSON.parse(businessObj?.appointmentObj?.content)),
  //   "items of change block"
  // );

  // const [activeTab, setActiveTab] = useState("tab1");

  const handlePreviousClick = () => {
    if (activeTab === "tab2") {
      setActiveTab("tab1");
    } else if (activeTab === "tab3") {
      setActiveTab("tab2");
    }
  };
  // const handleNextClick = () => {
  //   if (activeTab === "tab3") {
  //     setActiveTab("tab4");
  //   } 
  // };

  useEffect(()=>{
    if(!!nextThirdStepData){
      handleNextClick(null);
    }
  },[nextThirdStepData])

  const handleNextClick = (e) => {
    try {
      setIsSubmit(true);
      if (e?.preventDefault) {
        e.preventDefault();
      }
      let object = {};

      items.forEach((item) => {
        let contentKey = item.content === "Brand Deals" ? "gallery" : item.content;

      let key = contentKey.toLowerCase().replace(/ /g, "_");
      object[key] = item.position;
      });
      object.business_id = businessObj?.id;
      // console.log(object, ",,,,,,,,,,,,,,,,,,gybbgyhun");
      axios
        .put(`${baseUrl}api/v1/business/changeblock`, object, {
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((res) => {
          handlenextThirdStep()
          if (res?.data?.success) {            
            // window.location.reload();
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            let subdomain = res?.data?.data?.slug_subdomain;
            let redirectUrl = (subdomain != null)? httpProtocol + subdomain:(businessObj?.slug !=null)? "/"+ businessObj?.slug:"/";
            if(businessObjData?.theme_color != "theme53"){
              setActiveTab("tab4");
            }else{
              window.open(
                `${redirectUrl}`,
                "_blank"
              );
            }
            setIsSubmit(false);
          }
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsSubmit(false);
        });
    } catch (err) {
      handlenextThirdStep()
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setIsSubmit(false);
      return;
    }
  };


  return (
    <>
      <div className="row px-0 mb-4 ChangeBlock_Sequenceview_Wrapper">
        <div className="row ChangeBlock_Sequenceview_Container">
          <div className="col-12 col-lg-8 ChangeBlock_Container">
            <div className="tile SaveandPreviewShowBottom">
              {/* <div class="row justify-content-end px-0 mb-2">
              <button
                    className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                    type="button"
                    onClick={handlePreviousClick}>
                    Previous</button>
                <div class="col-12 col-lg-2 Changeblock_saveandpreview_buttonWrapper">
                  {businessObj &&
                    businessObj.id &&
                    (isSubmit ? (
                      <CircularLoader size={40} />
                    ) : (
                      <button
                        type="Submit"
                        className="btn btn-cu mb-4 w-100 save-cta Changeblock_saveandpreview_button"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Save & Preview
                      </button>
                    ))}
                </div>
              </div> */}

              <ul className="dragrow">
                {items ? (
                  items
                    .sort((a, b) => a.position - b.position)
                    .map((val, index) => (
                      <li
                        draggable={true}
                        onDrop={handleDrop}
                        onDragStart={handleDrag}
                        onTouchMove={handleDrag}
                        onTouchEnd={handleDrop}
                        onDragOver={(e) => e.preventDefault()}
                        id={val?.id}
                        key={index}
                      >
                        {val?.content}
                        <h3>
                          {" "}
                          <img src={dragimg} />
                        </h3>
                      </li>
                    ))
                ) : (
                  // <>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  //   <li>
                  //     <h3>
                  //       <img src={dragimg} />
                  //     </h3>
                  //   </li>
                  // </>
                  <CircularLoader />
                )}
              </ul>
              <div class="row ChangeBlock_Mobilesavepre_buttonWrapper">
                <div class="Changeblock_saveandpreview_buttonWrapper Changeblock_saveandpreview_buttonWrappertwo changeblockphonemedia">
                  <button
                    className="btn btn-cu save-cta Changeblock_saveandpreview_button changeblockphonemediabusinesspreview"
                    id="changeblockprenxtbtn"
                    type="button"
                    onClick={handlePreviousClick}
                  >
                    Previous
                  </button>
                  {/* </div>
                <div class="Changeblock_saveandpreview_buttonWrapper"> */}
                  {businessObj &&
                    businessObj.id &&
                    (isSubmitbusiness ? (
                      <CircularLoader size={40} />
                    ) : (
                      <button
                        type="Submit"
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button changeblockphonemediabusinesspreview"
                        id="changeblockprenxtbtn"
                        onClick={(e) => handleSubmit(e)}
                      >
                        Business Preview 
                      </button>
                    ))}

                    {businessObj &&
                    businessObj.id &&
                    (isSubmit ? (
                      <CircularLoader size={40} />
                    ) : (
                      <button
                        type="Submit"
                        className="btn btn-cu save-cta Changeblock_saveandpreview_button changeblockphonemediabusinesspreview"
                        id="changeblockprenxtbtn"
                        onClick={(e) => handleNextClick(e)}
                      >
                        {businessObjData?.theme_color != "theme53" ? "Save & Next" : "Save & Preview"} 
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* <ViewThem businessObj={businessObj} /> */}
          <div className="ChangeBlockView_Container">
            <ViewThem
              mobilePreviewLoading={mobilePreviewLoading}
              isLoadingSubmit={isLoadingSubmit}
              handleSubmit={handleSubmitWithoutNav}
              activeTab={activeTab}
              orderOfBlocks={items}
              themeColor={themeColor}
              businessObj={businessObj}
              title={title}
              designation={designation}
              googlereview={googlereview}
              subTitle={subTitle}
              description={description}
              bannerImageUrl={bannerImageUrl}
              logoImageUrl={logoImageUrl}
              contactInfo={{
                contactInfoAddress,
                contactInfoEmail,
                contactInfoPhone,
                contactInfoWebUrl,
              }}
              businessHourObject={businessHourObject}
              socialMediaData={socialMediaData}
              gallaryObject={gallaryObject}
              galleryData={galleryData}
              servicesArray={servicesArray}
              testimonialArray={testimonialArray}
              onState={onState}
              appointmentArray={appointmentArray}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangeBlock;