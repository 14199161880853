import React, { useState } from "react";
import { environmentVariables } from "../../../../config/env.config";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Lightbox } from "../../../Components/PortfolioPopup/PortfolioPopup";

const GalleryCarousel = ({ slides, video }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const [selectedImage, setSelectedImage] = useState(null);
  
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slides.length > 3 ? 4 : slides.length,
    slidesToScroll: 1,
    draggable: true,
    arrows: false,
  };

  const openLightbox = (index) => {
    setSelectedImage(index);
  };
  
  const closeLightbox = () => {
    setSelectedImage(null);
  };

  return (
    <div>
      <Slider {...settings}>
        {slides.map((slide, slideIndex) => {
          return (
            <img src={`${environmentVariables?.apiUrl}uploads/gallery/${slide?.image}`} alt={`Slide ${slideIndex}`} className="img-fluid" 
            onClick={() => openLightbox((currentIndex + slideIndex) % slides.length)}/>
          );
        })}
      </Slider>
        {selectedImage !== null && (
          <Lightbox
            src={`${environmentVariables?.apiUrl}uploads/gallery/${slides[selectedImage]?.image}`}
            slides={slides}
            currentIndex={selectedImage}
            onClose={closeLightbox}
            onPrevious={(index) => setSelectedImage(index)}
            onNext={(index) => setSelectedImage(index)}
          />
        )}
    </div>
  );
};

export default GalleryCarousel;
