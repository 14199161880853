import React, { useState, useEffect, useCallback } from "react";
import closeImg from "./../../../../../src/Images/close.png";
import EmptyCart from "./../../../../../src/Images/emptycart.png";
import LoginSignup from "./LoginSignup";
import OtpPopup from "./OTPPopup";
import axios from "axios";
import { toast } from "react-toastify";
import { environmentVariables } from "../../../../config/env.config";

const CartSummary = ({
  cartItems,
  closeDrawer,
  removeItem,
  openDrawer,
  closeCartSummaryDrawer,
  updatedState,
  setUpdatedState,
  handleSummaryData,
  inf_id
}) => {
  const [itemQuantities, setItemQuantities] = useState({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [forwardedemail, setFormwadedEmail] = useState("");
  const [isAppliedCoupon, setIsAppliedCoupon] = useState(false);
  const [totalAmountAfterDiscount, setTotalAmountAfterDiscount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponID, setCouponID] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenRegister, setIsModalOpenRegister] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [couponCode, setCouponCode] = useState(null);
  const [applyLoader, setApplyLoader] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;

  const [apply, setApply] = useState(false);

  useEffect(() => {
    const initialQuantities = {};
    cartItems.forEach((item, index) => {
      initialQuantities[index] = item.quantity || 1;
    });
    setItemQuantities(initialQuantities);
  }, [cartItems]);

  const increaseQuantity = (index, data) => {
    setItemQuantities((prevQuantities) => {
      const currentQuantity = prevQuantities[index] || 1;

      // Check if the quantity is already at the maximum limit
      if (currentQuantity >= 50) {
        return prevQuantities; // Return previous state without changes
      }

      // Otherwise, increment the quantity
      return {
        ...prevQuantities,
        [index]: currentQuantity + 1,
      };
    });
  };


  const decreaseQuantity = (index, data) => {
    if (itemQuantities[index] > 0) {
      setItemQuantities((prevQuantities) => ({
        ...prevQuantities,
        [index]: prevQuantities[index] - 1,
      }));
    }
  };

  useEffect(() => {
    for (let el in itemQuantities) {
      let get = cartItems.map((elem, index) => {
        if (index == el) {
          elem.quantity = itemQuantities[el];
        }
        return el;
      });
    }
  }, [itemQuantities]);
  const handleRemoveItem = (index) => {
    removeItem(index);
    setItemQuantities((prevQuantities) => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[index];
      return updatedQuantities;
    });
  };

  const calculateTotalPrice = (price, quantity) => {
    return price * quantity;
  };

  const calculateCartTotal = () => {
    let total = 0;
    cartItems.forEach((item, index) => {
      total += calculateTotalPrice(item.listing_price, itemQuantities[index] || 1);
    });
    return total;
  };

  const updateTotalAmount = (amount) => {
    setTotalAmount(amount);
  };
  useEffect(() => {
    handleApplyCouponCode();
  }, [totalAmount])

  useEffect(() => {
    updateTotalAmount(calculateCartTotal());
  }, [cartItems, itemQuantities]);
  const handleCheckout = () => {
    let getLocalStorageData = localStorage.getItem("Customer");
    if (getLocalStorageData) {
      closeCartSummaryDrawer();
      openDrawer();
      let cartSummaryData = {
        couponID: couponID,
        discountAmount: discountAmount,
        isAppliedCoupon: isAppliedCoupon,
        totalAmount: totalAmount,
        totalAmountAfterDiscount: totalAmountAfterDiscount
      }
      handleSummaryData(cartSummaryData);
    } else {
      setIsModalOpen(true);
      setIsModalOpenRegister(false);
    }
  };
  const handleCoupon = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setCouponCode(value);
    setIsAppliedCoupon(false);
    setIsAppliedCoupon(false);
  }
  const handleCouponApply = () => {
    setApply(true);
  }

  const handleApplyCouponCode = () => {
    setApplyLoader(true);
    if (!!couponCode) {
      let data = JSON.stringify({
        "code": couponCode,
        "influencer_id": inf_id
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/Influencercoupon/getInfluencerCouponByCode`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
        .then((response) => {
          setApplyLoader(false);
          let couponResponse = response.data;
          let couponData = couponResponse?.result;
          setIsAppliedCoupon(true);
          setCouponID(couponData?.id);
          let calculateDisAmount = ((totalAmount * couponData?.discount) / 100).toFixed(2);
          setDiscountAmount(calculateDisAmount);
          let totalAfterDiscount = (totalAmount - calculateDisAmount).toFixed(2);
          setTotalAmountAfterDiscount(totalAfterDiscount);
        })
        .catch((error) => {
          setApplyLoader(false);
          console.log("error===>>>", error);
          toast.error(error?.response?.data?.message || error?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setIsAppliedCoupon(false);
          setCouponID(0);
          setDiscountAmount(0);
          setTotalAmountAfterDiscount(0);
        });
    } else {
      setApplyLoader(false);
    }
  }

  return (
    <div className="cart-summary">
      <div
        onClick={closeDrawer}
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "15px",
          top: "15px",
        }}
      >
        {/* <img src={closeImg} /> */}
        <span style={{ fontSize: "24px", fontWeight: "500" }}> x</span>
      </div>

      <h3 className="theme53heading">Cart Summary</h3>
      {cartItems?.length <= 0 ? (
        <ul className="emptycartsummaryUl">
          <li className="emptycartsummary">
            <img src={EmptyCart} />
            Your cart is currently empty! <span>Looks like you haven't made your choice yet...</span>{" "}
          </li>
        </ul>
      ) : (
        <>
          <ul className="cartitemsul">
            {cartItems.map((item, index) => (
              <li key={item.id} className="cart-item" style={{ padding: "0 10px", alignItems:"flex-start" }}>
                <div className="theme53productimgcart">
                  <img
                    src={item.image}
                    alt={item.name}
                    className="item-image"
                    width="100%"
                  />
                </div>
                <div className="item-details cartprice_container">
                  <div style={{display:"flex", flexDirection:"column"}}>
                    <p className="theme53name" style={{textAlign:"left", fontSize:"18px"}}>{item.name}</p>

                    <div style={{display:"flex", alignItems:"center", gap:"10px"}}>
                      <p className="theme53price" style={{textAlign:"left"}}>
                        {item?.currencySymbol}{calculateTotalPrice(item.listing_price, itemQuantities[index] || 1).toFixed(2)}
                      </p>
                      <p className="theme53quantity">
                        <button
                          className="plusminus"
                          onClick={() => decreaseQuantity(index, item)}
                        >
                          -
                        </button>
                        {itemQuantities[index] || 1}
                        <button
                          className="plusminus"
                          onClick={() => increaseQuantity(index, item)}
                        >
                          +
                        </button>
                      </p>
                    </div>
                  </div>
                  
                </div>
                <button
                  className="closered"
                  onClick={() => handleRemoveItem(index)}
                >
                  X
                </button>
              </li>
            ))}
          </ul>
          <div className="subtotal_cart subtotal_cartcontainer">
            <p >
              <span style={{width:"100%", position:"relative"}}>
                <input className="applyCodeInput" type="text" name="coupon-code" placeholder="Enter coupon code" value={couponCode} onChange={(e) => handleCoupon(e)} />
                <button className="applyCodeBtn" type="button" onClick={() => {
                  handleApplyCouponCode();
                }}>{isAppliedCoupon ? 'Applied' : 'Apply'}</button>
              </span>
            </p>
            
            <p >Subtotal: <span>{cartItems?.[0]?.currencySymbol}{totalAmount.toFixed(2)}</span></p>
             {!!isAppliedCoupon && <p style={{color:"green"}}>Item Discount <span>{`- ${cartItems?.[0]?.currencySymbol + discountAmount}`}</span></p>}
            <p style={{fontSize:"22px", color:"#797979"}}>Total:<span>{cartItems?.[0]?.currencySymbol}{isAppliedCoupon ? totalAmountAfterDiscount : totalAmount.toFixed(2)}</span></p>
          </div>
        </>
      )}

      {/* <button onClick={closeDrawer}>Close</button> */}

      {cartItems?.length <= 0 ? (
        <button className="checkout53" onClick={closeDrawer}>
          Add Products
        </button>
      ) : (
        <button className="checkout53" onClick={() => handleCheckout()}>
          Checkout
        </button>
      )
      }
      {(isModalOpen || isModalOpenRegister) && (
        <LoginSignup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          updatedState={updatedState}
          setUpdatedState={setUpdatedState}
          setFormwadedEmail={setFormwadedEmail}
        />
      )}
      {isOtpModalOpen && (
        <OtpPopup
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          isModalOpenRegister={isModalOpenRegister}
          setIsModalOpenRegister={setIsModalOpenRegister}
          isOtpModalOpen={isOtpModalOpen}
          setIsOtpModalOpen={setIsOtpModalOpen}
          forwardedemail={forwardedemail}
          setFormwadedEmail={setFormwadedEmail}
        />
      )}
    </div>
  );
};

export default CartSummary;
