import { React, useEffect, useState } from 'react';
import { useFormik } from "formik";
import PhoneInput from "react-phone-input-2";
import { ToastContainer, toast } from "react-toastify";
import { environmentVariables } from "../../../../config/env.config";
import axios from "axios";
import * as Yup from 'yup';
import CircularLoader from '../../../Components/CircularLoader/CircularLoader';
import { getcoutryfromlocation } from '../../../../utils/utility';

export const HireForm = ({ handleClose, active, props }) => {
  const baseUrl = environmentVariables?.apiUrl;
  const [countryCode, setCountryCode] = useState("in");
  
  const fetchCountryfromlocation = async () => {
    try {
      const countryData = await getcoutryfromlocation();
      console.log("countryData-==>",countryData);
      setCountryCode(countryData.toLowerCase());
    } catch (error) {
      console.error("Error fetching country data:", error);
    }
  };

  useEffect(() => {
    fetchCountryfromlocation()
  }, []);
 const [isSubmitForm, setIsSubmitForm] = useState(false) 
  // Contact form validation schema
  const contactFormSchema = Yup.object().shape({
    name: Yup.string()
      .max(25, "Name must be 25 characters or less") 
      .matches(/^[A-Za-z -]+$/, "Name can only contain letters, spaces, and dashes")
      .required("Name is required"),

    email: Yup.string()
      .email("Please provide a valid email address")
      .required("Email is required"),

    contactNumber: Yup.string()
      .matches(
        /^\+?\d{1,4}?[-.\s]?\(?\d+\)?[-.\s]?\d+$/, 
        "Please provide a valid phone number"
      )
      .required("Contact number is required"),

    message: Yup.string()
      .min(10, "Message must be at least 10 characters long") 
      .required("Message is required"), 
  });

  // Initial form values
  const initialValues = {
    name: "",
    email: "",
    contactNumber: "",
    message: "",
    countryCode: countryCode,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: contactFormSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsSubmitForm(true)
      const { contactNumber, message, name, email } = values;
      const [dialCode, phone] = contactNumber.split("-");

      try {
        const config = {
          method: "post",
          url: `${baseUrl}api/v1/influencerhireme/hiremerequest`,
          withCredentials: true,
          data: {
            business_id: props.id,
            name,
            email,
            dialcode: dialCode,
            phone,
            message,
            influencerName: props?.rs?.sub_title,
            // createdBy: props?.rs?.created_by,
          },
        };

        const response = await axios.request(config);
        toast.success("Thank you for contacting us!");
        setIsSubmitForm(false)
        resetForm();
        handleClose();
      } catch (error) {
        toast.error(error?.response?.data?.message || error?.message);
        setIsSubmitForm(false)
      }
    },
  });

  const { values, errors, handleSubmit, handleChange, handleBlur, touched } = formik;

  return (
    <div className='hire-form' style={{ bottom: active ? "50px" : "-100%" }}>
      <article className="contactform sec">
        <h3 className="sectitle">HIRE ME</h3>
        <p className="cross">
          <i onClick={handleClose} className="fa-solid fa-x"></i>
        </p>
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="col-12 mb-3">
              <input
                maxLength='25'
                type="text"
                className="form-control clear_string"
                placeholder="Full Name"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {touched.name && errors.name && (
                <div style={{ fontSize: "14px", color: "red" }}>
                  {errors.name}
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
              {touched.email && errors.email && (
                <div style={{ fontSize: "14px", color: "red" }}>
                  {errors.email}
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <PhoneInput
                name="contactNumber"
                value={values.contactNumber}
                country={values.countryCode}
                countryCodeEditable={false}
                onChange={(value, country) => {
                  formik.setFieldValue("contactNumber", `+${country.dialCode}-${value.substring(country.dialCode.length)}`);
                  formik.setFieldValue("countryCode", country.dialCode);
                }}
                className="DetailsInfoPhoneInput"
              />
              {touched.contactNumber && errors.contactNumber && (
                <div style={{ fontSize: "14px", color: "red" }}>
                  {errors.contactNumber}
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
              <textarea
                className="form-control clear_string"
                rows="3"
                placeholder="Your message goes here..."
                name="message"  
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              ></textarea>
              {touched.message && errors.message && (
                <div style={{ fontSize: "14px", color: "red" }}>
                  {errors.message}
                </div>
              )}
            </div>
            <div className="col-12 mb-3">
            {isSubmitForm ? (
                    <button className="btn btn-submitform">
                      <CircularLoader size={20}/>
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-submitform">
                      Submit
                     
                    </button>)}
            </div>
          </form>
        </div>
        <ToastContainer />
      </article>
    </div>
  );
};
