
export const initializeFacebookPixel = (pixelId) => {
    if (!pixelId) {
      console.error("Facebook Pixel ID is required.");
      return;
    }
  
    const existingScript = document.getElementById("facebook-pixel");
  
    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "facebook-pixel";
      script.async = true;
      script.src = `https://connect.facebook.net/en_US/fbevents.js`;
      document.head.appendChild(script);
  
      script.onload = () => {
        if (window.fbq) {
          console.log("Facebook Pixel script loaded.");
          window.fbq("init", pixelId);
          console.log(`Facebook Pixel initialized with ID: ${pixelId}`);
          window.fbq("track", "PageView");
          console.log("PageView event tracked.");
        } else {
          console.error("Facebook Pixel script failed to initialize.");
        }
      };
  
      window.fbq =
        window.fbq ||
        function () {
          window.fbq.callMethod
            ? window.fbq.callMethod.apply(window.fbq, arguments)
            : window.fbq.queue.push(arguments);
        };
  
      window.fbq.queue = [];
      window.fbq.version = "2.0";
      window.fbq.loaded = true;
      window.fbq.push = window.fbq;
    } else {
      if (window.fbq) {
        console.log("Facebook Pixel script already loaded.");
        window.fbq("track", "PageView");
        console.log("PageView event tracked.");
      } else {
        console.error("Facebook Pixel script is missing.");
      }
    }
  };
  