import React, { useContext, useEffect, useRef, useState } from "react";
import Sidebar from "./Sidebar";
import logoDark from "../../Images/logo-dark.png";
import Whitelogo from "../../Images/whitelogo.png";
import { userContext } from "../../context/userContext";
import ThemTemplate from "../Pages/BusinessPages/ThemTemplate";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import { environmentVariables } from "../../config/env.config";
// import io, { socketIOClient } from "socket.io-client";
import Pusher from "pusher-js";
import {
  businessContext,
  isBusinesCreatedContext,
} from "../../context/businessContext";
import { businessObjContext } from "../../context/businessObjContext";
import CircularLoader from "../../Backend/Components/CircularLoader/CircularLoader";
import {
  currencyArrayContext,
  currencyContext,
} from "../../context/currencyContext";
import "../Pages/Updatedcss/HeaderUpdatedStyle.css";
import InfiniteScroll from 'react-infinite-scroll-component';
// const socket = io.connect(`${environmentVariables?.apiUrl}`);
function Header({
  businessId,
  setBusinessId,
  isChangeDynamicBusinessName,
  setDynamicBusinessName,
  dynamicBusinessName,
}) {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef(null);
  const { userData, setUserData } = useContext(userContext);
  const [isNewNotifications, setIsNewNotifications] = useState(false);
  const [isNotificationPopUp, setIsNotificationPopUp] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [notificationsData, setNotificationsData] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [selectThemeColor, setSelectThemeColor] = useState("");
  const baseUrl = environmentVariables?.apiUrl;

  const [isBusiness, setIsBusiness] = useState(false);
  const [ businessData, setBusinessData ] = useState('');
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const { currencyArrayData, setCurrencyArrayData } =
    useContext(currencyArrayContext);
  const [isStatus, setIsStatus] = useState(false);
  const { businessObjData, setBusinessObjData } =
    useContext(businessObjContext);
  const { isBusinessCreated, setIsBusinessCreated } = useContext(
    isBusinesCreatedContext
  );
  const [planName, setPlanName] = useState("");
  const [dynamicCurrencyName, setDynamicCurrencyName] = useState("");
  const [userPermission, setUserPermission] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // let fetchLocalObbj = localStorage.getItem("physicalCardTempObj");
  const location = useLocation();
  const prevHeaderIdRef = useRef({});
  const prevIdRef1 = useRef({});
  // const socket = io.connect(`${environmentVariables?.apiUrl}`);
  // const socket = io.connect(`${environmentVariables?.apiUrl}`);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);

  useEffect(() => {
    // Initial fetch to populate the data
    fetchMoreData();
  }, []);

  const fetchMoreData = () => {
    fetch(`${environmentVariables?.apiUrl}api/v1/business/fetchBusiness?page=${page}`, {
      headers: {
        _token: userData?.token,
      }
    })
      .then(response => response.json())
      .then(responseData => {
        const newData = Array.isArray(responseData.data) ? responseData.data : []; // Extract the data array from the response
        setBusinessData(prevData => [...prevData, ...newData]);
        setPage(prevPage => prevPage + 1);
        if (newData.length === 0) {
          setHasMore(false); // If no more data, set hasMore to false
        }
      })
      .catch(error => console.error(error));
  };
  

  function convertNotificationTime(timestamp) {
    const currentDate = new Date();
    const targetDate = new Date(timestamp);
    const timeDifference = currentDate - targetDate;
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    if (seconds < 60) {
      return seconds + (seconds === 1 ? " second ago" : " seconds ago");
    } else if (minutes < 60) {
      return minutes + (minutes === 1 ? " minute ago" : " minutes ago");
    } else if (hours < 24) {
      return hours + (hours === 1 ? " hour ago" : " hours ago");
    } else {
      // If the time difference is more than 24 hours, return the formatted date
      const options = { year: "numeric", month: "long", day: "numeric" };
      return targetDate.toLocaleDateString("en-US", options);
    }
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const logo = windowWidth > 768 ? logoDark : Whitelogo;

  useEffect(() => {
    if (userData) {
      setUserPermission(userData?.permission);
    }
  }, [userData]);
  // useEffect(() => {
  //   // socket.emit("join_room", userData?.id);
  //   socket.on("show_notification_appointment", (data) => {
  //     setIsNewNotifications(true);
  //     getNotificationData();
  //   });
  //   socket.on("show_notification_contact", (data) => {
  //     setIsNewNotifications(true);
  //     getNotificationData();
  //   });
  // }, [socket]);
  const changeNotificationStatus = () => {
    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/notifications/change_notification_status`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        setIsNewNotifications(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getNotificationData = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${environmentVariables?.apiUrl}api/v1/notifications/get_notifications_by_user`,
      headers: {
        _token: userData?.token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        setNotificationsData(response?.data?.data);
        if (response?.data?.data && response?.data?.data.length > 0) {
          setIsNewNotifications(response?.data?.isNew);
          // console.log(response?.data?.isNew, "response?.data?.isNew");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getPlanName = () => {
    axios
      .get(`${baseUrl}api/v1/plan/fetchPlanByIdForUser`, {
        params: { planId: userData?.plan },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        setPlanName(res?.data?.result?.name);
      })
      .catch((err) => {
        console.log({ err: err });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPlanName();
    getNotificationData();
  }, []);

  const handleChangePayCurrency1 = async (currencyName) => {
    try {
      let obj = {
        pay_currency: currencyName,
      };
      let SelectedTab = "";
      let SelectedTitle = "";
      let SelectedDesignation = "";
      let Selectedid = "";
      if (location?.pathname?.includes("ordernow")) {
        const params = new URLSearchParams(location.search);
        SelectedTab = params.get("card");
        SelectedTitle = params.get("title");
        SelectedDesignation = params.get("desig");
        Selectedid = params.get("businessId");
        localStorage.setItem(
          "physicalCardTempObj",
          JSON.stringify({
            SelectedTab: params.get("card"),
            SelectedTitle: params.get("title"),
            SelectedDesignation: params.get("desig"),
            Selectedid: params.get("businessId"),
          })
        );
      }
      await axios.put(`${baseUrl}api/v1/user/updateUserPayCurrency`, obj, {
        headers: {
          _token: userData?.token,
        },
      });

      if (location?.pathname?.includes("ordernow")) {
        navigate(
          `/ordernow?card=${SelectedTab}&title=${SelectedTitle}&desig=${SelectedDesignation}&businessId=${Selectedid}`
        );
      }
    } catch (err) {
      console.log(err, "Erro in");
    }
  };

  const handleChangePayCurrency = async (currencyName) => {
    try {
      let obj = {
        pay_currency: currencyName,
      };
      await axios.put(`${baseUrl}api/v1/user/updateUserPayCurrency`, obj, {
        headers: {
          _token: userData?.token,
        },
      });
    } catch (err) {
      console.log(err, "Erro in");
    }
  };

  const handleFetchActiveCurrency = async () => {
    try {
      let mobileCode = userData.country_code || '+91';
      const encodedCountryCode = encodeURIComponent(mobileCode);
      
      let { data: res } = await axios.get(
        // `${baseUrl}api/v1/currency/fetchAllActiveCurrency`,
        `${baseUrl}api/v1/currency/fetchAllActiveCurrency?mobile_code=${encodedCountryCode}`,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      const transformedData = res.data[0];
      
      setCurrencyObjData(transformedData)
      // setCurrencyObjData(res?.data)
      if (res && res?.status) {
        if (userData && userData?.pay_currency) {
          setDynamicCurrencyName(userData?.pay_currency);
          let findCurrency = res?.data.find(
            (el) => el.name == userData?.pay_currency
          );
          // console.log(findCurrency,"findCurrency1")
          // setCurrencyObjData(findCurrency);
        } else {
          setDynamicCurrencyName(res?.data[0]?.name);
          handleChangePayCurrency(res?.data[0]?.name);
        }
        setCurrencyArrayData(res?.data);
      }
    } catch (err) {
      console.log(err, "err in fetching currency");
    }
  };

  const handleFetchById = (id) => {
    console.log("in header.js L307");
    
    try {
      if (id != "undefined") {
        axios
          .get(
            `${environmentVariables?.apiUrl}api/v1/business/fetchBusinessById?id=${id}`,
            {
              headers: {
                _token: userData?.token,
              },
            }
          )
          .then((res) => {
            if (res?.data?.success) {
              localStorage.setItem(
                "businessObj",
                JSON.stringify(res?.data?.data)
              );
              setDynamicBusinessName(res?.data?.data?.title);
              setBusinessObjData(res?.data?.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
      return;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("id") && params.get("id") !== prevIdRef1.current) {
      prevIdRef1.current = params.get("id");
      handleFetchById(params.get("id"), "C203");
      setBusinessId(params.get("id"));
      localStorage.setItem("business_id", params.get("id"));
    }
    
    if (!!businessId) {
      prevIdRef1.current = businessId;
      handleFetchById(businessId, "C209");
    }

    // }, [location.search, businessId]);
  }, [location.search, businessId, isChangeDynamicBusinessName]);

  useEffect(() => {
    handleFetchActiveCurrency();
  }, []);

  useEffect(() => {
    if (isBusinessCreated && isBusinessCreated?.businessName) {
    }
  }, [isBusinessCreated]);

  const handleClick = (event) => {
    document?.body?.classList.toggle("sidenav-toggled");
  };

  // const handleLogout = () => {
  //   localStorage.clear();
  //   setUserData(null);
  // };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}api/v1/user/logout`,
        null,
        {
          headers: {
            _token: userData?.token,
          },
        }
      );
      const { success, message } = response.data;
      localStorage.clear();
      Cookies.remove('access_token', {
        domain: "localhost",
        path: "/",
      })
      setUserData(null);
      // Check if logout was successful
      if (success) {
        // Clear local storage and user data
        localStorage.clear();
        setUserData(null);
        navigate('/login');
      } else {
        console.error(message);
        localStorage.clear();
        setUserData(null);
        navigate('/login');
      }
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleSubmit = (e) => {
    try {
      e.preventDefault();
      setIsStatus(true);
      if (businessName == "" || businessName?.trim() == "") {
        toast.error("Please provide Business Name", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsStatus(false);
        return;
      } else if (selectedTheme == "" || selectedTheme?.trim() == "") {
        toast.error("Theme is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsStatus(false);
        return;
      } else if (selectThemeColor == "" || selectThemeColor?.trim() == "") {
        toast.error("Theme color is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
        setIsStatus(false);
        return;
      }
      let obj = {
        business_title: businessName,
        theme: selectedTheme,
        theme_color: selectThemeColor + `-${selectedTheme}`,
      };
      axios
        .post(`${baseUrl}api/v1/business/create`, obj, {
          headers: {
            _token: userData?.token,
          },
        })
        .then((res) => {
          setIsStatus(false);
          if (res?.data?.success) {
            setIsBusiness(!isBusiness);
            window.location.reload();
            toast.success(res?.data?.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });
            navigate("/dashboard");
          }
        })
        .catch((err) => {
          setIsStatus(false);
          toast.error(err?.response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        });
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }
  };

  const handleChangeCurrency = (obj) => {
    try {
      setDynamicCurrencyName(obj?.name);
      setCurrencyObjData(obj);
      handleChangePayCurrency(obj?.name);
    } catch (err) {}
  };

  const handleChangeBusiness = (businessName) => {
    try {
      localStorage.setItem("business_id", businessName.id);
      setDynamicBusinessName(businessName?.title);
      setBusinessId(businessName?.id);
      if (businessName?.id && businessName?.id !== prevIdRef1.current) {
        prevIdRef1.current = businessName?.id;
        handleFetchById(businessName?.id, "C312");
      }
    } catch (err) {
      toast.error(err?.response?.data?.message, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  const MenuBarHandler = () => {};

  const handleCreateBusinessClick = () => {};

  const handleToggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  useEffect(() => {
    Pusher.logToConsole = true;
    const pusher = new Pusher("cbb68a6fad0862e7fd60", {
      cluster: "ap2",
    });
    const channelAppoinments = pusher.subscribe(`MakeAppoinments-${userData?.id}`);
    const channelContacts = pusher.subscribe(`MakeContacts-${userData?.id}`);
    const channelInfluencerOrder = pusher.subscribe(`createOrder-${userData?.id}`);
    const channelPaymentRequest = pusher.subscribe(`paymRequest-${userData?.id}`);
    const channelPaymentApprovel = pusher.subscribe(`payStatus-${userData?.id}`);
    const channelExpirationAfter = pusher.subscribe(`after-expiration-${userData?.id}`);
    const channelExpirationBefore = pusher.subscribe(`before-expiration-${userData?.id}`);
    const handleMyEventAppoinment = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    const handleMyEventContact = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    const handleMyEventOrder = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    const handleMyEventPaymentRequest = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    const handleMyEventPaymentApprovel = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    const handleAfterExpiation = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    const handleBeforeExpiation = (receivedData) => {
      setNotificationsData([receivedData?.message, ...notificationsData]);
      setIsNewNotifications(true);
    };
    channelAppoinments.bind("create", handleMyEventAppoinment);
    channelContacts.bind("create", handleMyEventContact);
    channelInfluencerOrder.bind("create",handleMyEventOrder),
    channelPaymentRequest.bind("create",handleMyEventPaymentRequest),
    channelPaymentApprovel.bind("create",handleMyEventPaymentApprovel),
    channelExpirationAfter.bind("after-expiration", handleAfterExpiation);
    channelExpirationBefore.bind("before-expiration", handleBeforeExpiation);
    return () => {
      channelAppoinments.unbind("create", handleMyEventAppoinment);
      channelContacts.unbind("create", handleMyEventContact);
      channelInfluencerOrder.unbind("create",handleMyEventOrder),
      channelPaymentRequest.unbind("create",handleMyEventPaymentRequest),
      channelPaymentApprovel.unbind("create",handleMyEventPaymentApprovel),
      channelExpirationAfter.unbind("after-expiration", handleAfterExpiation);
      channelExpirationBefore.unbind("before-expiration", handleBeforeExpiation);
      pusher.unsubscribe(`MakeAppoinments-${userData?.id}`);
      pusher.unsubscribe(`MakeContacts-${userData?.id}`);
      pusher.unsubscribe(`createOrder-${userData?.id}`);
      pusher.unsubscribe(`paymRequest-${userData?.id}`);
      pusher.unsubscribe(`payStatus-${userData?.id}`);
      pusher.unsubscribe(`after-expiration-${userData?.id}`);
      pusher.unsubscribe(`before-expiration-${userData?.id}`);
    };
  }, [notificationsData]);

  const handleClickOutside = (event) => {
    if (notificationRef.current && !notificationRef.current.contains(event.target)) {
      setIsNotificationPopUp(false);
    }
  };  

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  },[isNotificationPopUp]);

  const handleOpenNotification = ()=>{
    setIsNotificationPopUp(!isNotificationPopUp);
    changeNotificationStatus();
  }

  
  

  return (
    <>
      <header className="app-header">
        {userData.user_type == 'super_admin'?
        <Link
          to="/super_dashboard"
          className="app-header__logo HeaderlogoClass"
          href="index.html"
        >
          <img src={logo} style={{width:"156px"}}/>
        </Link>
        :
        <Link
          to="/dashboard"
          className="app-header__logo HeaderlogoClass"
          href="index.html"
        >
          <img src={logo} style={{width:"156px"}}/>
        </Link>
        }
        <div
          onClick={handleClick}
          className="app-sidebar__toggle"
          data-toggle="sidebar"
          aria-label="Hide Sidebar"
        ></div>
        <ul className="app-nav" id="MobileBaarview">
          <li className="app-nav__item d-none">
            <span className="plan_name">{planName}</span>
          </li>
          <li className="notificationbell">
            <div className="notificationbellcontainer">
              <i
                onClick={() => {
                  handleOpenNotification()
                }}
                className="fa fa-light fa-bell"
                ref={notificationRef}
              ></i>

              {isNewNotifications == true ? (
                <div className="notification-red-dot"></div>
              ) : (
                <></>
              )}
              {isNotificationPopUp ? (
                <div className="all-notifications-container">
                  {notificationsData &&
                    notificationsData?.length > 0 &&
                    notificationsData
                      .sort((a, b) => {
                        // Assuming both created_at and updated_at are in ISO format, you can use Date.parse
                        const timeA = Date.parse(a.updated_at || a.created_at);
                        const timeB = Date.parse(b.updated_at || b.created_at);

                        // Sort in descending order
                        return timeB - timeA;
                      })
                      .map((val) => (
                        <div
                          className="notifications-content"
                          style={
                            val?.is_new == "1"
                              ? {
                                  background: "white",
                                }
                              : {}
                          }
                          onClick={() => {
                            if (val?.notification_type == "appointment") {
                              navigate("/appoinments");
                            } else if (val?.notification_type == "contact") {
                              navigate("/contact");
                            }
                            setIsNotificationPopUp(false);
                          }}
                        >
                          <div>
                            {val?.notification_text?.replace("and", "an")}
                          </div>
                          <div className="notification-time">
                            {convertNotificationTime(val?.created_at)}
                          </div>
                        </div>
                      ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </li>
          {/* <li className="dropdown midmenu " id="dropdown_midmenu_mobile_view">
            {dynamicCurrencyName && dynamicCurrencyName.length ? (
              <div
                className="app-nav__item btnhead"
                data-bs-toggle="dropdown"
                aria-label="Open Profile Menu"
              >
                {dynamicCurrencyName}
              </div>
            ) : (
              <div></div>
            )}

            {currencyArrayData && (
              <ul className="dropdown-menu settings-menu dropdown-menu-right">
                {currencyArrayData &&
                  currencyArrayData.length &&
                  currencyArrayData.map((ele, index) => {
                    return (
                      <li key={index}>
                        <Link
                          onClick={() => handleChangeCurrency(ele)}
                          className="dropdown-item"
                        >
                          {ele?.name}
                        </Link>
                      </li>
                    );
                  })}
              </ul>
            )}
          </li> */}
          {userData && userData?.created_by != 0 ? (
            userPermission &&
            userPermission.length &&
            userPermission.some((obj) =>
              obj.permissionobj?.name?.includes("manage business")
            ) ? (
              <Link
                to={`/business?create=1`}
                className="app-nav__item btnhead"
                onClick={handleCreateBusinessClick}
              >
                + Create Business
              </Link>
            ) : (
              ""
            )
          ) : (
            <>
              <Link
                to={`/business?create=1`}
                className="app-nav__item btnhead HeaderCreateBusinessClass"
                onClick={handleCreateBusinessClick}
              >
                + Create Business
              </Link>
            </>
          )}
          {userData && userData?.created_by != 0 ? (
            userPermission &&
            userPermission.length &&
            userPermission.some((el) =>
              el?.permissionobj?.name?.includes("manage business")
            ) ? (
              <li
                className="dropdown midmenu "
                id="dropdown_midmenu_mobile_view"
              >
                {dynamicBusinessName && dynamicBusinessName.length > 0 ? (
                  <div
                    className="app-nav__item btnhead BusinList"
                    data-bs-toggle="dropdown"
                    aria-label="Open Profile Menu"
                  >
                    {dynamicBusinessName}
                  </div>
                ) : (
                  <div></div>
                )}
                {businessData && businessData?.length > 0 && (
                  <ul className="dropdown-menu settings-menu dropdown-menu-right header_business_list_dropdown">
                    {businessData.map((ele, index) => {
                      return (
                        <li key={index}>
                          <Link
                            to={`/business?id=${ele.id}`}
                            onClick={() => handleChangeBusiness(ele)}
                            className="dropdown-item BusLi"
                          >
                            {ele?.title}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </li>
            ) : (
              ""
            )
          ) : (
            <li className="dropdown midmenu " id="dropdown_midmenu_mobile_view">
              {dynamicBusinessName && dynamicBusinessName.length ? (
                <div
                  className="app-nav__item btnhead BusinList"
                  data-bs-toggle="dropdown"
                  aria-label="Open Profile Menu"
                >
                  {dynamicBusinessName}
                </div>
              ) : (
                <div></div>
              )}

              <div>
              {businessData && businessData?.length > 0 && (
                <InfiniteScroll
                dataLength={businessData.length}
                next={fetchMoreData}
                hasMore={hasMore}
                // loader={<h4>Loading...</h4>} 
                // height={200}  
                // endMessage={<p style={{ textAlign: 'center' }}>No more data</p>}
              >
                  <ul className="dropdown-menu settings-menu dropdown-menu-right header_business_list_dropdown">
                  {businessData.map((ele, index) => {
                    return (
                      <li key={index}>
                        <Link
                          to={`/business?id=${ele.id}`}
                          onClick={() => handleChangeBusiness(ele)}
                          className="dropdown-item BusLi"
                        >
                          {ele?.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                </InfiniteScroll>
              )}
              </div>
            </li>
          )}
          {/* End Business Header Menu */}
          <div className="Header_menubarstyle" onClick={handleToggleSidebar}>
            <i className="fa fa-bars" aria-hidden="true"></i>
          </div>
          <li className="dropdown" id="dropdown_midmenu_mobile_view">
            <div
              className="app-nav__item btnhead profilenmae"
              data-bs-toggle="dropdown"
              aria-label="Open Profile Menu"
            >
              <i className="bi bi-person-fill fs-4 d-none"></i>Hi,
              <span>{userData?.name}</span>
            </div>
            <ul className="dropdown-menu settings-menu dropdown-menu-right">
              <li>
                <Link to="/profile" className="dropdown-item">
                  <i className="bi bi-person-fill me-2 fs-5"></i> Profile
                </Link>
              </li>
              <li>
                <Link to="/" onClick={handleLogout} className="dropdown-item">
                  <i className="bi bi-box-arrow-right me-2 fs-5"></i> Logout
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <Sidebar
          isSidebarVisible={isSidebarVisible}
          handleToggleSidebar={handleToggleSidebar}
        />
      </header>
      <div
        className={`modal fade ${isBusiness ? "show" : ""}`}
        id="createBusinessModal"
        data-bs-backdrop={isBusiness ? "static" : true}
        data-bs-keyboard={isBusiness ? "false" : true}
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden={!isBusiness}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="staticBackdropLabel">
                Create New Business
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Business
              <input
                type="text"
                className="form-control mb-2"
                onChange={(e) => setBusinessName(e.target.value)}
              ></input>
              <div className="row mt-3">
                <ThemTemplate
                  setSelectedTheme={setSelectedTheme}
                  selectedTheme={selectedTheme}
                  setSelectThemeColor={setSelectThemeColor}
                  isNewTheme={true}
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => handleSubmit(e)}
              >
                {isStatus ? <CircularLoader size={25} /> : "Create"}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default Header;
//1212
