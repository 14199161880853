import React, { useContext, useEffect, useState, useCallback } from "react";
import "./Style.css";
import PageName from "../Utils/PageName";
import axios from "axios";
import { userContext } from "../../context/userContext";
import { environmentVariables } from "../../config/env.config";
import "./Dstyles.css";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";

function InfluencersDetails() {
  const apiUrl = environmentVariables?.apiUrl;
  const { userData } = useContext(userContext);
  const [influencerData, setInfluencerData] = useState(null);
  const [infBusinessLink, setInfBusinessLink] = useState("");
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [paginationTotalRows, setPaginationTotalRows] = useState(0);
  const [paginationPerPage, setPaginationPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [percentage, setPercentage] = useState(0);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState([]);
  const [action] = useState({ fromUser: false });
  const [claimAmount, setClaimAmount] = useState();
  const [name, setName] = useState();
  const [currencySymbol, setCurrencySymbol] = useState("");
  const url = useLocation();
  const searchParams = new URLSearchParams(url.search);
  const id = searchParams.get('id');
  const httpProtocol = environmentVariables?.httpProtocol;
  const fronturl = environmentVariables?.frntUrl;
console.log(id,"^&*");


const getInfluenderDetails = () =>{
  axios.get(`${apiUrl}api/v1/influencer/getInfluencerById?id=${id}`)
  .then((response)=>{
    setName(response?.data?.result)
    const businessData = response?.data?.result?.businesses;
    const slugb = businessData?.slug;
    const slug_subdomain = businessData?.slug_subdomain
    let bsLink = (slug_subdomain != null)? httpProtocol +slug_subdomain:(slugb !=null)? fronturl+ slugb:"/";
    setInfBusinessLink(bsLink);
  }).catch((err)=>{
    console.log(err);
  })
}

useEffect(()=>{
  getInfluenderDetails();
},[])



  useEffect(() => {
    let config = {
      method: "get",
      url: `${apiUrl}api/v1/influencer/getInfluencerOrderSummary`,
      headers: {
        _token: userData?.token,
      },
    };
    axios
      .request(config)
      .then((response) => {
        console.log(response?.data?.result,"#*JF");
        
        // setInfluencerData(response?.data?.result);
      })
      .catch((error) => {
        console.log(error);
        if (error?.response?.status) {
          localStorage.clear();
          window.location.href = "/";
          window.location.reload();
        }
      });
  }, []);


  const fetchInfluencers = (influencerId) => {
    axios
      .get(`${apiUrl}api/v1/influencer/getInfluencerOrderSummaryByInfluencerId`, {
        params: { id: influencerId },
        headers: {
          _token: userData?.token,
        },
      })
      .then((res) => {
        if (res?.status === 200) {
          console.log(res?.data?.result,"rf565rf")
          setInfluencerData(res?.data?.result)
          setCurrencySymbol(res?.data?.result?.currencyDataObj?.symbol);
          setPercentage(res?.data?.result?.percentage);
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
        } else {
          console.error("error:", error);
        }
      });
  };
  
  useEffect(() => {
    // Get the id from the URL query string
    const urlParams = new URLSearchParams(window.location.search);
    const influencerId = urlParams.get('id'); 
console.log(urlParams,"urlParams0o0" , influencerId);

    if (influencerId) {
      fetchInfluencers(influencerId); // Pass the id to the fetch function
    } else {
      console.error("Influencer ID not found in URL");
    }
  }, []);

  const tableExtensions = { export: false, print: false };

  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        padding: "25px",
        border: "none",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "58px !important",
      },
    },
  };

  const columns = [
    {
      name: "Name",
      selector: row=>row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: row=>row.email,
      sortable: true,
    },
    {
      name: "Contact",
      selector: row => {
        const countryCode = row.country_code || '';
        const contact = row.contact || ''; 
        return countryCode && contact ? `${countryCode} ${contact}` : contact;
      },
      sortable: true,
    },
    {
      name: "Business Name",
      selector: row => row.businesses && row.businesses.length > 0 ? row.businesses[0].title : "No Business",
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => (
          <i
            key={`view_${d.id}`}
            // onClick={() => handleviewClick(d.slug, d.slug_subdomain)}
            className="first fas fa-eye"
          ></i>
      ),
    },
  ];

  const tableData = {
    columns,
    data,
  };

  const handleOnSelectedRowsChange = useCallback(
    ({ selectedRows }) => {
      if (!action.fromUser) return; //the component always trigger this with 0 selected rows when it renders a page, what would clear the selection

      selectedRowsPerPage[currentPage] = selectedRows; //there is no way to tell if a row was DEselected, so I had to control the selected rows per page,
      //the array would get an index to control each page
      console.log(JSON.stringify(selectedRowsPerPage));
    },
    [currentPage, selectedRowsPerPage, action.fromUser]
  );

  const handlePageChange = (page) => {
    const nextPage = page; 
    MainData(nextPage);
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = async (newRowsPerPage) => {
    if (!businesslistData.length) return;
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); 
    setSelectedRowsPerPage([]);
    MainData(1, newRowsPerPage);
  };

  const handleCopyClick = (infBusinessLink) => {
    const linkToCopy = window.location.href;
    navigator.clipboard
      .writeText(infBusinessLink)
      .then((res) => {
        // setCopied(true);
      })
      .catch((error) => {
        console.error("Failed to copy link: ", error);
      });
    toast.info("Link Copy on Clipboard", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 1000,
    });
  };

  // const ClaimHandler = () =>{
  //  let data = {
  //     influencer_id: 26783,
  //     requested_amount: String(influencerData?.claimableAmount)
  //  }
  //  axios.post("https://uat-be.businessbay.io/api/v1/influencer/createClaimRequest",data)
  //  .then((res)=>{
  //   console.log(res?.data?.result,"asjdhkaksjdhakjsdhdakjsdh")
  //   setClaimAmount(res?.data?.result);
  //   toast.success(res?.data?.message, {
  //     position: toast.POSITION.TOP_RIGHT,
  //     autoClose: 1000,
  //   });
  //  }).catch((error)=>{
  //   console.log(error)
  //  })
  // }

  
  return (
    <>
      <PageName PageName="Influencers Details" />
      <div className="row Greeting_total_wrapper">
      <div className="influencersdetails-topdetails">
        <h3><span>Influencer Name :</span> {name?.name}</h3>
        <h3><span>Business Name :</span> {name?.businesses?.title}</h3>
        <h3><span>Business Link :</span> {infBusinessLink}   &nbsp;&nbsp;<span style={{cursor:"pointer"}}  onClick={()=>handleCopyClick(infBusinessLink)}><i className="fa-solid fa-copy"></i></span></h3>
      </div>
        <div className="col-md-12" id="total_wrapper_container">
          <div className="row">
         
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i class="fa-solid fa-business-time"></i>
                <h3>Total Orders</h3>
                <h4>{influencerData?.totalOrders || 0}</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i class="fa-solid fa-business-time"></i>
                <h3>Total Pending Orders</h3>
                <h4>{influencerData?.totalPendingOrders || 0}</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox Total_Titlebox_container">
                <i class=" fa-regular fa-handshake"></i>
                <h3>Total Delivered Orders</h3>
                <h4>{influencerData?.totalDeliveredOrders  || 0}</h4>
              </div>
            </div>
            <div class="col-md-3">
              <div class="tile bbox">
                <i class="fa-solid fa-users"></i>
                <h3>Total Sales</h3>
                <h4>{currencySymbol}{influencerData?.totalSales || 0}</h4>
              </div>
            </div>
            <div className="col-md-3"> 
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Earning </h3>
                <h4>{currencySymbol}{!!influencerData && influencerData != null ? Number(influencerData?.totalEarning)?.toFixed(2) : 0}</h4>
              </div>
            </div>  
            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Total Claimable Amount</h3>
                <h4>{currencySymbol}{ !!influencerData && influencerData != null ? Number(influencerData?.claimableAmount)?.toFixed(2) : 0}</h4>
                {/* <div className="Remainingclaimableamtdiv"><h4 className="Remainingclaimableamt">{currencySymbol}{influencerData?.claimableAmount} <span className="claimbtnspan" onClick={ClaimHandler}><button className="claimbtn">Claim</button></span></h4></div> */}
              </div>
            </div>
            <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Withdrawn Amount</h3>
                <h4>{currencySymbol}{!!influencerData ? influencerData?.totalPaidAmount?.toFixed(2) : 0}</h4>
              </div>
            </div>
            {/* <div className="col-md-3">
              <div className="tile bbox ">
                <i className="fa-solid fa-users"></i>
                <h3>Remaining Claimable Amount</h3>
                <div className="Remainingclaimableamtdiv"><h4 className="Remainingclaimableamt">{currencySymbol}{influencerData?.totalRemaingAmount} <span className="claimbtnspan"><button className="claimbtn">Claim</button></span></h4></div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
export default InfluencersDetails;