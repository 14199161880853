import React, { useRef, useState } from "react";

import bannerImage from "../../../../Images/theme-53-banner.png";
// import logoImage from "../../../../Images/theme-17-logo.png";
import "./Theme53.css";
import GalleryCarousel from "./GalleryCarousel";
import TestimonialCarousel from "./TestimonialCarousel";
import gallery1 from '../../../../Images/theme-53-g1.png';
import gallery2 from '../../../../Images/theme-53-g2.png';
import gallery3 from '../../../../Images/theme-53-g3.png';
import gallery4 from '../../../../Images/theme-53-g4.png';
import logo1 from '../../../../Images/theme-53-l1.png';
import logo2 from '../../../../Images/theme-53-l2.png';
import logo3 from '../../../../Images/theme-53-l3.png';
import logo4 from '../../../../Images/theme-53-l4.png';
import product1 from '../../../../Images/theme-53-p1.png';
import product2 from '../../../../Images/theme-53-p2.png';
import insta from '../../../../Images/theme-53-insta.png';
import youtube from '../../../../Images/Theme-53-youtube.png';




const Theme53Template = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const calendarInputRef = useRef(null);
  const testimonialArray = [
    {
      name: "Ernest Reinger",
      description:
        
"Expert support and innovative solutions! Our business runs smoother thanks to their help.",
      formCount: "0",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
       "Fantastic service! The team solved our IT issues quickly and efficiently. Highly recommend!",
      formCount: "1",
      image: "null",
    },
    {
      name: "Ernest Reinger",
      description:
       "Professional and reliable! They transformed our IT infrastructure. Truly a game changer!",
      formCount: "2",
      image: "null",
    },
  ];
  const galleryArray = [
    {
      id: 60,
      business_id: 10,
      image: "1701329881279Rectangle6.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:01.000Z",
      updated_at: "2023-11-30T07:38:01.000Z",
    },
    {
      id: 61,
      business_id: 10,
      image: "1701329883848Rectangle7.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:03.000Z",
      updated_at: "2023-11-30T07:38:03.000Z",
    },
    {
      id: 62,
      business_id: 10,
      image: "1701329887518Rectangle597.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:07.000Z",
      updated_at: "2023-11-30T07:38:07.000Z",
    },
    {
      id: 63,
      business_id: 10,
      image: "1701329891477Rectangle598.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:11.000Z",
      updated_at: "2023-11-30T07:38:11.000Z",
    },
    {
      id: 64,
      business_id: 10,
      image: "1701329899378Rectangle599.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:19.000Z",
      updated_at: "2023-11-30T07:38:19.000Z",
    },
    {
      id: 65,
      business_id: 10,
      image: "1701329902425Rectangle600.jpg",
      created_by: 72,
      created_at: "2023-11-30T07:38:22.000Z",
      updated_at: "2023-11-30T07:38:22.000Z",
    },
  ];

  const openCalendar = () => {
    if (calendarInputRef.current) {
      calendarInputRef.current.showPicker();
    }
  };
  return (
    <div>
      <div class="main-temaplt" id="temp53">
        <div className="container">
          <div className="row justify-content-center">
            <div style={{ width: "420px" }} className="col-12 wtemplate">
              <article className="imgsechere sec">
                <img
                  src={bannerImage}
                  className="mx-auto d-block img-fluid coverimghere "
                  style={{ width: "100%" }}
                />
                <div className="imageOverlay"></div>

                <article className="social-links-sec">
                  {/* <h3 class="sectitle">Social Media</h3> */}
                  <ul class="socil-link socil-link-inner">
                    <li>
                      <a>
                        <i class={`fa-brands fa-facebook`}></i>
                        {/* <img src={FacebookIcon} /> */}
                      </a>
                    </li>
                    <li>
                      <a>
                        <i class={`fa-brands fa-google`}></i>
                        {/* <img src={InstagramIcon} /> */}
                      </a>
                    </li>

                    <li>
                      <a>
                        <i class={`fa-brands fa-twitter`}></i>
                        {/* <img src={TweeterIcon} /> */}
                      </a>
                    </li>
                    <li>
                      <a>
                        <i class={`fa-brands fa-whatsapp`}></i>
                        {/* <img src={WhatsappIcon} /> */}
                      </a>
                    </li>
                    <li>
                      <a>
                        <i class={`fa-brands fa-linkedin`}></i>
                        {/* <img src={LinedinIcon} /> */}
                      </a>
                    </li>
                  </ul>
                </article>

                <div className="followers">
                 <div className="item" ><img src={insta} alt="" /><span>1.8M Followers</span></div>
                 <p className="middle">|</p>
                 <div className="item" ><img src={youtube} alt="" /><span>859K Subscribers</span></div>
                </div>

              </article>

              <section className="mainsection">
                {/* <div className="chevron">
                  {" "}
                  <i class="fa-solid fa-chevron-up"></i>
                </div> */}
                <article className="personalinfo">
                  {/* <img src={logoImage} className="mx-auto d-block img-fluid logo-profile" /> */}
                  <h3 className="username">
                  Kritika Khurana
                  </h3>
                  <p className="titleuser">Beauty Fashion/Lifestyle</p>
                </article>

                <article className="abouttemplate sec">
                  {/* <h3 class="sectitle">About Us</h3> */}
                  <p className="para">
                    {` I'm Kritika Khurana and this is your one-stop for all things fashion! I have completed my graduation in Fashion Designing and I make outfit videos, Hairstyle tutorial, DIYs, Travel videos etc.
#bohogirlies are life`}
                  </p>
                </article>

                <article class="gallery sec sec-common">
                  <h3 className="sectitle">Gallery </h3>
                  {/* <GalleryCarousel slides={galleryArray} /> */}

<div className="inner-div">
  <div className="item"><img src={gallery1} alt="" /></div>
  <div className="item"><img src={gallery2} alt="" /></div>
  <div className="item"><img src={gallery3} alt="" /></div>
  <div className="item"><img src={gallery4} alt="" /></div>
</div>
<h3 className="sectitle" style={{marginTop:"5px",fontSize:"14px"}}  >View All</h3>
                </article>

<article className="brand-deals sec">  
<h3 class="sectitle">Brand Deals </h3>

<div className="brand-inner" >
<div className="item"> <img src={logo1} alt="" /></div>
<div className="item"> <img src={logo2} alt="" /></div>
<div className="item"> <img src={logo3} alt="" /></div>
<div className="item"> <img src={logo4} alt="" /></div>
<div className="item"> <img src={logo1} alt="" /></div>

</div>

</article>


<article className="products sec">
<h3 class="sectitle"> Products </h3>
<div className="product-inner">
  <div className="item">
    <div className="image" ><img src={product1} alt="" /></div>
  <h6>All stay foundation</h6>
  <p>₹1099.00/-</p>
  <button className="stat">Buy Now</button>
  </div>
  <div className="item">
    <div className="image" ><img src={product2} alt="" /></div>
  <h6>All stay foundation</h6>
  <p>₹1099.00/-</p>
  <button className="stat">Buy Now</button>
  </div>
 
</div>
</article>

<article className="conatctform sec">
                  <h3 class="sectitle">Contact Form</h3>
                  <div class="contact-form">
                    <form>
                      {/* <div class="col-12 mb-3">
                        <label> Name*</label>
                        <input
                          type="text"
                          class="form-control clear_string"
                          placeholder="Full Name"
                          name="last_name"
                          value=""
                          required=""
                        />
                      </div> */}
                      <div class="col-12 mb-3">
                      {/* <label> email*</label> */}
                        <input
                          type="text"
                          class="form-control"
                          placeholder="abc@gmail.com"
                          name="email"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">   {/* <label> Phone*</label> */}
                       
                        <input
                          type="text"
                          class="form-control clear_string"
                          maxlength="10"
                          minlength="10"
                          placeholder="+91 (000) 000-0000 "
                          name="mobile_number"
                          value=""
                          required=""
                        />
                      </div>
                      <div class="col-12 mb-3">
                        {/* <label> Case Description* </label> */}
                        <textarea
                          class="form-control clear_string"
                          rows="3"
                          placeholder="Leave a message"
                          required=""
                          name="message"
                        ></textarea>
                      </div>

                      <div class="col-12 mb-3">
                        <button type="submit" class="btn btn-submitform">
                          Submit
                          {/* <img src={arrow} alt='arrow' /> */}
                        </button>
                      </div>
                    </form>
                  </div>
                </article>

                <article class="contact sec">
                  <h3 class="sectitle">For Inquiries</h3>
                  <ul class="conlink">
                    {/* <li>
                      <a href="">
                        <i class="fa-solid fa-phone"></i>
                        <span>{`8010666660`}</span>
                      </a>
                    </li> */}

                    <li>
                      <a href="">
                        {/* <i class="fa-solid fa-envelope"></i> */}
                        <span>{`Email: kritika.thatbohogirl@gmail.com`}</span>
                      </a>
                    </li>

                    {/* <li>
                      <a href="">
                        <i class="fa-solid fa-location-dot"></i>
                        <span>{`First floor, A-250, A Block, Pocket A, Sector 19, Noida, Uttar Pradesh 201301, India`}</span>
                      </a>
                    </li> */}
                  </ul>
                </article>


                
                

                {/* <article className="servicesName sec">
                  <h3 class="sectitle">Services </h3>
                  <div className="mainservicebox">
                    <ul className="servesbox">
                      <li>Software Development</li>
                      <li>IT Consulting </li>
                      <li>Network Management </li>
                      <li>Web Development </li>
                      <li>System Integration </li>
                      <li>Training and Workshops </li>
                     
                    </ul>
                  </div>
                </article> */}

               

                {/* <article className="businessHours sec">
                  <h3 class="sectitle">
                    Working Hours{" "}
                    <i class="fa-solid fa-chevron-right rightcorner"></i>
                  </h3>
                  <p>
                    Mon to Sat <span>9AM to 9PM</span>
                    <br />
                    Sunday Closed!
                  </p>

                  <ul class="timelist">
                  <li>
                    <div className="day">Monday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Tuesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Wednesday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Thursday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Friday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                  <li>
                    <div className="day">Saturday :</div>
                    <div className="time">
                      <span>8:00 AM</span>-<span>5:00 PM</span>
                    </div>
                  </li>
                </ul>
                </article> */}

               

                

                {/* <article class="testimoniyalbox sec">
                  <div className="testimonial-inner">
                    <h3 class="sectitle testimonial">Testimonial </h3>

                    <div>
                      <TestimonialCarousel
                        testimonials={testimonialArray}
                        currentElem={currentIndex}
                      />
                    </div>
                  </div>
                </article> */}

              </section>




            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Theme53Template;
