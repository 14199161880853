import React, { useContext, useEffect, useState, useRef } from "react";
import PageName from "../Utils/PageName";
import { Link } from "react-router-dom";
import { environmentVariables } from "../../config/env.config";
import axios from "axios";
import { userContext } from "../../context/userContext";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "./OrderNowUpdatedStyle.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import styled from "styled-components";
import "react-tabs/style/react-tabs.css";
import { currencyContext } from "../../context/currencyContext.js";
import { useNavigate } from "react-router";
import DataTable from "react-data-table-component";
import SortIcon from "@material-ui/icons/ArrowDownward";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import PlansAllocate from "./PlansAllocate.js";

function Plans() {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const baseUrl = environmentVariables?.apiUrl;
  const { userData, setUserData } = useContext(userContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [lifetime, setLifetime] = useState("");
  const [isShowLifetime, setIsShowLifetime] = useState(false);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const [price, setPrice] = useState("");
  const selectRef = useRef(null);
  const [maxUsers, setMaxUsers] = useState("");
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [maxBusiness, setMaxBusiness] = useState("");
  const [numberOfpvc, setnumberOfpvc] = useState("");
  const [numberOfMetal, setnumberOfMetal] = useState("");
  const [storageLimit, setStorageLimit] = useState("");
  const [enableDomain, setEnableDomain] = useState(false);
  const [enableSubDomain, setenableSubDomain] = useState(false);
  const [enableBranding, setenableBranding] = useState(false);
  const [progressiveWebApp, setprogressiveWebApp] = useState(false);
  const [enableQRCode, setenableQRCode] = useState(false);
  const [enableChatgpt, setenableChatgpt] = useState(false);
  const [enablePVCCard, setenablePVCCard] = useState(false);
  const [enableMetalCard, setenableMetalCard] = useState(false);
  const [textareaValue, setTextareaValue] = useState("");
  const user_type = userData?.user_type;
  const [themes, setThemes] = useState("");
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [dataArr, setDataArr] = useState([]);
  const [dataArrYear, setDataArrYear] = useState([]);
  const [dataArrMonth, setDataArrMonth] = useState([]);
  const [dataArrCustom, setDataArrCustom] = useState([]);
  const [dataArrLifetime, setDataArrLifetime] = useState([]);
  const { currencyObjData, setCurrencyObjData } = useContext(currencyContext);
  const [isPaymentBtnClicked, setIsPaymentBtnClicked] = useState(false);
  const [isEmailFound, setIsEmailFound] = useState(true);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [emailsucessMessage, setEmailsucessMessage] = useState('');
  const [formName, setFormName] = useState('');
  const [submitBtn, setSubmitBtn] = useState('');
  const [data, setData] = useState([]);
  const [expiryDays, setExpiryDays] = useState();
  const [userEmail, setUserEmail] = useState("");
  const [editedData, setEditedData] = useState({});
  const [planId, setPlanId] = useState("");
  const [handleName, setHandleName] = useState("");
  const [addedData, setAddedData] = useState({});
  const navigate = useNavigate();
  const tableExtensions = { export: false, print: false };
  const [isEditMode, setIsEditMode] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedPlan, setSelectedPlan] = useState('');

  const handlePlanClick = async () => {
    //  setEditedData({
    //   ...editedData,
    //   description: ""
    // });
    setEditedData({});
    setUserEmail("");
    // await setData({});
    setFormName("Create New Plan")
    setSubmitBtn("Save Changes")
    setHandleName("handleSubmit")

    // Get the element with the class 'MyClickModel'
    const myClickModelElement = document.querySelector('.MyClickModel');
    if (myClickModelElement) {
      const formElement = document.getElementById('bplanForm');
      formElement.reset();
      myClickModelElement.click();
    }
  }
  const handlePlanAllocateClick = async () => {
    console.log("handlePlanAllocateClick.....");
    setIsOpen(true);    
  }
  const handleClosePopup = () =>{
    setIsOpen(false);
  }
  const customStyles = {
    dataTableExtensions: {
      backgroundColor: "lightgray",
      padding: "10px",
    },
    headRow: {
      style: {
        backgroundColor: "#e9e9e9",
        borderRadius: "2px 2px 0 0",
        paddingLeft: "25px",
        border: "none",
        textTransform: "capitalize",
        position: "sticky",
        left: 0,
        top: 0,
        zIndex: 1,
        boxShadow: "0 2px 2px -1px rgba(0, 0, 0, 0.1)",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
    headCells: {
      style: {
        color: "#525252",
        fontSize: "16px",
        minHeight: "52px",
        //   width: "135px", // Adjust the width as needed
        // overflow: "visible", 
      },
    },
    rows: {
      style: {
        padding: "6px 0px 6px 25px",
        fontSize: "14px",
        textTransform: "capitalize",
        minHeight: "48px !important",
      },
    },
    BusinessColumn: {
      position: "sticky",
      left: "0",
      zIndex: "1",
      backgroundColor: "#fff",
      overflow: "hidden",
      whiteSpace: "nowrap",
    }
  };

  const handleTabSelect = (index) => {
    setSelectedTabIndex(index);
  };

  const CenteredTabList = styled.div`
    display: flex;
    justify-content: center;
  `;
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const keyToRemove = 'id';
      const { [keyToRemove]: removedKey, ...newAddedData } = addedData;
      if (!newAddedData.hasOwnProperty('lifetime_year')) {
        newAddedData.lifetime_year = 0;
      }

      if (newAddedData.hasOwnProperty('is_enterprise')) {
        newAddedData.email = !!newAddedData.userEmail?newAddedData.userEmail:newAddedData.email;
        newAddedData.expiry_days = newAddedData.expiryDays;
        if (newAddedData.is_enterprise === 'on') {
          newAddedData.is_enterprise = 1;
          newAddedData.duration = "customized";
        } else {
          newAddedData.is_enterprise = 0;
        }
      }

      // let selectedValue = selectRef?.current?.value;

      // if (addedData.isEnterprise) {
      //   selectedValue = "customized";
      // }
      
      if (addedData.name == "" || addedData.name?.trim() == "") {
        toast.error("Name is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.name?.length < 3) {
        toast.error("Name should be atleast 3 character long", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.price == "") {
        toast.error("Price is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.price < 0) {
        toast.error("Price cannot be negative", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.isEnterprise == false) {
        toast.error("Duration is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.maxUsers == "") {
        toast.error("User creation limit is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.maxUsers < 0) {
        toast.error("User creation limit cannot be negative", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.maxBusiness == "") {
        toast.error("Business creation limit is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.maxBusiness < 0) {
        toast.error("Business creation limit cannot be negative", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.storageLimit == "") {
        toast.error("Storage limit is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.storageLimit < 0) {
        toast.error("Storage limit cannot be negative", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.themes == "") {
        toast.error("themes is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      } else if (addedData.textareaValue == "" || addedData.textareaValue?.trim() == "") {
        toast.error("Description is mandatory", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        return;
      }
      const response = await axios.post(
        `${baseUrl}api/v1/plan/createPlan`,
        {
          ...newAddedData,
        },
        {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        }
      );
      if (response && response?.data && response?.data?.success) {
        // closeModal
        const closeModalElement = document.querySelector('#closeModal');
        if (closeModalElement) {
          closeModalElement.click();
        }
        toast.success("Plan Saved Successfully.", {
          position: toast.POSITION.TOP_RIGHT,
          toastId:"plan-saved",
          autoClose: 1000,
        });
        setEmailsucessMessage("");
        fetchPlanData();
        // if (isEnterprise) {
        //   navigate("/CustomizedPlansSuperAdmin");
        //   fetchPlanData();
        // } else {
        //   setTimeout(() => {
        //     location.reload();
        //   }, 2000);
        // }
      } else {
        toast.error("Something is missing", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message?.message ||
        error?.response?.data?.message,
        {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        }
      );
    }
  };

  const countryToToCountryCode = {
    "INDIA":"+91",
    "USA":"+1",
    "UAE":"+971"
  };

  const handleBlurEmail = () => {
    if (userEmail === '') {
      setEmailErrorMessage('Email is required!');
      return;
    }
  const country = countryToToCountryCode[editedData.country];

    let countrycode = selectedCountry || country

    axios
      .get(`${baseUrl}api/v1/user/checkEmailAvailability?email=${userEmail}&countryCode=${encodeURIComponent(countrycode)}`)
      .then((response) => {
        const { isEmailUsed } = response.data;
        setIsEmailFound(isEmailUsed);
        if (isEmailUsed) {
          setEmailsucessMessage('user Found');
        } else {
          setIsEmailFound(false)
          setEmailErrorMessage('user not found for this country');
        }
      })
      .catch((err) => {
        console.error(err);
        setEmailErrorMessage('Error checking email availability');
      });
  };

  const fetchData = async (id) => {
    try {
      setIsEditMode(true);
      setFormName("Edit Plan")
      setSubmitBtn("Update Changes")
      setHandleName("handleSave")
      await axios
        .get(`${baseUrl}api/v1/plan/fetchCustomPlanById`, {
          params: { planId: id },
          headers: {
            _token: userData?.token,
          },
          withCredentials: true,
        })
        .then((response) => {
          // setData(response.data);
          // setEditedData(response?.data?.result);
          const { plan, userEmail, days } = response?.data?.result;
          setEditedData(plan);
          setUserEmail(response?.data?.result?.userEmail || '');
          setExpiryDays(days || '');
          const myClickModelElement = document.querySelector('.MyClickModel');
          if (myClickModelElement) {
            myClickModelElement.click();
          }
          setPlanId(id);
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddedData({ ...editedData, [name]: value });
    setEditedData({ ...editedData, [name]: value });
    if (name === 'is_enterprise') {
      if (e.target.checked === true) {
        setEditedData({
          ...editedData,
          is_enterprise: "on"
        });
        //  setIsEnterprise(e.target.checked);
      } else {
        // setIsEnterprise(false);
        setEditedData({
          ...editedData,
          is_enterprise: false
        });
      }
    }
    if (name === 'userEmail') {
      setUserEmail(e.target.value);
      setAddedData({ ...addedData, email: e.target.value });
      setEditedData({ ...editedData, email: e.target.value });
    }
    setIsFormChanged(true);

    if (name === 'expiryDays') {
      if (!isNaN(e.target.value) && e.target.value >= 1 && e.target.value <= 99999) {
        // setEditedData({
        //   ...editedData,
        //   expiryDays: inputValue
        // });
      } else {

        toast.error(
          "Only Five Digit Allow.",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          }
        );
        // showErrorMssage
      }
    }
  };

  const handleAlocatePlan = async () => {
 
    setEditedData({});
    setUserEmail("");
    setFormName("Allocate Plan")
    setHandleName("handleSubmit")

    const myClickModelElement = document.querySelector('.MyClickmodes');
    if (myClickModelElement) {
      const formElement = document.getElementById('alocateForm');
      formElement.reset();
      myClickModelElement.click();
    }
  }

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      let data = JSON.stringify(editedData);
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${baseUrl}api/v1/plan/editPlan?planId=${planId}`,
        headers: {
          _token: userData?.token,
          "Content-Type": "application/json",
        },
        // withCredentials: true,
        data: data,
      };
      axios
        .request(config)
        .then((response) => {
          toast.success("plan Updated succesfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          const closeModalElement = document.querySelector('#closeModal');
          if (closeModalElement) {
            closeModalElement.click();
          }
          fetchPlanData();
          // setTimeout(() => {
          //   location.reload();
          // }, 3000);
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.message?.message || "Failed to Update.",
            {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            }
          );
        });
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const fetchPlanData = () => {
    let apiEnd = "";
    if (user_type === "super_admin") {
      apiEnd = "/fatchPlans";
    } else {
      apiEnd = "/fatchPlansUsers";
    }
    try {
      axios
        .get(`${baseUrl}api/v1/plan${apiEnd}`, {
          headers: {
            _token: userData?.token,
          },
          // withCredentials: true,
        })
        .then((response) => {
          const filteredDataYear =
            response.data?.result.filter(
              (item) => item.duration === "Per Year"
            ) || [];
          const filteredDataMonth =
            response.data?.result.filter(
              (item) => item.duration === "Per Month"
            ) || [];
          const filteredDataCustom = response.data?.result.filter(
            (item) => item.duration == "customized"
          );
          const filteredDataLifetime = response.data?.result.filter(
            (item) => item.duration == "Lifetime"
          );

          let arr = response?.data?.result
          arr = arr.sort(
            (x, y) => new Date(y?.created_at) - new Date(x?.created_at)
          );

          setDataArr(response.data?.result);
          setDataArrYear(filteredDataYear);
          setDataArrCustom(filteredDataCustom);
          setDataArrLifetime(filteredDataLifetime);
          setDataArrMonth(filteredDataMonth);
          setData(arr)
        })
        .catch((error) => {
          console.error("Error:", error?.response?.data?.message);
        });
    } catch (error) {
      console.error("API Error:", error?.response?.data?.message);
    }
  };
  useEffect(() => {
    fetchPlanData();
  }, []);

  const handleChangeIsEnterprise = (e) => {
    setIsEnterprise(e.target.checked);
  };
  const handleChangeLifetime = (e) => {
    if (e.target.value != 'Lifetime' && e.target.value != '') {
      setIsShowLifetime(true)
    } else {
      setIsShowLifetime(false)
    }
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
    setAddedData({ ...addedData, [name]: value });
    setIsFormChanged(true);
  };

  const handleDelete = async (planId) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: `You won't be able to revert this!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });
    if (result.isConfirmed) {
      try {
        const response = await axios.delete(
          `${baseUrl}api/v1/plan/deletePlan${planId}`,
          {
            headers: {
              _token: userData?.token,
              "Content-Type": "application/json",
            },
          }
        );
        if (response.data.success) {
          toast.success("Plan deleted successfully.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          toast.error("Failed to delete the plan.", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      } catch (error) {
        console.error("Error deleting the plan:", error.message);
      }
    }
  };

  const handlebutton = async (e) => {
    if (formName === "Create New Plan") {
      await handleSubmit(e);
    } else {
      await handleSave(e);
    }
  };


  const [dataRs, setDataRs] = useState([]);

  const SubComponent1 = (props) => {
    useEffect(() => {
      setDataRs(props?.data);
    }, [props?.data]);


    return (
      <>
        <div className="demo">
          <div className="container">
            <div className="row">
              {dataRs?.map((item, index) => (
                <div className="col-12 col-md-3 col-sm-6 plan_Wrapper">
                  <div className="pricingTable green" id="Adminplan_container">
                    {user_type === "super_admin" && (
                      <p style={{ marginBottom: "20px" }}>
                        <button
                          className="btn btn-primary icon-btn Admin_editplan_button"
                          onClick={() => fetchData(dataRs[index]?.id)}
                          style={{ margin: "0 10px" }}
                        >
                          Edit Plan
                        </button>
                        <button
                          className="btn btn-primary icon-btn Admin_deleteplan_button"
                          onClick={() => handleDelete(dataRs[index]?.id)}
                        >
                          Delete
                        </button>
                      </p>
                    )}
                    <div className="pricingTable-header">
                      <h3 className="title">{dataRs[index]?.name}</h3>
                    </div>
                    <ul className="pricing-content">
                      <li className="on">{dataRs[index]?.themes} Theme</li>
                      <li>{dataRs[index]?.business} Business</li>
                      <li className="on">{dataRs[index]?.max_users} Users</li>
                      <li
                        className={
                          dataRs[index]?.enable_branding === "on"
                            ? "on"
                            : "disable"
                        }
                      >
                        QR Code
                      </li>
                      <li
                        className={
                          dataRs[index]?.enable_physical_pvc === "on"
                            ? "on"
                            : "disable"
                        }
                      >
                        {dataRs[index]?.no_of_pvc_card} PVC Card
                      </li>
                      <li
                        className={
                          dataRs[index]?.enable_physical_metal === "on"
                            ? "on"
                            : "disable"
                        }
                      >
                        {dataRs[index]?.no_of_metal_card} Metal Card
                      </li>
                      <li>{dataRs[index]?.storage_limit} Storage Limit</li>
                    </ul>
                    <div className="price-value">
                      <i className="fa fa-inr" aria-hidden="true"></i>
                      {dataRs[index]?.price}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  };

  const columns = [
    {
      name: "Plan Name",
      selector: row => row.name,
      sortable: true,
    },
    {
      name: "Country",
      selector: row => row.country,
      sortable: true,
    },
    {
      name: "Price",
      selector: row => row.price,
      sortable: true,
    },
    {
      name: "Duration",
      selector: row=>row.duration,
      sortable: true,
    },
    {
      name: "Business",
      selector: row=>row.business,
      sortable: true,
    },
    {
      name: "Users",
      selector: row=>row.max_users,
      sortable: true,
    },
    {
      name: "Appointments",
      selector: row=>row.appoinments,
      sortable: true,
    },
    {
      name: "Contacts",
      selector: row=>row.contacts,
      sortable: true,
    },
    {
      name: "Themes",
      selector: row=>row.themes,
      sortable: true,
    },
    {
      name: "QrCode",
      selector: row=>row.enable_qr_code,
      sortable: true,
    },
    {
      name: "Storage",
      selector: row=>row.storage_limit,
      sortable: true,
    },
    {
      name: "PVC",
      selector: row=>row.no_of_pvc_card,
      sortable: true,
    },
    {
      name: "Metal",
      selector: row=>row.no_of_metal_card,
      sortable: true,
    },
    {
      name: "Action",
      sortable: false,
      cell: (d) => [
        <>
          <i
            style={{ padding: "0 7px" }}
            key={`trash-${d.id}`}
            className="fas fa-trash-alt"
            onClick={() => handleDelete(d?.id)}
          ></i>{" "}
          &nbsp;&nbsp; &nbsp;{" "}
          <i
            key={d?.id}
            onClick={() => { fetchData(d?.id); }}
            className="first fas fa-pen"
          ></i>
        </>,
      ],
    },
  ];
  const tableData = {
    columns,
    data,
  };


  const handlePlanChange = (event) => {
    setSelectedPlan(event.target.value);
  };

  useEffect(() => {
    handleBlurEmail()
  }, [selectedCountry]);


  const handleCountryChange = async (e) => {
    const countryCode = e.target.value;
    setSelectedCountry(countryCode);
    
    if (countryCode) {
      // Make the API call
      try {
        const response = await axios.get(
          `${baseUrl}api/v1/plan/fatchPlansUsers_withcountry?country=${encodeURIComponent(countryCode)}`,
          {
            headers: {
              _token: userData?.token,
            },
          }
        );
        const fetchedPlans = response?.data?.result || [];
      setPlans(fetchedPlans); 
      } catch (error) {
        console.error('Error fetching plans:', error);
      }
      handleBlurEmail(); 
    } else {
      setPlans([]);
    }
  };

  const handleAllocatePlan = async () => {
    // Validate required fields before API call
    if (!selectedCountry || !userEmail || !selectedPlan) {
      toast.error("Please fill in all required fields.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      return;
    }

    if (!isEmailFound) {
      setEmailErrorMessage('User not found for this country');
      return;  // Prevent the API call
    }
    

    const payload = {
      email: userEmail,
      plan_id: selectedPlan,
    };

    try {
      const response = await axios.post(`${baseUrl}api/v1/plan/allocate_plan`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      toast.success("Plan allocated successfully.", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
      setSelectedPlan('')
      setUserEmail('')
      setSelectedCountry('')
      setEmailsucessMessage('')
      const closeModalElement = document.querySelector('#closeplanModal');
      if (closeModalElement) {
        closeModalElement.click();
      }
    } catch (error) {
      console.error('Error allocating plan:', error);
      toast.error("Error allocating plan", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <PageName PageName="Plan" />
      <div style={{"display": "flex","justify-content": "end","gap": "20px"}}>
      <p className="addrolep">
        <span className="icon-btn addrolemain_role" onClick={handlePlanClick}>
          Add New Plan <span className="addrole_role">+</span>
        </span>
        <a className="MyClickModel" data-bs-toggle="modal" data-bs-target="#planselectedit"></a>
      </p>
      <p className="addrolep">
        <span className="icon-btn addrolemain_role" onClick={handleAlocatePlan}>
        Allocate Plan <span className="addrole_role">+</span>
        </span>
        <a className="MyClickmodes" data-bs-toggle="modal" data-bs-target="#Alocateplan"></a>
      </p>
      </div>
      <div className="scrollable-columns">
        <div className="sticky-header-container">
          <DataTableExtensions
            {...tableData}
            {...tableExtensions}
            customStyles={customStyles}
            filterPlaceholder="Search CustomizedPlans"
          >
            <DataTable
              columns={columns}
              data={data}
              noHeader
              defaultSortField="id"
              sortIcon={<SortIcon />}
              defaultSortAsc={true}
              pagination
              highlightOnHover
              dense
              customStyles={customStyles}
            />
          </DataTableExtensions>
        </div>
      </div>
      <>
        <div
          className="modal fade"
          id="planselectedit"
          backdrop="static"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg dialog_wrapper_plan_superadmin">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title L508" id="exampleModalLabel">
                  {formName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeModal"
                ></button>
              </div>
              <div className="modal-body ddd">
                <form id="bplanForm">
                  {/* {editedData?.is_enterprise == true && ( */}
                  <div className="row g-3 mb-3">
                    <div className="col">
                      <div className="d-flex gap-2">
                        <input
                          // disabled
                          checked={editedData?.is_enterprise}
                          // onChange={handleChangeIsEnterprise}
                          name="is_enterprise"
                          onChange={handleInputChange}
                          type="checkbox"
                        />
                        <div>Enterprise Plan</div>
                      </div>
                    </div>
                  </div>
                  {/* )} */}

                  <div className="col">
                    <select
                      className="form-select"
                      name="country"
                      value={editedData.country}
                      onChange={handleInputChange}
                    >
                      <option value="">
                        Select Country
                      </option>
                      <option value="UAE">UAE</option>
                      <option value="INDIA">INDIA</option>
                      <option value="USA">USA</option>
                    </select> 
                  </div>

                  {editedData?.is_enterprise && (
                    <div className="row g-3 mb-3">
                      <div className="col">
                        <input
                          type="text"
                          // className="form-control"
                          className={`form-control ${isEmailFound ? 'user-found' : 'input-error'}`}
                          placeholder="Enter user Email*"
                          aria-label="email"
                          name="userEmail"
                          value={userEmail}
                          onChange={handleInputChange}
                          onBlur={handleBlurEmail}
                          disabled={isEditMode}
                        />
                        {!isEmailFound && <div className="error-message">{emailErrorMessage}</div>}
                        {isEmailFound && <div className="success-message">{emailsucessMessage}</div>}
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          onWheel={(e) => e.target.blur()}
                          placeholder="Valid for Days*"
                          aria-label="expiryDays"
                          name="expiryDays"
                          value={expiryDays}
                          // onChange={(e) => setExpiryDays(e.target.value)}
                          onChange={handleInputChange}
                          disabled={isEditMode}
                        />
                      </div>
                    </div>
                    // ) : (
                    //   <></>
                  )}

                  <div className="row g-3 mb-3">
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Plan Name*"
                        aria-label="Name"
                        name="name"
                        value={editedData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Plan Price*"
                        aria-label="Price"
                        name="price"
                        value={editedData.price}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <select
                        className="form-select"
                        disabled={editedData?.is_enterprise}
                        name="duration"
                        value={editedData.duration}
                        onChange={handleChangeLifetime}
                      >
                        <option value="">
                          Select Duration
                        </option>
                        <option value="Per Month">Per Month</option>
                        <option value="Per Year">Per Year</option>
                        {/* <option value="Lifetime">Lifetime</option> */}
                      </select>
                    </div>

                    {/* <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Number Of Year*"
                        name="lifetime_year"
                        // disabled={isShowLifetime}
                        onWheel={(e) => e.target.blur()}
                        disabled={isShowLifetime || editedData?.is_enterprise}
                        value={editedData.lifetime_year}
                        onChange={handleInputChange}
                      />
                    </div> */}

                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="User Create Limit*"
                        onWheel={(e) => e.target.blur()}
                        aria-label="Max User"
                        name="max_users"
                        value={editedData.max_users}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Business Create Limit*"
                        onWheel={(e) => e.target.blur()}
                        aria-label="Max Business"
                        name="business"
                        value={editedData.business}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Storage limit*"
                        onWheel={(e) => e.target.blur()}
                        aria-label="Storage limit"
                        value={editedData.storage_limit}
                        name="storage_limit"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Appointments Limit*"
                        aria-label="Max Appointments"
                        name="appoinments"
                        value={editedData.appoinments}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Contacts limit*"
                        aria-label="Contacts limit"
                        value={editedData.contacts}
                        name="contacts"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <input
                        type="number"
                        className="form-control"
                        onWheel={(e) => e.target.blur()}
                        placeholder="Enter Max Theme Create Limit*"
                        aria-label="Max Theme"
                        name="themes"
                        value={editedData?.themes}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_custdomain === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_custdomain: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Enable Domain2
                        </label>
                      </div>
                    </div>

                <div className="col d-none">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="flexSwitchCheckDefault"
                      checked={editedData.enable_custsubdomain === "on"}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        setEditedData({
                          ...editedData,
                          enable_custsubdomain: isChecked ? "on" : "off",
                        });
                      }}
                    />
                    <label
                      className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Enable Sub Domain
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_branding === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_branding: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Enable Branding
                        </label>
                      </div>
                    </div>
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.pwa_business === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              pwa_business: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Progressive Web App (PWA3)
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3  mb-3">
                    <div className="col">
                      <div className="form-check form-switch Admin_editplan_inputcard_wrapper">
                        <input
                          className="form-check-input input_admineditplan_form"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_qr_code === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_qr_code: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Enable QR Code
                        </label>
                      </div>
                    </div>
                    <div className="col d-none">
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                          checked={editedData.enable_chatgpt === "on"}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setEditedData({
                              ...editedData,
                              enable_chatgpt: isChecked ? "on" : "off",
                            });
                          }}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Enable Chatgpt
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="box" id="AdminEditPlan_physicalcard_box">
                    <h3>Physical card</h3>
                    <div className="row g-3  mb-3">
                      <div className="col Admin_editplan_form">
                        <div className="form-check form-switch Admin_editplan_inputcard_wrapper">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={editedData.enable_physical_pvc === "on"}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setEditedData({
                                ...editedData,
                                enable_physical_pvc: isChecked ? "on" : "off",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Enable PVC Card
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          onWheel={(e) => e.target.blur()}
                          placeholder="0"
                          aria-label="No of card"
                          value={editedData.no_of_pvc_card}
                          name="no_of_pvc_card"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                    <div className="row g-3  mb-3">
                      <div className="col Admin_editplan_form">
                        <div className="form-check form-switch Admin_editplan_inputcard_wrapper">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexSwitchCheckDefault"
                            checked={editedData.enable_physical_metal === "on"}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setEditedData({
                                ...editedData,
                                enable_physical_metal: isChecked ? "on" : "off",
                              });
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Enable Metal Card
                          </label>
                        </div>
                      </div>
                      <div className="col">
                        <input
                          type="number"
                          className="form-control"
                          onWheel={(e) => e.target.blur()}
                          placeholder="0"
                          aria-label="No of card"
                          value={editedData?.no_of_metal_card}
                          name="no_of_metal_card"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row g-3  mt-3">
                    <div className="col">
                      <textarea
                        className="form-control"
                        placeholder="Enter Plan Description*"
                        id="floatingTextarea2"
                        rows="5"
                        value={(editedData?.description)?editedData?.description:""}
                        name="description"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div class="adminplan_buttonwrapper">
                    <button
                      type="button"
                      className="btn btn-secondary icon-btn adminplan_closebutton"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary icon-btn adminplan_deletbutton"
                      // data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{ margin: "0 10px" }}
                      // disabled={!isFormChanged}
                      onClick={handlebutton}
                    >
                      {submitBtn}
                    </button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="Alocateplan"
          backdrop="static"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg dialog_wrapper_plan_superadmin">
            <div className="modal-content ">
              <div className="modal-header">
                <h5 className="modal-title L508" id="exampleModalLabel">
                  {formName}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  id="closeplanModal"
                ></button>
              </div>
              <div className="modal-body ddd">
                <form id="alocateForm">
                  <div className="col">
                    <select
                      className="form-select"
                      name="country"
                      value={selectedCountry}
                      onChange={handleCountryChange} 
                    >
                      <option value="">Select Country</option>
                      <option value="+971">UAE</option>
                      <option value="+91">INDIA</option>
                      <option value="+1">USA</option>
                    </select> 
                  </div>

                    <div className="row g-3 mb-3">
                      <div className="col">
                        <input
                          type="text"
                          className={`form-control ${isEmailFound ? 'user-found' : 'input-error'}`}
                          placeholder="Enter user Email*"
                          aria-label="email"
                          name="userEmail"
                          value={userEmail}
                          onChange={handleInputChange}
                          onBlur={handleBlurEmail}
                          disabled={isEditMode}
                        />
                        {!isEmailFound && <div className="error-message">{emailErrorMessage}</div>}
                        {isEmailFound && <div className="success-message">{emailsucessMessage}</div>}
                      </div>
                      <div className="col">
                      <select className="form-select" name="duration" value={selectedPlan} onChange={handlePlanChange}>
                      <option value="">Select Plan</option>
                      {plans.length > 0 ? (
                        plans
                          .filter(plan => plan.duration !== "customized")  // Exclude plans with 'customized' duration
                          .map(plan => (
                            <option key={plan.id} value={plan.id}>
                              {plan.name}
                            </option>
                          ))
                      ) : (
                        <option value="">No plans available</option>
                      )}
                    </select>
                    </div>
                    </div>
                  <div class="adminplan_buttonwrapper">
                    <button
                      type="button"
                      className="btn btn-secondary icon-btn adminplan_closebutton"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>

                    <button
                      type="button"
                      className="btn btn-primary icon-btn adminplan_deletbutton"
                      aria-label="Close"
                      style={{ margin: "0 10px" }}
                      onClick={handleAllocatePlan}
                    >
                      Allocate plan
                    </button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </>
      <PlansAllocate isOpen={isOpen} handleClosePopup={handleClosePopup} />
    </>
  );
}
export default Plans;
