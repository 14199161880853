import React from 'react'
import { getServicesArray } from "../../../../utils/utility";

const CabAlternateUrl = (props) => {
  let rs = props?.altdata

  return (
    <div className='px-2'>
      <ul className="servesbox">
        {getServicesArray(rs?.alternatUrlObj?.content).map((val) => {
          const url = val?.description?.startsWith("http")
            ? val?.description
            : `https://${val?.description}`;
          return (
            val?.name &&
            val?.description && (
              <li key={val?.name} className="service-list">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {val?.name}
                </a>
              </li>
            )
          );
        })}
      </ul>

    </div>
  )
}

export default CabAlternateUrl

