import React, { useEffect, useState } from "react";
// import
// import { defaultPic } from "../../../Images/placeholder-imagevmycard.jpg";
import { environmentVariables } from "../../../config/env.config";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from "styled-components";
import axios from "axios";
// import { response } from "express";

const FormikErrorStyle = styled.div`
font-size:11px;
color: #ff3333;
padding-bottom: 5px;
`;

const ServiceForm = ({
  formCount,
  servicesData,
  setIsAddServiceDisable,
  setServicesData,
  removeForm,
  index,
  businessObjData
}) => {
  const [data, setData] = useState({
    formCount: formCount,
    banner: null,
    id:null,
    name: "",
    description: "",
    buttonLink: "",
    buttonName: "",
    bannerImage: null,
    isImageUpload: true,
  });

  const [tempFormCount, setTempFormCount] = useState(0);
  const [addBtnDisable, setAddBtnDisable] = useState(false);
  const [image, setImage] = useState(null);
  const [isShowAdd, setIsShowAdd] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [dynamicMarketplaceCategories,setDynamicMarketplaceCategories]=useState([]);

  const validationSchema = Yup.object({
    // name: Yup.string().matches(/^[a-zA-Z]+([a-zA-Z' -]*[a-zA-Z])*$/, 'Only alphabets are allowed').min(2, 'Minimum 2 characters'),
    name: Yup.string().matches(/^[\s\S]*$/, 'Only alphabets, digits, and special characters are allowed')
    .min(1, 'Minimum 1 characters')
    .max(250, 'Maximum 250 characters')
    .test('unique', 'Service already exists', value => {
      return !servicesData.some(service => service.id === value);
    }),
    
  });

  const formik = useFormik({
    initialValues: {
      name: data.name || '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      handleAddService(formik.values.name);
    },
  });

  const handleServiceImgChange = (e) => {
    setImage(e.target.files);
    if (e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = () => {
        setData({ ...data, bannerImage: reader.result });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };  
  const getMarketplaceCategories=()=>{
    let config={
      method:"get",
      url:`${environmentVariables?.apiUrl}api/v1/business/allCategory`
    }
    axios.request(config).then(response=>{
        setDynamicMarketplaceCategories(response.data)
    }).catch(error=>{
      console.log(error)
    })
  }
  useEffect(()=>{
    getMarketplaceCategories();
  },[])
  useEffect(() => {
    let currentForm = servicesData[index];
    
    setTempFormCount(formCount);
    // console.log(index,"inexx")
    setData({
      // formCount: index,
      formCount: currentForm?.formCount,
      banner: currentForm?.image, ///////////////////////
      id:currentForm?.id,
      name: currentForm?.name,
      description: currentForm?.description,
      buttonLink: currentForm?.buttonLink,
      buttonName: currentForm?.buttonName,
      bannerImage: currentForm?.bannerImage,
      isImageUpload: currentForm?.isImageUpload,
    });

      // If 'name' field already has data, disable the add button
  if (currentForm?.name) {
    setAddBtnDisable(true);
  } else {
    setAddBtnDisable(false);
  }

    data["name"] ? setIsShowAdd(false) : setIsShowAdd(true);
    
  }, [servicesData]);

  useEffect(() => {
    // console.log(data,"datatatat")
    setTempFormCount(data?.formCount);
  }, [data]);

  const handleAddService = (nameValue) => {
    setAddBtnDisable(true);
    setIsAddServiceDisable(false);
    // console.log(servicesData,"servicesData!@#! ")
    // const newServicesData = servicesData.map((val) => {
    //   if (val.formCount == formCount) {
    //     //     banner: [],
    //     // name: "",
    //     // description: "",
    //     // buttonLink: "",
    //     // buttonName: "",
    //     // bannerImage: null,
    //     val.banner =image;
    //     val.name = data?.name;
    //     val.description = data?.description;
    //     val.buttonLink = data?.buttonLink;
    //     val.buttonName = data?.buttonName;
    //     val.bannerImage = data?.bannerImage;
    //     return val;
    //   }
    //   return val;
    // });

    // // setData({ ...data, formCount: formCount });
    // setData(newServicesData);
    // setServicesData(newServicesData);
    //==============================================
    const filteredCategories=dynamicMarketplaceCategories.filter(val=>val.id==nameValue);
    const newServicesData = [...servicesData];
    
    // Check if the selected service is already present
    const isServiceAlreadyAdded = newServicesData.some(service => service.id === nameValue);
    console.log("isServiceAlreadyAdded====>>>", isServiceAlreadyAdded);
    
    if (isServiceAlreadyAdded) {
      return;
    }

    newServicesData.forEach((val) => {
      if (val.formCount === tempFormCount) {
        val.id=nameValue,
        val.banner = image;
        val.name = (businessObjData?.theme_color != 'theme20')?nameValue:filteredCategories[0]?.category_Name;
        val.description = data?.description;
        val.buttonLink = data?.buttonLink;
        val.buttonName = data?.buttonName;
        val.bannerImage = data?.bannerImage;
        val.isImageUpload = data?.isImageUpload;
      }
    });     
           
    setData(newServicesData);
    setServicesData(newServicesData);
    setIsEditing(false);
    // setIsAddDisable(true);
    
  };
  const handleEditForm = () => {
    setIsEditing(true);
    setAddBtnDisable(false);
    setIsShowAdd(true);
  
    formik.setValues({
      name: data.name || '',
    });
  
    console.log("Entering edit form with data:", data);
  };


  return (
    <div key={`form-${formCount}`} className="col-12" id="AddNewStyleFormfieldpad">
      <div className="serviceboxedit">
        <i
          class="fa-solid fa-circle-xmark crosseditservice crossIconAdddetails"
          onClick={() => removeForm(index, dynamicMarketplaceCategories)}
        ></i>
         <span onClick={handleEditForm} style={{padding:"2px 45px 10px 0px", cursor:"pointer",display:"flex", justifyContent:"end"}}> 
          <i class="fa-solid fa-pen"></i>
          </span>
       
       <div>
          {isShowAdd || isEditing ? (
            <div>
              {businessObjData?.theme_color !== 'theme20' ? (
                <input
                  name="name"
                  disabled={addBtnDisable}
                  className="form-control"
                  style={{ padding: "14px 25px", marginBottom: "10px" }}
                  type="text"
                  placeholder="Name"
                  value={data["name"] || ""}
                  onChange={(e) => {
                    formik.setFieldValue('name', e.target.value);
                    setData({ ...data, name: e.target.value });
                  }}
                  onBlur={formik.handleBlur}
                />
              ) : (
                <select
                  name="name"
                  disabled={addBtnDisable}
                  className="form-control"
                  style={{ padding: "14px 25px", marginBottom: "10px" }}
                  placeholder="Select Category"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                >
                  <option value={0}>Select Category</option>
                  {dynamicMarketplaceCategories &&
                    Array.isArray(dynamicMarketplaceCategories) &&
                    dynamicMarketplaceCategories.length > 0 &&
                    dynamicMarketplaceCategories.map((val) => (
                      <option key={val.id} value={val?.id}>
                        {val?.category_Name}
                      </option>
                    ))}
                </select>
              )}

              {formik.touched.name && formik.errors.name && (
                <FormikErrorStyle>{formik.errors.name}</FormikErrorStyle>
              )}
            </div>
          ) : (
            <div className="servicenameedit" id="ServicesEditformstyle">
              {data["name"] || ""}
            </div>
          )}
        </div>

        {/* <div class="mb-3">
          <label class="form-label">Description:</label>
          {data["isImageUpload"] ? (
            <textarea
              class="form-control"
              rows="4"
              placeholder="Description"
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
              value={
                data["description"] == "undefined" ? "" : data["description"]
              }
            ></textarea>
          ) : (
            <div>{data["description"] == "undefined" ? "" : data["description"]}</div>
          )}
        </div>

        <div class="mb-3">
          <label class="form-label">Button Name:</label>
          {data["isImageUpload"] ? (
            <input
              onChange={(e) => setData({ ...data, buttonName: e.target.value })}
              class="form-control"
              type="text"
              placeholder="Button Name"
              value={
                data["buttonName"] == "undefined" ? "" : data["buttonName"]
              }
            />
          ) : (
            <div>{data["buttonName"]== "undefined" ? "" : data["buttonName"]}</div>
          )}
        </div>
        <div class="mb-3">
          <label class="form-label">Button link:</label>
          {data["isImageUpload"] ? (
            <input
              onChange={(e) => setData({ ...data, buttonLink: e.target.value })}
              class="form-control"
              type="text"
              placeholder="Button link"
              value={
                data["buttonLink"] == "undefined" ? "" : data["buttonLink"]
              }
            />
          ) : (
            <div>{data["buttonLink"] == "undefined" ? "" : data["buttonLink"]}</div>
          )}
        </div> */}
        {isShowAdd && !addBtnDisable ? (
          <button
            type="button"
            disabled={!formik.isValid || !formik.dirty}
            onClick={() => {
              formik.handleSubmit();
            }}
            className="btn btn-cu addbuttoneditform"
          >
            {isEditing ? "Save" : "Add"}
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ServiceForm;
